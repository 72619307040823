import actionTypes from '../constants/action-types';

const templateBuildSlug = (state = null, action) => {
  switch (action.type) {
    case actionTypes.SET_TEMPLATE_BUILD_SLUG:
      return action.payload.templateBuildSlug;
    default:
      return state;
  }
};

export default templateBuildSlug;
