import React from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import { t } from 'i18next';

import Layout from '../layout';

const SiteLimitModal = (props) => {
  const {
    onClose,
    onUpgrade,
    myyolaLink,
    isPremium,
    currentPackageName,
    limit,
    dashboardAllowed,
  } = props;

  const headerTitle = isPremium ? t('Website Limit') : t('Upgrade Your Account');

  return (
    <ReactModal
      isOpen
      contentLabel={headerTitle}
      overlayClassName="modal-overlay"
      className="modal-container"
    >
      <Layout headerTitle={headerTitle} onCloseClick={onClose}>
        <section className="modal-body limit">
          <h4> {t("You've reached your website limit.")}</h4>
          <p className="limit-label">
            <span>
              {limit}/{limit}
            </span>
            &nbsp;{t('websites')}
          </p>
          {isPremium ? (
            <div>
              <p>
                {t(
                  'To continue using the selected website template you will need to delete one of your existing websites.'
                )}
              </p>
              {dashboardAllowed && (
                <a className="myyola" href={myyolaLink}>
                  {t('View your websites')}
                </a>
              )}
            </div>
          ) : (
            <div>
              <p>
                {t(
                  "You're currently using the {subscriptionType} plan which includes 1 website. To continue using the selected website template, please upgrade your plan.",
                  { subscriptionType: currentPackageName }
                )}
              </p>
              <button type="button" className="upgrade" onClick={onUpgrade}>
                {t('Upgrade Now')}
              </button>
              {dashboardAllowed && (
                <p>
                  {t('Or...')}{' '}
                  <a className="myyola" href={myyolaLink}>
                    {t('View your websites')}
                  </a>
                </p>
              )}
            </div>
          )}
        </section>
      </Layout>
    </ReactModal>
  );
};

SiteLimitModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onUpgrade: PropTypes.func.isRequired,
  limit: PropTypes.number,
  myyolaLink: PropTypes.string,
  currentPackageName: PropTypes.string,
  isPremium: PropTypes.bool,
  dashboardAllowed: PropTypes.bool,
};

SiteLimitModal.defaultProps = {
  limit: 0,
  myyolaLink: '',
  currentPackageName: 'FREE',
  isPremium: false,
  dashboardAllowed: false,
};

export default SiteLimitModal;
