import getCurrentHostname from 'src/js/utils/get-current-hostname';

import getYolaApiUrl from './yola-api-url';
import getWlApiUrl from './wl-api-url';
import getWlDomain from './wl-domain';

const getApiUrl = (rootState) => {
  if (getCurrentHostname() === getWlDomain(rootState)) {
    return getWlApiUrl(rootState);
  }
  return getYolaApiUrl(rootState);
};

export default getApiUrl;
