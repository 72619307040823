import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import { t } from 'i18next';
import partner from 'src/js/modules/partner';
import getLogoSrc from 'src/js/modules/user/selectors/get-logo-src';
import isMatchMobile from 'src/js/helpers/is-match-mobile';

const { Preloader, OnboardingHeader } = designSystem;
const getLogoSize = (width, mobileWidth) => {
  if (isMatchMobile()) {
    return {
      width: mobileWidth || 38,
      height: mobileWidth ? 'auto' : 24,
    };
  }

  return {
    width: width || 49,
    height: width ? 'auto' : 32,
  };
};

function Header({ isBackPlatformEnabled, onBackButtonClick, preloader, onSkip }) {
  const src = useSelector(getLogoSrc);
  const logoWidth = useSelector(partner.selectors.getPartnerLogoWidth);
  const logoMobileWidth = useSelector(partner.selectors.getPartnerLogoMobileWidth);
  const logoSize = getLogoSize(logoWidth, logoMobileWidth);
  const logo = {
    src,
    width: logoSize.width,
    height: logoSize.height,
  };

  const progress = preloader && (100 / preloader.total) * preloader.current;

  return (
    <div className="header">
      <OnboardingHeader
        captions={{
          back: isBackPlatformEnabled && !isMatchMobile() ? t('Back to Dashboard') : t('Back'),
          skip: t('Skip'),
        }}
        logo={logo}
        onBack={onBackButtonClick}
        onSkip={onSkip}
      />
      {progress && (
        <div className="header__preloader">
          <Preloader progress={progress} />
        </div>
      )}
    </div>
  );
}

Header.defaultProps = {
  isBackPlatformEnabled: false,
  onBackButtonClick: null,
  onSkip: null,
  preloader: null,
};

Header.propTypes = {
  isBackPlatformEnabled: PropTypes.bool,
  onBackButtonClick: PropTypes.func,
  onSkip: PropTypes.func,
  preloader: PropTypes.shape({
    current: PropTypes.number,
    total: PropTypes.number,
  }),
};

export default Header;
