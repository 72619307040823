import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import { t } from 'i18next';
import { SiteSetupContext } from 'src/js/modules/site-setup/helpers/site-setup-context';
import { AI_SITE_WIZARD_FLAG } from 'src/js/constants/common';
import useFeatureFlags from 'src/js/modules/feature-flags/hooks/use-feature-flags';

const { TemplatePreviewContainer } = designSystem;
const DEFAULT_PREVIEW_SRC = '/images/default-template-preview.jpg';

const TemplateThumb = ({
  name,
  previewUrl,
  templateSlug,
  onTemplatePreviewClick,
  onTemplateEditClick,
}) => {
  const context = useContext(SiteSetupContext);
  const { archetype } = context.getData();
  const [featureFlags] = useFeatureFlags([AI_SITE_WIZARD_FLAG]);
  const { [AI_SITE_WIZARD_FLAG]: isAISiteWizardEnabled } = featureFlags;
  const startEditingText =
    archetype && !isAISiteWizardEnabled ? t('Use this template') : t('Start editing');

  const handlePreviewClick = () => {
    onTemplatePreviewClick(templateSlug);
  };

  const handleEditClick = () => {
    onTemplateEditClick(templateSlug);
  };

  return (
    <TemplatePreviewContainer
      title={name}
      previewUrl={previewUrl || DEFAULT_PREVIEW_SRC}
      onPreviewClick={handlePreviewClick}
      onStartEditingClick={handleEditClick}
      captions={{
        preview: t('Preview'),
        startEditing: startEditingText,
      }}
    />
  );
};

TemplateThumb.defaultProps = {
  previewUrl: '',
};

TemplateThumb.propTypes = {
  name: PropTypes.string.isRequired,
  previewUrl: PropTypes.string,
  templateSlug: PropTypes.string.isRequired,
  onTemplatePreviewClick: PropTypes.func.isRequired,
  onTemplateEditClick: PropTypes.func.isRequired,
};

export default TemplateThumb;
