import actionTypes from '../constants/action-types';

const limit = (state = 0, action) => {
  switch (action.type) {
    case actionTypes.SET_SITES_LIMIT:
      return action.payload;
    default:
      return state;
  }
};

export default limit;
