import { useSelector, shallowEqual } from 'react-redux';
import useFeatureFlags from 'src/js/modules/feature-flags/hooks/use-feature-flags';
import getUserAuthorizationStatus from 'src/js/modules/user/selectors/user-authorization-status';
import getAvailableSubscriptionTypes from 'src/js/modules/partner/selectors/available-subscription-types';
import getTemplates from '../selectors/get-templates';
import filterAvailableTemplates from '../helpers/filter-available-templates';
import processSiteConceptForTemplates from '../helpers/process-site-concept-for-templates';

const useTemplates = () => {
  const [featureFlags] = useFeatureFlags(['legacy_latitude_templates', 'blank_template']);
  const isUserAuthenticated = useSelector(getUserAuthorizationStatus);
  const availableSubscriptionTypes = useSelector(getAvailableSubscriptionTypes, shallowEqual);
  const templates = useSelector(getTemplates);

  const availableTemplates = filterAvailableTemplates(
    templates,
    featureFlags,
    availableSubscriptionTypes,
    isUserAuthenticated
  );
  const computedTemplates = processSiteConceptForTemplates(availableTemplates);

  return {
    templates: computedTemplates,
  };
};

export default useTemplates;
