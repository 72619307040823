import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { designSystem } from '@yola/ws-ui';
import routesMap from '../../router/helpers/routes-map';

const { ActionButton } = designSystem;

const CategoryLink = forwardRef(({ id, title, onClick, isActive }, ref) => {
  const handleClick = () => {
    onClick(id);
  };

  return (
    <div className="categories-bar__item" ref={ref}>
      <NavLink to={routesMap.gallery.url({ category: id })}>
        <ActionButton
          appearance="accent"
          format="text"
          label={title}
          selected={isActive}
          onClick={handleClick}
        />
      </NavLink>
    </div>
  );
});

CategoryLink.defaultProps = {
  onClick: Function.prototype,
  isActive: false,
};

CategoryLink.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
};

export default CategoryLink;
