import get from 'lodash.get';
import getIsB2C from './is-b2c';

const getUpgradeUrl = (rootState) => {
  if (getIsB2C(rootState)) {
    return get(rootState, 'config.service.common.myyola.upgrade_url', null);
  }

  return get(rootState, 'config.service.common.myyola.upgrade_wl_url', null);
};

export default getUpgradeUrl;
