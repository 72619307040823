import { APP_NAME } from 'src/js/constants/common';
import getSiteIdRecreate from 'src/js/modules/sites/accessors/get-site-id-recreate';
import getSiteIdToSwitchTemplate from 'src/js/modules/sites/accessors/get-site-id-to-switch-template';
import track from '../helpers/track';
import eventTypes from '../constants/event-types';

const trackSitesetupAiGenerateWebsiteSelected = ({
  stepId,
  businessCategory = null,
  archetypeId,
}) => {
  track(eventTypes.SITESETUP_AI_GENERATE_WEBSITE_SELECTED, {
    appName: APP_NAME,
    siteId: getSiteIdRecreate() || getSiteIdToSwitchTemplate() || null,
    stepId,
    businessCategory,
    archetypeId,
  });
};

export default trackSitesetupAiGenerateWebsiteSelected;
