import { APP_NAME } from 'src/js/constants/common';
import getSiteIdRecreate from 'src/js/modules/sites/accessors/get-site-id-recreate';
import track from '../helpers/track';
import eventTypes from '../constants/event-types';

const trackSitesetupStartWithBlankTemplateSelected = ({
  stepId,
  businessCategory = null,
  archetypeId,
}) => {
  track(eventTypes.SITESETUP_START_WITH_BLANK_TEMPLATE_SELECTED, {
    appName: APP_NAME,
    siteId: getSiteIdRecreate() || null,
    stepId,
    businessCategory,
    archetypeId,
  });
};

export default trackSitesetupStartWithBlankTemplateSelected;
