const getLanguageName = ({ languageCode, language, country, countryName: mapCountryName }) => {
  const { name: langName, nativeName: langNativeName } = language;
  const {
    name: tempCountryName,
    nativeName: countryNativeName,
    languageName: tempLangName,
  } = country;

  const nativeName = countryNativeName || langNativeName;
  const countryName = tempCountryName || mapCountryName;
  const lang = tempLangName || langName;

  const languageName = lang !== nativeName ? `${lang} (${nativeName})` : lang;
  return languageCode.split('-').length > 1 ? `${languageName} — ${countryName}` : languageName;
};

export default getLanguageName;
