import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { t } from 'i18next';
import { useHistory, matchPath } from 'react-router-dom';
import routesMap from 'src/js/router/helpers/routes-map';
import redirectUnauthorizedUser from 'src/js/helpers/redirect-unauthorized-user';
import { SiteSetupContext } from '../modules/site-setup/helpers/site-setup-context';
import getSettingsFromContext from '../modules/site-setup/helpers/get-settings-from-context';
import UseTemplate from '../components/use-template';
import useTemplates from '../modules/templates/hooks/use-templates';
import createSite from '../modules/templates/actions/create-site-by-template';
import useFeatureFlags from '../modules/feature-flags/hooks/use-feature-flags';
import getUserAuthorizationStatus from '../modules/user/selectors/user-authorization-status';
import useSiteProperties from '../modules/templates/hooks/use-site-properties';
import getSiteIdToSwitch from '../modules/sites/selectors/site-id-to-switch-template';
import { BUSINESS_TAXONOMY_FLAG } from '../constants/common';

const UseTemplateContainer = ({ templateSlug }) => {
  const history = useHistory();
  const context = useContext(SiteSetupContext);
  const siteProperties = useSiteProperties();
  const { getData, updateData } = context;
  const [featureFlags] = useFeatureFlags([BUSINESS_TAXONOMY_FLAG]);
  const { [BUSINESS_TAXONOMY_FLAG]: isBusinessTaxonomyEnabled } = featureFlags;
  const dispatch = useDispatch();
  const { templateBuildSlug: slug, archetype, siteName, locale } = getData();
  const isArchetypeSet = Boolean(archetype !== undefined);
  const isUserAuthenticated = useSelector(getUserAuthorizationStatus);
  const siteIdToSwitch = useSelector(getSiteIdToSwitch);
  const { templates } = useTemplates();

  useEffect(() => {
    if (templateSlug) {
      if (isUserAuthenticated) {
        updateData({
          templateBuildSlug: templateSlug,
          useTemplateFlow: true,
        });
        history.replace(
          isBusinessTaxonomyEnabled ? routesMap.siteCategory.url() : routesMap.userArchetypes.url()
        );
      } else {
        redirectUnauthorizedUser(routesMap.useTemplate.url({ templateSlug }));
      }
    }
  }, [isUserAuthenticated, history, updateData, templateSlug, isBusinessTaxonomyEnabled]);

  if (!siteIdToSwitch && (!slug || !isArchetypeSet)) {
    if (isUserAuthenticated) {
      history.replace(
        isBusinessTaxonomyEnabled ? routesMap.siteCategory.url() : routesMap.userArchetypes.url()
      );
      return null;
    }
  }

  if (!siteProperties) return null;

  const createSiteByTemplate = (conceptId, templateBuildSlug, currentLocale) => {
    const settings = getSettingsFromContext(context);
    const name = siteName || t('My website');

    dispatch(
      createSite(conceptId, name, templateBuildSlug, currentLocale, siteProperties, settings)
    );

    let match = null;
    const step = Object.keys(routesMap).find((route) => {
      if (route === 'index' || route === 'createSite') return false;

      match = matchPath(window.location.pathname, routesMap[route].pattern());

      return Boolean(match);
    });

    if (match) {
      history.replace(routesMap[step].url(match.params));
    } else {
      history.replace(
        isBusinessTaxonomyEnabled ? routesMap.siteCategory.url() : routesMap.userArchetypes.url()
      );
    }
  };

  return (
    <UseTemplate
      templateSlug={slug}
      templates={templates}
      locale={locale}
      createSiteByTemplate={createSiteByTemplate}
    />
  );
};

UseTemplateContainer.defaultProps = {
  templateSlug: null,
};

UseTemplateContainer.propTypes = {
  templateSlug: PropTypes.string,
};

export default UseTemplateContainer;
