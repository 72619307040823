import get from 'lodash.get';

const sortTemplates = (templates) =>
  [...templates].sort((templateA, templateB) => {
    const isNewTemplateA = get(templateA, 'properties.new', false);
    const isNewTemplateB = get(templateB, 'properties.new', false);

    if (isNewTemplateA && !isNewTemplateB) {
      return -1;
    }

    if (!isNewTemplateA && isNewTemplateB) {
      return 1;
    }

    return 0;
  });

export default sortTemplates;
