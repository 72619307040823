import { shuffle } from 'lodash';
import yowsClient from 'src/js/modules/service-client/yows-client';
import templates from 'src/js/modules/templates';
import errorModule from 'src/js/modules/error';

const fetchTemplatesData = (params) => async (dispatch) => {
  try {
    const { data } = await yowsClient.get().listTemplates(params);

    const newData = {
      ...data,
      result: shuffle(data.result),
    };
    dispatch(templates.actions.setTemplatesData(newData));
  } catch (error) {
    dispatch(
      errorModule.actions.setCriticalError({
        error,
        logSentry: true,
      })
    );
  }
};

export default fetchTemplatesData;
