import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import Layout from '../layout';

import { getSelectedTemplate } from './utils';
import HeaderDetailPageContainer from '../../containers/header-detail-page-container';
import LivePreviewSlider from '../live-preview-slider';
import { DESKTOP } from '../../modules/live-preview/constants';
import getPreviewModeForDevice from '../../modules/live-preview/helpers/get-preview-mode-for-device';

export const LivePreviewContext = React.createContext({
  mode: DESKTOP,
  toggleMode: Function.prototype,
});

function TemplateDetails(props) {
  const { templates, templateSlug, locale } = props;
  const defaultPreviewMode = getPreviewModeForDevice();
  const [previewMode, setPreviewMode] = useState(defaultPreviewMode);
  const selectedTemplate = getSelectedTemplate(templates, templateSlug);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  if (!templates.length || !locale) {
    return null;
  }

  if (templateSlug === 'blank') {
    return <Layout />;
  }

  if (!selectedTemplate) {
    return null;
  }

  const {
    properties: { livePreviewUrl },
  } = selectedTemplate;

  return (
    <LivePreviewContext.Provider
      value={{
        mode: previewMode,
        toggleMode: setPreviewMode,
      }}
    >
      <Layout Header={HeaderDetailPageContainer}>
        <Fragment>
          <LivePreviewSlider
            toMode={previewMode}
            templateSlug={templateSlug}
            url={livePreviewUrl}
          />
        </Fragment>
      </Layout>
    </LivePreviewContext.Provider>
  );
}

TemplateDetails.defaultProps = {
  templates: [],
};

TemplateDetails.propTypes = {
  templates: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
      description: PropTypes.string,
      previewUrl: PropTypes.string,
    })
  ),
  templateSlug: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
};

export default TemplateDetails;
