import featureFlagsManager from '@yola/feature-flags-manager';
import config from 'src/js/modules/config';
import serviceClient from '../service-client';

const clientLoader = () => (next) => (action) => {
  switch (action.type) {
    case config.actionTypes.SET_SERVICE_CONFIG: {
      const configData = {
        config: {
          service: { ...action.payload.configData },
        },
      };
      const apiUrl = config.selectors.getApiUrl(configData);
      serviceClient.set(featureFlagsManager.initWith(apiUrl));
      next(action);
      break;
    }
    default: {
      next(action);
    }
  }
};

export default clientLoader;
