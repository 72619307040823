import yowsClient from 'src/js/modules/service-client/yows-client';
import errorModule from 'src/js/modules/error';
import sites from 'src/js/modules/sites/actions';

const fetchSitesData = () => async (dispatch) => {
  try {
    const { data } = await yowsClient.get().listSites({
      params: {
        page_size: '200',
      },
    });

    dispatch(sites.setSitesData(data));
  } catch (error) {
    dispatch(
      errorModule.actions.setCriticalError({
        error,
        logSentry: true,
      })
    );
  }
};

export default fetchSitesData;
