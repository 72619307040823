import TagManager from 'react-gtm-module';

const init = ({ gtmId, auth, preview, dataLayerName }) => {
  TagManager.initialize({
    gtmId,
    auth,
    preview,
    dataLayerName,
  });
};

const setDataLayer = (dataLayerName, pathname) => {
  TagManager.dataLayer({
    dataLayer: {
      page: pathname,
    },
    dataLayerName,
  });
};

export default { init, setDataLayer };
