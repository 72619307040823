import trackCategorySelected from './category-selected';
import trackLivePreviewBackClicked from './live-preview-back-clicked';
import trackLivePreviewDeviceClicked from './live-preview-device-clicked';
import trackUseTemplateButtonClicked from './use-template-button-clicked';
import trackSiteSetupCompleted from './site-setup-completed';
import trackSiteSetupSiteNameProvided from './site-setup-site-name-provided';
import trackSiteSetupSkipButtonClicked from './site-setup-skip-button-clicked';
import trackSiteSetupBackButtonClicked from './site-setup-back-button-clicked';
import trackSiteSetupUserArchetypeClicked from './site-setup-user-archetype-clicked';
import trackSiteSetupUserArchetypeProvided from './site-setup-user-archetype-provided';
import trackSiteSetupBusinessNameAndIntroductionProvided from './site-setup-business-name-and-introduction-provided';
import trackSiteSetupBusinessDetailsProvided from './site-setup-business-details-provided';
import trackPreviewClicked from './preview-clicked';
import trackEditingClicked from './editing-clicked';
import trackSitesetupBusinessCategoryProvided from './sitesetup-business-category-provided';
import trackSitesetupStartWithBlankTemplateSelected from './sitesetup-start-with-blank-template-selected';
import trackBusinessCategoryInputFocused from './business-category-input-focused';
import trackBusinessCategorySelected from './business-category-selected';
import trackSitesetupStartWithATemplateSelected from './sitesetup-start-with-a-template-selected';
import trackSitesetupAiGenerateWebsiteSelected from './sitesetup-ai-generate-website-selected';

export default {
  trackCategorySelected,
  trackLivePreviewBackClicked,
  trackLivePreviewDeviceClicked,
  trackUseTemplateButtonClicked,
  trackSiteSetupCompleted,
  trackSiteSetupSiteNameProvided,
  trackSiteSetupSkipButtonClicked,
  trackSiteSetupBackButtonClicked,
  trackSiteSetupUserArchetypeClicked,
  trackSiteSetupUserArchetypeProvided,
  trackPreviewClicked,
  trackEditingClicked,
  trackSiteSetupBusinessNameAndIntroductionProvided,
  trackSiteSetupBusinessDetailsProvided,
  trackSitesetupBusinessCategoryProvided,
  trackSitesetupStartWithBlankTemplateSelected,
  trackBusinessCategoryInputFocused,
  trackBusinessCategorySelected,
  trackSitesetupStartWithATemplateSelected,
  trackSitesetupAiGenerateWebsiteSelected,
};
