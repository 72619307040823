import actionTypes from '../constants/action-types';

const hideSpinner = () => ({
  type: actionTypes.HIDE_SPINNER,
  payload: {
    text: null,
    spinnerIsShown: false,
  },
});

export default hideSpinner;
