import { get } from 'lodash';
import isAuthComponentAvailable from '../../partner/verifiers/is-auth-component-available';
import getPartnerSignupUrl from '../../partner/selectors/partner-signup-url';
import getIsB2C from './is-b2c';

const getSignupUrl = (rootState) => {
  if (getIsB2C(rootState)) {
    return get(rootState, 'config.service.common.auth-gateway.signup_url', null);
  }

  const partnerSignupUrl = getPartnerSignupUrl(rootState);

  if (!isAuthComponentAvailable() && partnerSignupUrl) {
    return partnerSignupUrl;
  }

  return get(rootState, 'config.service.common.auth-gateway.signup_wl_url', null);
};

export default getSignupUrl;
