import setCookie from 'src/js/utils/set-cookie';
import getPartnerIdFromStorage from '../accessors/get-partner-id-from-storage';
import constants from '../constants';

const savePartnerId = (id) => {
  const partnerId = getPartnerIdFromStorage();
  if (partnerId && partnerId === id) return;

  const domain = window.location.hostname.replace(/^[^.]+/g, '');
  setCookie(constants.COOKIE_PARTNER_ID_ITEM, id, domain);
};

export default savePartnerId;
