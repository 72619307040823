import subscriptionTypes from '../constants/subscription-types';

const { GOLD, SILVER, BRONZE, WL_LITE, WL_PREMIUM, WL_BASIC, WL_ULTRA_LITE } = subscriptionTypes;
const premiumSubscriptions = [GOLD, SILVER, BRONZE, WL_LITE, WL_PREMIUM, WL_BASIC, WL_ULTRA_LITE];

const hasPremium = (activeSubscriptions) =>
  activeSubscriptions.some((subscription) => {
    const subscriptionType = subscription.type.toLowerCase();
    return premiumSubscriptions.includes(subscriptionType);
  });

export default hasPremium;
