import React, { useContext, useMemo, useCallback, useEffect } from 'react';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import { t } from 'i18next';
import { designSystem, reactHookForm } from '@yola/ws-ui';
import { useSelector } from 'react-redux';
import { cloneDeepWith } from 'lodash';
import { SiteSetupContext } from 'src/js/modules/site-setup/helpers/site-setup-context';
import routesMap from 'src/js/router/helpers/routes-map';
import Layout from 'src/js/components/layout';
import HeaderGalleryPageContainer from 'src/js/containers/header-gallery-page-container';
import SiteSetupLayout from 'src/js/components/site-setup/site-setup-layout';
import siteSetup from 'src/js/modules/site-setup';
import config from 'src/js/modules/config';
import user from 'src/js/modules/user';
import trackers from 'src/js/modules/analytics/segment/trackers';
import isURL from 'src/js/utils/is-url';
import normalizeUrl from 'src/js/utils/normalize-url';
import InputGroupWithButtonField from 'src/js/components/input-group-with-button-field';
import validateRequired from 'src/js/helpers/validate-required';
import useFeatureFlags from '../../modules/feature-flags/hooks/use-feature-flags';
import { BUSINESS_TAXONOMY_FLAG, ENTER_KEY } from '../../constants/common';

const { Container, ControlGroup, Stack, ActionButton, InputGroupField } = designSystem;
const { useForm, useFieldArray } = reactHookForm;
const {
  constants: { archetypesMap, siteSetupNames, common, validationPatterns },
} = siteSetup;
const { MAX_INPUT_CHARS } = common;
const { trackSiteSetupSkipButtonClicked, trackSiteSetupBusinessDetailsProvided } = trackers;

const getCaptions = () => ({
  title: t('Finish with business information'),
  subtitle: t('Your website will use this information in all needed places'),
  buttons: {
    addLink: t('Add another social media'),
    next: t('Finish setup'),
  },
  error: {
    phone: t('Invalid phone number. Please, check the phone number and try again'),
    email: t('Invalid email. Please, check the email and try again'),
    url: t('Invalid URL. Please, check the URL and try again'),
    required: t('This field is required'),
  },
  fields: {
    address: {
      label: t('Business address'),
      placeholder: t('Enter your address'),
    },
    phone: {
      label: t('Business phone'),
      placeholder: t('Enter your phone'),
    },
    email: {
      label: t('Business email'),
      placeholder: t('Enter your email'),
    },
    hours: {
      label: t('Working hours'),
      placeholder: t('Enter your working hours'),
    },
    links: {
      label: t('Social links'),
    },
  },
});

function BusinessDetailsStepContainer() {
  const captions = getCaptions();
  const history = useHistory();
  const location = useLocation();
  const userData = useSelector(user.selectors.getUserData);
  const isB2C = useSelector(config.selectors.getIsB2C);
  const isAuthorized = useSelector(user.selectors.getUserAuthorizationStatus);
  const userEmail = isB2C && Object.keys(userData).length ? userData.email : '';
  const [featureFlags] = useFeatureFlags([BUSINESS_TAXONOMY_FLAG]);
  const { [BUSINESS_TAXONOMY_FLAG]: isBusinessTaxonomyEnabled } = featureFlags;
  const context = useContext(SiteSetupContext);
  const createSite = useCallback(() => {
    history.replace(routesMap.createSite.url({ currentPath: location.pathname }));
  }, [history, location.pathname]);
  const { getData, updateData, getArchetype } = context;
  const contextData = getData();
  const archetype = getArchetype();

  if (!isAuthorized || !archetype || archetype === archetypesMap.PERSONAL) {
    history.replace(
      isBusinessTaxonomyEnabled ? routesMap.siteCategory.url() : routesMap.userArchetypes.url()
    );
  }

  const { number: currentStep } = useMemo(siteSetup.helpers.getCurrentStep, []);

  let initialSocialLinks = [{ link: '' }];

  if (contextData.socialLinks && contextData.socialLinks.length) {
    initialSocialLinks = contextData.socialLinks.map((item) => ({ link: item }));
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      address: contextData.address || '',
      phone: contextData.phone || '',
      email: contextData.email || userEmail,
      hours: contextData.hours || '',
      socialLinks: initialSocialLinks,
    },
    mode: 'onTouched',
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'socialLinks',
  });

  const onNext = () => {
    handleSubmit((data) => {
      const trimmedData = cloneDeepWith(data, (val) => {
        if (typeof val === 'string') return val.trim();
      });

      const { socialLinks, hours, ...rest } = trimmedData;
      const formattedSocialLinks = socialLinks
        .map((item) => normalizeUrl(item.link))
        .filter((link) => link.length);

      trackSiteSetupBusinessDetailsProvided({
        ...rest,
        socialLinks: formattedSocialLinks,
        workingHours: hours,
        archetypeId: archetype,
        businessCategory: contextData.businessCategorySlug,
      });

      updateData({
        ...trimmedData,
        socialLinks: formattedSocialLinks,
      });

      createSite();
    })();
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === ENTER_KEY && !Object.keys(errors).length) {
        onNext();
      }
    };
    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const onSkip = () => {
    const stepId = siteSetupNames.BUSINESS_DETAILS;

    trackSiteSetupSkipButtonClicked({
      stepId,
      archetypeId: archetype,
      businessCategory: contextData.businessCategorySlug,
    });
    createSite();
  };

  const validateURL = useCallback((link) => {
    if (link.length) {
      const isValidURL = isURL(link);

      if (!isValidURL) return captions.error.url;
    }

    return true;
  }, []);

  return (
    <Layout Header={HeaderGalleryPageContainer} headerOnSkip={onSkip}>
      <SiteSetupLayout
        className="ws-site-setup-layout-site-name"
        captions={captions}
        currentStep={currentStep}
        next={{
          callback: onNext,
          disabled: Boolean(Object.keys(errors).length),
        }}
      >
        <Container>
          <Stack gap="spacing-m">
            <ControlGroup title={captions.fields.address.label}>
              <InputGroupField
                size="large"
                iconGlyph="map-marker"
                placeholder={captions.fields.address.placeholder}
                name="address"
                control={control}
                rules={{
                  required: false,
                  maxLength: {
                    value: MAX_INPUT_CHARS,
                    message: t('Maximum number of characters {number}', {
                      number: MAX_INPUT_CHARS,
                    }),
                  },
                }}
              />
            </ControlGroup>

            <ControlGroup title={captions.fields.phone.label}>
              <InputGroupField
                size="large"
                iconGlyph="phone"
                placeholder={captions.fields.phone.placeholder}
                name="phone"
                control={control}
                rules={{
                  required: false,
                  pattern: {
                    value: validationPatterns.phone,
                    message: captions.error.phone,
                  },
                }}
              />
            </ControlGroup>

            <ControlGroup title={captions.fields.email.label} required>
              <InputGroupField
                size="large"
                iconGlyph="mail"
                placeholder={captions.fields.email.placeholder}
                name="email"
                control={control}
                rules={{
                  validate: (value) => validateRequired(value, captions.error.required),
                  pattern: {
                    value: validationPatterns.email,
                    message: captions.error.email,
                  },
                }}
              />
            </ControlGroup>

            <ControlGroup title={captions.fields.hours.label}>
              <InputGroupField
                size="large"
                iconGlyph="clock"
                placeholder={captions.fields.hours.placeholder}
                name="hours"
                control={control}
                rules={{
                  required: false,
                  maxLength: {
                    value: MAX_INPUT_CHARS,
                    message: t('Maximum number of characters {number}', {
                      number: MAX_INPUT_CHARS,
                    }),
                  },
                }}
              />
            </ControlGroup>

            <ControlGroup title={captions.fields.links.label}>
              <Stack gap="spacing-2-xs">
                {fields.map((field, index) => (
                  <div key={field.id}>
                    <InputGroupWithButtonField
                      input={{
                        size: 'large',
                        placeholder: 'https://facebook.com/profile',
                        name: `socialLinks.${index}.link`,
                        control,
                        rules: {
                          required: false,
                          validate: validateURL,
                        },
                      }}
                      button={{
                        iconGlyph: 'trash',
                        format: 'text',
                        disabled: fields.length === 1,
                        onClick: () => {
                          remove(index);
                        },
                      }}
                    />
                  </div>
                ))}
                <div>
                  <ActionButton
                    label={captions.buttons.addLink}
                    fullWidth={false}
                    onClick={() => append({ link: '' })}
                  />
                </div>
              </Stack>
            </ControlGroup>
          </Stack>
        </Container>
      </SiteSetupLayout>
    </Layout>
  );
}

export default withRouter(BusinessDetailsStepContainer);
