import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Panel, PanelGroup, designSystem } from '@yola/ws-ui';

const {
  Stack,
  DialogHeader,
  DialogHeaderTitle,
  Box,
  ControlGroup,
  RadioButton,
  RadioButtonGroup,
  DialogHeaderControls,
  DialogHeaderIconButton,
  ActionButton,
} = designSystem;

const FilterTemplatesDialog = ({
  captions,
  categories,
  selectedCategory,
  onCategorySelect,
  onClose,
  onSubmit,
}) => (
  <Modal
    isBodyScrollDisabled
    draggable={false}
    resizable={false}
    fullscreen
    overlay="visible"
    className="ws-filter-templates-modal"
    onOverlayClick={onClose}
  >
    <PanelGroup className="ws-filter-templates-modal__panel-group">
      <Panel>
        <DialogHeader>
          <DialogHeaderTitle>{captions.title}</DialogHeaderTitle>
          <DialogHeaderControls>
            <DialogHeaderIconButton onClick={onClose} glyph="close" />
          </DialogHeaderControls>
        </DialogHeader>
      </Panel>
      <Panel>
        <Box padding="spacing-xs">
          <Stack gap="spacing-m">
            <ControlGroup title={captions.radioButtonGroupLabel}>
              <RadioButtonGroup
                onChange={onCategorySelect}
                name="filter-templates-radio-button-group"
                selectedValue={selectedCategory}
              >
                {categories.map(({ title, id }) => (
                  <RadioButton value={id} label={title} key={title} id={id} isLabelBold />
                ))}
              </RadioButtonGroup>
            </ControlGroup>
            <ActionButton
              fullWidth
              appearance="accent"
              format="solid"
              label={captions.submitButtonLabel}
              onClick={onSubmit}
            />
          </Stack>
        </Box>
      </Panel>
    </PanelGroup>
  </Modal>
);

FilterTemplatesDialog.propTypes = {
  captions: PropTypes.shape({
    title: PropTypes.string,
    radioButtonGroupLabel: PropTypes.string,
    submitButtonLabel: PropTypes.string,
  }).isRequired,
  selectedCategory: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    })
  ).isRequired,
  onCategorySelect: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FilterTemplatesDialog;
