import React from 'react';
import { useSelector } from 'react-redux';
import SiteLimitDialogContainer from './site-limit-dialog-container';
import FilterTemplatesDialogContainer from './filter-templates-dialog-container';
import dialogs from '../index';

function DialogsContainer() {
  const dialog = useSelector(dialogs.selectors.getDialog);

  switch (dialog.type) {
    case dialogs.dialogTypes.SITE_LIMIT:
      return <SiteLimitDialogContainer {...dialog.dialogProps} />;
    case dialogs.dialogTypes.FILTER_TEMPLATES_DIALOG:
      return <FilterTemplatesDialogContainer {...dialog.dialogProps} />;
    default:
      return null;
  }
}

export default DialogsContainer;
