import actionTypes from '../constants/action-types';

const siteIdToSwitchTemplate = (state = null, action) => {
  switch (action.type) {
    case actionTypes.SET_SITE_ID_TO_SWITCH_TEMPLATE:
      return action.payload.siteId;
    default:
      return state;
  }
};

export default siteIdToSwitchTemplate;
