import React from 'react';
import PropTypes from 'prop-types';
import isOldIOSBrowser from '../helpers/is-old-ios-browser';

function IframeFixWrapper({ children, size }) {
  if (!isOldIOSBrowser()) return children;

  const { width, height } = size;
  return (
    <div
      className="ios-12-wrapper"
      style={{
        maxHeight: height,
        width,
      }}
    >
      {children}
    </div>
  );
}

IframeFixWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  size: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
};

export default IframeFixWrapper;
