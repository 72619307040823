import React from 'react';
import PropTypes from 'prop-types';
import { Brand } from '@yola/ws-ui';
import { DEFAULT_LOGO_URL } from 'src/js/constants/common';

const Logo = (props) => {
  const { partnerLogoUrl, alt, isUserAuthorized } = props;
  let src = partnerLogoUrl;

  if (!src && !isUserAuthorized) {
    // It's needed for anonymous users
    src = DEFAULT_LOGO_URL;
  }

  if (!src) return null;

  return (
    <div className="branded-logo">
      <Brand
        logo={{
          src,
          width: 'auto',
          height: 'auto',
        }}
        alt={alt}
        defaultWidth={87}
        altWidth={180}
        alignLogo="left"
      />
    </div>
  );
};

Logo.defaultProps = {
  partnerLogoUrl: '',
  alt: '',
};

Logo.propTypes = {
  partnerLogoUrl: PropTypes.string,
  alt: PropTypes.string,
  isUserAuthorized: PropTypes.bool.isRequired,
};

export default Logo;
