const apiResolvers = {};

const api = new Promise((resolve, reject) => {
  apiResolvers.resolve = resolve;
  apiResolvers.reject = reject;
});

const loadSnippet = (url, mountPoint) =>
  new Promise((resolve, reject) => {
    const { ownerDocument } = mountPoint;
    const scriptId = 'ze-snippet';

    const scriptOnPage = ownerDocument.getElementById(scriptId);

    if (scriptOnPage) {
      api
        .then(() => {
          const {
            defaultView: { zE },
          } = ownerDocument;
          resolve(zE);
        })
        .catch(reject);
      return;
    }

    const script = ownerDocument.createElement('script');
    script.id = scriptId;
    script.onload = () => {
      const {
        defaultView: { zE },
      } = ownerDocument;
      apiResolvers.resolve(zE);
      resolve(zE);
    };
    script.onerror = (error) => {
      reject(error);
      apiResolvers.reject(error);
    };
    script.src = url;
    mountPoint.appendChild(script);
  });

export default loadSnippet;
