import React, { useMemo, useCallback, useRef, useContext } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import {
  AI_SITE_WIZARD_SITE_PROP,
  aiSiteWizardSitePropValues,
} from 'src/js/constants/ai-site-wizard';
import siteSetup from '../modules/site-setup';
import GalleryLayout from '../components/gallery-layout';
import siteSetupComp from '../components/site-setup';
import getAvailableSubscriptionTypes from '../modules/partner/selectors/available-subscription-types';
import getSiteIdToRecreate from '../modules/sites/selectors/get-site-id-recreate';
import getSiteIdToSwitch from '../modules/sites/selectors/site-id-to-switch-template';
import useTemplates from '../modules/templates/hooks/use-templates';
import sortTemplates from '../modules/templates/helpers/sort-templates';
import splitTemplates from '../modules/templates/helpers/split-templates';
import getUserData from '../modules/user/selectors/get-user-data';
import getUserAuthorizationStatus from '../modules/user/selectors/user-authorization-status';
import getLocale from '../modules/i18n/selectors/locale';
import useFeatureFlags from '../modules/feature-flags/hooks/use-feature-flags';
import Layout from '../components/layout';
import categoriesModule from '../modules/categories';
import getGalleryCaptions from '../utils/get-gallery-captions';
import trackers from '../modules/analytics/segment/trackers';
import routesMap from '../router/helpers/routes-map';
import { BUSINESS_TAXONOMY_FLAG, DEFAULT_CATEGORY_FOR_URI } from '../constants/common';
import { SiteSetupContext } from '../modules/site-setup/helpers/site-setup-context';
import useOnTemplateSelect from '../modules/site-setup/hooks/use-on-template-select';
import constants from '../modules/site-setup/constants';
import dialogs from '../modules/dialogs';

const { helpers } = siteSetup;
const { GalleryLayoutStep } = siteSetupComp;

const {
  helpers: { getCategories, filterTemplatesByCategory, filterAvailableCategories },
  categoryIds: { ALL },
} = categoriesModule;

const {
  trackCategorySelected,
  trackPreviewClicked,
  trackEditingClicked,
  trackSitesetupStartWithBlankTemplateSelected,
} = trackers;
const { triggerIds } = constants;

const getTemplateCategory = (category) => category || DEFAULT_CATEGORY_FOR_URI;

function GalleryLayoutContainer({ activeCategory, match, history }) {
  const galleryGridRef = useRef(null);
  const categoriesBarRef = useRef(null);
  const pathName = get(match, 'url', '');
  const isTemplateRoute = helpers.isTemplateRoute(pathName);
  const context = useContext(SiteSetupContext);
  const { getArchetype, getData, updateData } = context;
  const contextData = getData();
  const archetype = getArchetype() || null;
  const isArchetypeSet = Boolean(archetype !== undefined);
  const availableSubscriptionTypes = useSelector(getAvailableSubscriptionTypes, shallowEqual);
  const [featureFlags] = useFeatureFlags(['legacy_latitude_templates', BUSINESS_TAXONOMY_FLAG]);
  const { [BUSINESS_TAXONOMY_FLAG]: isBusinessTaxonomyEnabled } = featureFlags;
  const isUserAuthenticated = useSelector(getUserAuthorizationStatus);

  const dispatch = useDispatch();

  const allCategories = getCategories();
  const availableCategories = filterAvailableCategories(
    allCategories,
    availableSubscriptionTypes,
    isUserAuthenticated
  );

  const { templates: allTemplates } = useTemplates();
  const { commonTemplates, blankTemplate } = splitTemplates(allTemplates);
  const sortedTemplates = sortTemplates(commonTemplates);
  const templates = filterTemplatesByCategory(sortedTemplates, activeCategory);

  const userData = useSelector(getUserData);
  const locale = useSelector(getLocale);
  const siteIdToRecreate = useSelector(getSiteIdToRecreate);
  const siteIdToSwitch = useSelector(getSiteIdToSwitch);
  const handleTemplateSelect = useOnTemplateSelect();
  const siteId = siteIdToRecreate || siteIdToSwitch || null;
  // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  const captions = useMemo(() => getGalleryCaptions(siteIdToRecreate, siteIdToSwitch), [
    locale,
    siteIdToRecreate,
    siteIdToSwitch,
  ]);
  const traits = useMemo(
    () => ({
      siteId,
      selectedTemplateCategory: activeCategory,
      businessCategory: contextData.businessCategorySlug,
    }),
    [siteId, activeCategory, contextData.businessCategorySlug]
  );

  const isGalleryLayoutStep = !siteIdToSwitch && !isTemplateRoute;

  const scrollToGalleryGridTop = useCallback(() => {
    const { current: categoriesBar } = categoriesBarRef;
    const { current: galleryGrid } = galleryGridRef;

    if (!galleryGrid || !categoriesBar) {
      return;
    }

    const { bottom: categoriesBarBottom } = categoriesBar.getBoundingClientRect();
    const { top: galleryGridTop } = galleryGrid.getBoundingClientRect();

    if (galleryGridTop >= categoriesBarBottom) {
      return;
    }

    window.scrollTo({
      top: galleryGrid.offsetTop - categoriesBarBottom,
      behavior: 'smooth',
    });
  }, [categoriesBarRef, galleryGridRef]);

  const handleCategorySelect = useCallback(
    (selectedTemplateCategory) => {
      if (selectedTemplateCategory !== activeCategory) {
        trackCategorySelected({ siteId, selectedTemplateCategory });
      }

      scrollToGalleryGridTop();
    },
    [activeCategory, siteId, scrollToGalleryGridTop]
  );

  const handleTemplatePreview = useCallback(
    (templateBuildSlug) => {
      trackPreviewClicked({
        ...traits,
        templateBuildSlug,
        archetypeId: archetype,
      });

      const templateDetailsLink = routesMap.templateDetails.url({
        templateSlug: templateBuildSlug,
        category: getTemplateCategory(match.params.category),
      });

      history.push(templateDetailsLink);
    },
    [match.params.category, history, archetype, traits]
  );

  const handleTemplateEditing = useCallback(
    (templateBuildSlug) => {
      trackEditingClicked({
        ...traits,
        templateBuildSlug,
        archetypeId: archetype,
      });

      handleTemplateSelect(templateBuildSlug, triggerIds.TEMPLATE);
    },
    [traits, archetype, handleTemplateSelect]
  );

  const handleBlankTemplateSelect = () => {
    const { stepName } = helpers.getCurrentStep();

    trackSitesetupStartWithBlankTemplateSelected({
      stepId: stepName,
      businessCategory: contextData.businessCategorySlug,
      archetypeId: archetype,
    });

    updateData({ [AI_SITE_WIZARD_SITE_PROP]: aiSiteWizardSitePropValues.SKIPPED });
    handleTemplateSelect(blankTemplate.slug, triggerIds.BLANK_TEMPLATE);
  };

  const onFilterTemplateClick = () => {
    dispatch(
      dialogs.actions.show(dialogs.dialogTypes.FILTER_TEMPLATES_DIALOG, {
        activeCategory,
        categories: availableCategories,
        onSubmit: handleCategorySelect,
      })
    );
  };

  if (!isArchetypeSet && !siteIdToSwitch) {
    history.replace(
      isBusinessTaxonomyEnabled ? routesMap.siteCategory.url() : routesMap.userArchetypes.url()
    );
    return null;
  }

  if (!templates.length || !userData || !locale) return <Layout />;

  return isGalleryLayoutStep ? (
    <GalleryLayoutStep
      captions={captions}
      archetype={archetype}
      activeCategory={activeCategory}
      categories={availableCategories}
      templates={templates}
      categoriesBarRef={categoriesBarRef}
      galleryGridRef={galleryGridRef}
      isSiteToSwitch={Boolean(siteIdToSwitch)}
      isBlankTemplateEnabled={Boolean(blankTemplate)}
      onCategorySelect={handleCategorySelect}
      onTemplatePreviewClick={handleTemplatePreview}
      onTemplateEditClick={handleTemplateEditing}
      onBlankTemplateClick={handleBlankTemplateSelect}
      onFilterTemplateClick={onFilterTemplateClick}
    />
  ) : (
    <GalleryLayout
      captions={captions}
      activeCategory={activeCategory}
      categories={availableCategories}
      templates={templates}
      categoriesBarRef={categoriesBarRef}
      galleryGridRef={galleryGridRef}
      isSiteToSwitch={Boolean(siteIdToSwitch)}
      isBlankTemplateEnabled={Boolean(blankTemplate)}
      onCategorySelect={handleCategorySelect}
      onTemplatePreviewClick={handleTemplatePreview}
      onTemplateEditClick={handleTemplateEditing}
      onBlankTemplateClick={handleBlankTemplateSelect}
    />
  );
}

GalleryLayoutContainer.defaultProps = {
  activeCategory: ALL,
};

GalleryLayoutContainer.propTypes = {
  activeCategory: PropTypes.string,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(GalleryLayoutContainer);
