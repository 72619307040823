const supportedBrowsers = {
  desktop: {
    chrome: '>=67.0',
    firefox: '>=68.0',
    opera: '>=54.0',
    safari: '>=11.1',
    edge: '>=80.0',
  },
  tablet: {
    chrome: '>=78.0',
    safari: '>=12',
    samsung_internet: '>=9.0',
  },
  mobile: {
    chrome: '>=78.0',
    safari: '>=12',
    samsung_internet: '>=9.0',
  },
  iOS: {
    firefox: '>=21.0',
  },
  Android: {
    firefox: '>=68.0',
  },
};

export default supportedBrowsers;
