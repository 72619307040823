import React, { useState } from 'react';
import { NetworkErrorHandler } from '@yola/webapp-plugins';
import useTemplates from 'src/js/modules/templates/hooks/use-templates';
import { redirectToUrl } from 'src/js/utils/redirect-to-url';
import routesMap from 'src/js/router/helpers/routes-map';
import analytics from 'src/js/modules/analytics';
import { APP_NAME } from 'src/js/constants/common';

const DISABLE_SCROLL = 'disable-scroll';

function NetworkErrorHandlerContainer() {
  const [forceShow, setForceShow] = useState(false);
  const { templates } = useTemplates();

  const handleShow = () => {
    document.body.classList.add(DISABLE_SCROLL);
    analytics.segment.track(analytics.segment.eventTypes.NETWORK_STATUS_CHANGED, {
      appName: APP_NAME,
      status: 'offline',
    });
  };

  const handleHide = () => {
    document.body.classList.remove(DISABLE_SCROLL);
    analytics.segment.track(analytics.segment.eventTypes.NETWORK_STATUS_CHANGED, {
      appName: APP_NAME,
      status: 'online',
    });
    setForceShow(!templates.length);
  };

  const reload = () => {
    redirectToUrl(routesMap.index.url());
  };

  const handleDismiss = !templates.length ? reload : null;

  return (
    <NetworkErrorHandler
      onHide={handleHide}
      onShow={handleShow}
      onDismiss={handleDismiss}
      forceShow={forceShow}
    />
  );
}

export default NetworkErrorHandlerContainer;
