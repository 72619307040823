import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import user from 'src/js/modules/user';
import { Icon } from '@yola/ws-ui';

const YBotAuthContainer = () => {
  const userData = useSelector(user.selectors.getUserData, shallowEqual);
  const { id: userId, email: userEmail, employeeId: userEmployeeId } = userData;
  const [isShown, changeDisplayStatus] = useState(Boolean(userEmployeeId));

  const onButtonClick = () => {
    changeDisplayStatus(false);
  };

  if (!isShown) {
    return null;
  }

  return (
    <div className="y-bot-auth-massage">
      You are logged in as {userId} ({userEmail}).
      <Icon glyph="x" strokeWidth="1.3" onClick={() => onButtonClick()} />
    </div>
  );
};

export default YBotAuthContainer;
