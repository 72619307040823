import React, { useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ScrollRestoration from 'react-scroll-restoration';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { once } from 'lodash';
import getLocale from 'src/js/modules/i18n/selectors/locale';
import routesMap, { initializeRoutesMap } from 'src/js/router/helpers/routes-map';
import config from 'src/js/modules/config';
import user from 'src/js/modules/user';
import sites from 'src/js/modules/sites';
import analytics from 'src/js/modules/analytics';
import categories from 'src/js/modules/categories';
import getCurrentStep from '../../modules/site-setup/helpers/get-current-step';
import { SiteSetupContextProvider } from '../../modules/site-setup/helpers/site-setup-context';
import ArchetypesStepContainer from '../../containers/site-setup/archetypes-step-container';
import CategoryStepContainer from '../../containers/site-setup/category-step-container';
import GalleryLayoutContainer from '../../containers/gallery-layout-container';
import SiteNameStepContainer from '../../containers/site-setup/site-name-step-container';
import BusinessNameStepContainer from '../../containers/site-setup/business-name-step-container';
import BusinessDetailsStepContainer from '../../containers/site-setup/business-details-step-container';
import HowToBuildStepContainer from '../../containers/site-setup/how-to-build-step-container';
import TemplateDetailsContainer from '../../containers/template-details-container';
import UseTemplateContainer from '../../containers/use-template-container';
import PlatformSelectorContainer from '../../containers/platform-selector-container';
import TagManagerContainer from '../../containers/tag-manager-container';
import {
  BUSINESS_TAXONOMY_FLAG,
  AI_OPTIONAL_TEMPLATE_SELECTION_STEP_FLAG,
  APP_NAME,
} from '../../constants/common';
import useFeatureFlags from '../../modules/feature-flags/hooks/use-feature-flags';

const {
  helpers: { getCategories },
  categoryIds: { ALL },
} = categories;

const categoriesRoutes = getCategories().map(({ id }) => id);

const getCreateSiteRoute = () => (
  <Route path={routesMap.createSite.pattern()} render={() => <UseTemplateContainer />} />
);

const getTemplateDetailsView = () => (
  <Route
    path={routesMap.templateDetails.pattern()}
    render={({
      match: {
        params: { category, templateSlug },
      },
    }) => (
      <div>
        <TemplateDetailsContainer category={category} templateSlug={templateSlug} />
      </div>
    )}
  />
);

const getArchetypesStep = () => (
  <Route path={routesMap.userArchetypes.pattern()} render={() => <ArchetypesStepContainer />} />
);

const getCategoryStep = () => (
  <Route path={routesMap.siteCategory.pattern()} render={() => <CategoryStepContainer />} />
);

const getGalleryView = () => (
  <Route
    path={routesMap.gallery.pattern()}
    render={({
      match: {
        params: { category },
      },
    }) => <GalleryLayoutContainer activeCategory={category} />}
  />
);

const getSiteNameStep = () => (
  <Route
    path={routesMap.siteName.pattern()}
    render={({
      match: {
        params: { category, templateSlug },
      },
    }) => <SiteNameStepContainer category={category} templateSlug={templateSlug} />}
  />
);

const getBusinessNameStep = () => (
  <Route
    path={routesMap.businessName.pattern()}
    render={({
      match: {
        params: { category, templateSlug },
      },
    }) => <BusinessNameStepContainer category={category} templateSlug={templateSlug} />}
  />
);

const getBusinessDetailsStep = () => (
  <Route
    path={routesMap.businessDetails.pattern()}
    render={({
      match: {
        params: { category, templateSlug },
      },
    }) => <BusinessDetailsStepContainer category={category} templateSlug={templateSlug} />}
  />
);

const getHowToBuildStep = (b2cFullAccess) => (
  <Route
    path={routesMap.howToBuild.pattern()}
    render={() => <HowToBuildStepContainer b2cFullAccess={b2cFullAccess} />}
  />
);

const getPlatformSelectorView = (firstStepUrl) => (
  <Route
    path={routesMap.platformSelector.pattern()}
    render={() => <PlatformSelectorContainer latitudePath={firstStepUrl} />}
  />
);

const callAppOpenedOnce = once((siteId, templateBuildSlug) => {
  analytics.segment.track(analytics.segment.eventTypes.APP_OPENED, {
    appName: APP_NAME,
    siteId,
    templateBuildSlug,
  });
});
const callSiteSetupStartedOnce = once((siteId, stepName) => {
  analytics.segment.track(analytics.segment.eventTypes.SITESETUP_STARTED, {
    appName: APP_NAME,
    stepId: stepName,
    siteId,
  });
});

const getUseTemplateView = () => (
  <Route
    path={routesMap.useTemplate.pattern()}
    render={({
      match: {
        params: { templateSlug },
      },
    }) => <UseTemplateContainer templateSlug={templateSlug} />}
  />
);

const Main = ({
  locale,
  supportedLocales,
  isWhiteLabel,
  platformAccess,
  siteIdRecreate,
  siteIdToSwitchTemplate,
  templateBuildSlug,
  isUserAuthenticated,
}) => {
  const location = useLocation();
  const [featureFlags, , requestedFeatureFlags] = useFeatureFlags([
    BUSINESS_TAXONOMY_FLAG,
    AI_OPTIONAL_TEMPLATE_SELECTION_STEP_FLAG,
  ]);
  const {
    [BUSINESS_TAXONOMY_FLAG]: isBusinessTaxonomyEnabled,
    [AI_OPTIONAL_TEMPLATE_SELECTION_STEP_FLAG]: isOptionalTemplateSelectionStepEnabled,
  } = featureFlags;

  // Used in order to save sequence for APP_OPENED and PLATFORM_SELECTOR_DISPLAYED
  // which is triggered inside useEffect hook of PlatformSelectorContainer
  useLayoutEffect(() => {
    if (locale && isUserAuthenticated) {
      const siteId = siteIdRecreate || siteIdToSwitchTemplate || null;

      callAppOpenedOnce(siteId, templateBuildSlug);
    }
  }, [locale, isUserAuthenticated, siteIdRecreate, siteIdToSwitchTemplate, templateBuildSlug]);

  useEffect(() => {
    if (siteIdToSwitchTemplate) return;

    const { number, stepName } = getCurrentStep();
    if (number) {
      const siteId = siteIdRecreate || null;
      callSiteSetupStartedOnce(siteId, stepName);
    }
  }, [location.pathname, siteIdToSwitchTemplate, siteIdRecreate]);

  const b2cFullAccess =
    !isWhiteLabel &&
    platformAccess === user.platformAccess.ALL &&
    !siteIdRecreate &&
    !siteIdToSwitchTemplate;
  const isPlatformSelectorPageAvailable = b2cFullAccess && !isOptionalTemplateSelectionStepEnabled;

  const firstStepUrl = isBusinessTaxonomyEnabled
    ? routesMap.siteCategory.url()
    : routesMap.userArchetypes.url();
  const galleryPath = siteIdToSwitchTemplate
    ? routesMap.gallery.url({ category: ALL })
    : firstStepUrl;

  const redirectPath = isPlatformSelectorPageAvailable
    ? routesMap.platformSelector.url()
    : galleryPath;
  const isRequitedFlagsLoaded =
    requestedFeatureFlags.includes(BUSINESS_TAXONOMY_FLAG) &&
    requestedFeatureFlags.includes(AI_OPTIONAL_TEMPLATE_SELECTION_STEP_FLAG);

  initializeRoutesMap(supportedLocales, categoriesRoutes);

  if (!locale || (isUserAuthenticated && !isRequitedFlagsLoaded)) return null;

  return (
    <TagManagerContainer>
      <ScrollRestoration />
      <SiteSetupContextProvider>
        {getCreateSiteRoute()}
        <Switch>
          {getArchetypesStep()}
          {getCategoryStep()}
          {getSiteNameStep()}
          {getBusinessNameStep()}
          {getBusinessDetailsStep()}
          {getGalleryView()}
          {getTemplateDetailsView()}
          {getUseTemplateView()}
          {getHowToBuildStep(b2cFullAccess)}
          {isPlatformSelectorPageAvailable && getPlatformSelectorView(firstStepUrl)}
          <Redirect to={redirectPath} />
        </Switch>
      </SiteSetupContextProvider>
    </TagManagerContainer>
  );
};

Main.defaultProps = {
  locale: null,
  supportedLocales: null,
  isWhiteLabel: false,
  platformAccess: null,
  siteIdRecreate: null,
  siteIdToSwitchTemplate: null,
  templateBuildSlug: null,
  isUserAuthenticated: false,
};

Main.propTypes = {
  locale: PropTypes.string,
  supportedLocales: PropTypes.array,
  isWhiteLabel: PropTypes.bool,
  platformAccess: PropTypes.string,
  siteIdRecreate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  siteIdToSwitchTemplate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  templateBuildSlug: PropTypes.string,
  isUserAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const locale = getLocale(state);
  const supportedLocales = (Boolean(locale) && config.selectors.getBaseLanguageCodes(state)) || [];
  const { isWhiteLabel, signupDate } = user.selectors.getUserData(state);
  const { platformAccess } = user.selectors.getUserPreferences(state) || {};
  const siteIdRecreate = sites.selectors.getSiteIdRecreate(state);
  const siteIdToSwitchTemplate = sites.selectors.getSiteIdToSwitchTemplate(state);
  const templateBuildSlug = sites.selectors.getTemplateBuildSlug(state);
  const isUserAuthenticated = user.selectors.getUserAuthorizationStatus(state);

  return {
    locale,
    supportedLocales,
    isWhiteLabel,
    signupDate,
    platformAccess,
    isUserAuthenticated,
    siteIdRecreate,
    siteIdToSwitchTemplate,
    templateBuildSlug,
  };
};

export default connect(mapStateToProps, null)(Main);
