import track from '../helpers/track';
import eventTypes from '../constants/event-types';
import { APP_NAME } from '../../../../constants/common';

const trackSitesetupBusinessCategoryProvided = ({
  siteId,
  stepId,
  businessCategory,
  archetypeId,
}) => {
  track(eventTypes.SITESETUP_BUSINESS_CATEGORY_PROVIDED, {
    appName: APP_NAME,
    siteId,
    stepId,
    businessCategory,
    archetypeId,
  });
};

export default trackSitesetupBusinessCategoryProvided;
