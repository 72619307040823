import React, { memo, useCallback, useState, useMemo } from 'react';
import { designSystem } from '@yola/ws-ui';
import PropTypes from 'prop-types';
import siteSetup from 'src/js/modules/site-setup';
import HeaderGalleryPageContainer from 'src/js/containers/header-gallery-page-container';
import SiteSetupLayout from '../site-setup-layout';
import Layout from '../../layout';

const { UserArchetypes, UserArchetypeCard, Container } = designSystem;

function ArchetypesStep({ captions, items, onSkip, onCardClick, ...otherProps }) {
  const currentCard = items.find((item) => item.active)?.value || null;
  const { number: currentStep } = useMemo(siteSetup.helpers.getCurrentStep, []);
  const [activeCard, setActiveCard] = useState(currentCard);

  const onCardClickHandler = useCallback(
    (value) => {
      onCardClick(value);
      setActiveCard(value);
    },
    [onCardClick]
  );

  return (
    <Layout Header={HeaderGalleryPageContainer} headerOnSkip={onSkip}>
      <SiteSetupLayout captions={captions} currentStep={currentStep} {...otherProps}>
        <Container>
          <UserArchetypes>
            {items.map(({ icon, title, description, value }) => (
              <UserArchetypeCard
                iconGlyph={icon}
                title={title}
                description={description}
                active={value === activeCard}
                key={value}
                onClick={() => onCardClickHandler(value)}
              />
            ))}
          </UserArchetypes>
        </Container>
      </SiteSetupLayout>
    </Layout>
  );
}

ArchetypesStep.defaultProps = {
  onCardClick: Function.prototype,
};

ArchetypesStep.propTypes = {
  captions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    buttons: PropTypes.shape({
      next: PropTypes.string,
      skip: PropTypes.string,
    }),
  }).isRequired,
  items: PropTypes.array.isRequired,
  onSkip: PropTypes.func.isRequired,
  onCardClick: PropTypes.func,
};

export default memo(ArchetypesStep);
