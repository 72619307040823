import actionTypes from '../constants/action-types';

const siteIdRecreate = (state = '', action) => {
  switch (action.type) {
    case actionTypes.SET_SITE_ID_RECREATE:
      return action.payload.siteId;
    default:
      return state;
  }
};

export default siteIdRecreate;
