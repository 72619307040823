import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ErrorBoundary from './error-boundary';
import Main from './components/main';
import DialogsContainer from './modules/dialogs/containers/dialogs-container';
import NetworkErrorHandlerContainer from './containers/network-error-container';
import { FeatureFlagsProvider } from './modules/feature-flags/context';

const App = () => (
  <FeatureFlagsProvider>
    <ErrorBoundary>
      <BrowserRouter>
        <Main />
        <DialogsContainer />
      </BrowserRouter>
    </ErrorBoundary>
    <NetworkErrorHandlerContainer />
  </FeatureFlagsProvider>
);

export default App;
