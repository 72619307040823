import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { designSystem } from '@yola/ws-ui';

const { ActionButton } = designSystem;

const BlankTemplateActionButton = ({ fullWidth, onClick }) => (
  <ActionButton
    format="text"
    iconGlyph="plus-in-circle"
    label={t('Start from scratch')}
    fullWidth={fullWidth}
    onClick={onClick}
  />
);

BlankTemplateActionButton.propTypes = {
  fullWidth: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

BlankTemplateActionButton.defaultProps = {
  fullWidth: false,
};

export default BlankTemplateActionButton;
