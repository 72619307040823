import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import { t } from 'i18next';
import { designSystem, reactHookForm } from '@yola/ws-ui';
import user from 'src/js/modules/user';
import trackers from 'src/js/modules/analytics/segment/trackers';
import siteSetup from 'src/js/modules/site-setup';
import { SiteSetupContext } from 'src/js/modules/site-setup/helpers/site-setup-context';
import routesMap from 'src/js/router/helpers/routes-map';
import HeaderGalleryPageContainer from 'src/js/containers/header-gallery-page-container';
import SiteSetupLayout from 'src/js/components/site-setup/site-setup-layout';
import Layout from 'src/js/components/layout';
import getTotalSteps from 'src/js/modules/site-setup/helpers/get-total-steps';
import getLastStepButtonText from 'src/js/modules/site-setup/helpers/get-last-step-button-text';
import useFeatureFlags from 'src/js/modules/feature-flags/hooks/use-feature-flags';
import validateRequired from 'src/js/helpers/validate-required';
import { AI_SITE_WIZARD_FLAG, BUSINESS_TAXONOMY_FLAG, ENTER_KEY } from 'src/js/constants/common';
import getLocale from '../../modules/i18n/selectors/locale';
import getLanguageCodesOptions from '../../modules/site-setup/helpers/get-language-code-options';

const { Container, InputGroupField, ControlGroup, Stack, LanguageSelectField } = designSystem;
const { useForm } = reactHookForm;
const { trackSiteSetupSiteNameProvided } = trackers;
const {
  constants: { common, validationPatterns },
  helpers,
} = siteSetup;
const { MAX_SITE_NAME_CHARS, MIN_SITE_NAME_CHARS } = common;

const getCaptions = ({ isLastStep, isWl, userFullName, isAISiteWizardEnabled }) => ({
  title: t("Let's fill some basic information"),
  buttons: {
    next: isLastStep ? getLastStepButtonText(isAISiteWizardEnabled) : t('Continue'),
  },
  error: {
    required: t('This field is required'),
    email: t('Invalid email. Please, check the email and try again'),
    maxCharacters: t('Maximum number of characters {number}', {
      number: MAX_SITE_NAME_CHARS,
    }),
    minCharacters: t('Minimum number of characters {number}', {
      number: MIN_SITE_NAME_CHARS,
    }),
  },
  fields: {
    email: {
      label: t('Email'),
      placeholder: t('Enter your email'),
    },
    siteName: {
      label: t('Website name'),
      placeholder: isWl ? t('My website') : t("{userFullName}'s site", { userFullName }),
    },
    locale: {
      label: t('Website language'),
    },
  },
});

function SiteNameStepContainer() {
  const history = useHistory();
  const location = useLocation();
  const [isLanguageSelectFocused, setLanguageSelectFocusedState] = useState(false);
  const [featureFlags] = useFeatureFlags([AI_SITE_WIZARD_FLAG, BUSINESS_TAXONOMY_FLAG]);
  const {
    [AI_SITE_WIZARD_FLAG]: isAISiteWizardEnabled,
    [BUSINESS_TAXONOMY_FLAG]: isBusinessTaxonomyEnabled,
  } = featureFlags;
  const userData = useSelector(user.selectors.getUserData);
  const isAuthorized = useSelector(user.selectors.getUserAuthorizationStatus);
  const defaultLocale = useSelector(getLocale);
  const context = useContext(SiteSetupContext);
  const { getArchetype, getData, updateData } = context;
  const contextData = getData();
  const archetype = getArchetype();
  const isArchetypeSet = Boolean(archetype !== undefined);

  if (!isArchetypeSet || !isAuthorized) {
    history.replace(
      isBusinessTaxonomyEnabled ? routesMap.siteCategory.url() : routesMap.userArchetypes.url()
    );
  }

  const { number: currentStep } = useMemo(helpers.getCurrentStep, []);
  const total = getTotalSteps(archetype, isAISiteWizardEnabled);
  const { name: userName, surname: userSurname } = userData;
  const isWl = userData.isWhiteLabel;
  const userFullName = userSurname?.trim() ? `${userName} ${userSurname}` : userName;
  const captions = useMemo(
    () =>
      getCaptions({
        isLastStep: currentStep === total,
        isWl,
        userFullName,
        isAISiteWizardEnabled,
      }),
    [currentStep, total, isWl, userFullName, isAISiteWizardEnabled]
  );
  const localeOptions = useMemo(getLanguageCodesOptions, []);

  const shouldShowEmailField = isWl && isAISiteWizardEnabled;

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      siteName: contextData.siteName || captions.fields.siteName.placeholder,
      email: contextData.email || '',
      locale: contextData.locale || defaultLocale,
    },
    mode: isAISiteWizardEnabled ? 'onSubmit' : 'onTouched',
  });

  const onNext = useCallback(() => {
    handleSubmit((data) => {
      trackSiteSetupSiteNameProvided({
        siteName: data.siteName || null,
        archetypeId: archetype,
        businessCategory: contextData.businessCategorySlug,
      });

      updateData({
        siteName: data.siteName.trim(),
        locale: data.locale,
        ...(isWl && { email: data.email.trim() }),
      });

      if (archetype && !isAISiteWizardEnabled) {
        history.push(routesMap.businessName.url());
      } else {
        history.replace(routesMap.createSite.url({ currentPath: location.pathname }));
      }
    })();
  }, [
    handleSubmit,
    archetype,
    updateData,
    isWl,
    isAISiteWizardEnabled,
    history,
    location.pathname,
  ]);

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === ENTER_KEY && (isAISiteWizardEnabled || isValid) && !isLanguageSelectFocused) {
        onNext();
      }
    },
    [isValid, onNext, isAISiteWizardEnabled, isLanguageSelectFocused]
  );

  const handleLanguageSelectFocus = () => {
    setLanguageSelectFocusedState(true);
  };

  const handleLanguageSelectBlur = () => {
    setLanguageSelectFocusedState(false);
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <Layout Header={HeaderGalleryPageContainer}>
      <SiteSetupLayout
        className="ws-site-setup-layout-site-name"
        captions={captions}
        currentStep={currentStep}
        next={{
          callback: onNext,
          disabled: isAISiteWizardEnabled ? false : !isValid,
        }}
      >
        <Container>
          <Stack gap="spacing-m">
            <ControlGroup title={captions.fields.siteName.label} required>
              <InputGroupField
                name="siteName"
                control={control}
                size="large"
                placeholder={captions.fields.siteName.placeholder}
                rules={{
                  validate: (value) => validateRequired(value, captions.error.required),
                  maxLength: {
                    value: MAX_SITE_NAME_CHARS,
                    message: captions.error.maxCharacters,
                  },
                  minLength: {
                    value: MIN_SITE_NAME_CHARS,
                    message: captions.error.minCharacters,
                  },
                }}
              />
            </ControlGroup>
            {shouldShowEmailField && (
              <ControlGroup title={captions.fields.email.label} required>
                <InputGroupField
                  size="large"
                  iconGlyph="mail"
                  placeholder={captions.fields.email.placeholder}
                  name="email"
                  control={control}
                  rules={{
                    validate: (value) => validateRequired(value, captions.error.required),
                    pattern: {
                      value: validationPatterns.email,
                      message: captions.error.email,
                    },
                  }}
                />
              </ControlGroup>
            )}
            <ControlGroup title={captions.fields.locale.label}>
              <LanguageSelectField
                name="locale"
                control={control}
                options={localeOptions}
                appearance="searchable"
                size="large"
                onFocus={handleLanguageSelectFocus}
                onBlur={handleLanguageSelectBlur}
              />
            </ControlGroup>
          </Stack>
        </Container>
      </SiteSetupLayout>
    </Layout>
  );
}

export default withRouter(SiteNameStepContainer);
