import config from 'src/js/modules/config';
import user from 'src/js/modules/user';
import sentryInstance from './sentry/instance';
import identify from './segment/helpers/identify';
import tagManager from './tag-manager';
import isAnalyticsEnabled from './verifiers/is-analytics-enabled';

const init = (store) => {
  const state = store.getState();
  sentryInstance.init(config.selectors.getSentryDSN(state));
  tagManager.init(config.selectors.getGTM(state));

  const userData = user.selectors.getUserData(state);
  const preferences = user.selectors.getUserPreferences(state);
  if (userData) {
    const { id } = userData;
    sentryInstance.setUserId(id);

    const analyticsEnabled = isAnalyticsEnabled();
    identify(
      id,
      {
        frontEndTrackingEnabled: analyticsEnabled,
      },
      () => {
        const { frontendTrackingEnabled } = preferences;

        if (
          (frontendTrackingEnabled === 'True' && analyticsEnabled) ||
          (!analyticsEnabled && frontendTrackingEnabled === 'False')
        )
          return;

        store.dispatch(
          user.actions.updateUserPreference(
            user.preferences.FRONTEND_TRACKING_ENABLED,
            analyticsEnabled
          )
        );
      }
    );
  }
};

export default init;
