import categories from '../modules/categories';
import siteSetup from '../modules/site-setup';

const {
  constants: { archetypesMap },
} = siteSetup;

const getGalleryCategory = (type) => {
  switch (type) {
    case archetypesMap.NON_PROFIT:
    case archetypesMap.COMMUNITY:
      return categories.categoryIds.COMMUNITY_OR_ORGANIZATION;
    case archetypesMap.EVENTS:
      return categories.categoryIds.BUSINESS;
    case archetypesMap.OTHER:
      return categories.categoryIds.ALL;
    default:
      return type;
  }
};

export default getGalleryCategory;
