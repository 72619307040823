import { DEFAULT_LOGO_URL } from 'src/js/constants/common';
import getPartnerLogo from '../../partner/selectors/partner-logo';

const getLogoSrc = (state) => {
  const partnerLogo = getPartnerLogo(state);

  return !partnerLogo ? DEFAULT_LOGO_URL : partnerLogo;
};

export default getLogoSrc;
