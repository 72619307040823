import archetypesMap from 'src/js/modules/site-setup/constants/archetypes-map';

const { PERSONAL, BUSINESS, ONLINE_STORE, NON_PROFIT } = archetypesMap;
const getTotalSteps = (archetype, isAISiteWizardEnabled) => {
  switch (archetype) {
    case BUSINESS:
    case ONLINE_STORE:
    case NON_PROFIT:
      return isAISiteWizardEnabled ? 3 : 5;
    case PERSONAL:
      return isAISiteWizardEnabled ? 3 : 4;
    default:
      return 3;
  }
};

export default getTotalSteps;
