import track from '../helpers/track';
import eventTypes from '../constants/event-types';
import { APP_NAME } from '../../../../constants/common';

const trackLivePreviewDeviceClicked = ({ siteId, templateBuildSlug, typeId }) => {
  track(eventTypes.LIVE_PREVIEW_DEVICE_CLICKED, {
    appName: APP_NAME,
    siteId,
    templateBuildSlug,
    typeId,
  });
};

export default trackLivePreviewDeviceClicked;
