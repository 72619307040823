import reduxCatch from 'redux-catch';
import thunk from 'redux-thunk';
import analytics from 'src/js/modules/analytics';
import error from 'src/js/modules/error';
import user from 'src/js/modules/user';
import featureFlags from 'src/js/modules/feature-flags';
import templates from 'src/js/modules/templates';
import serviceClient from 'src/js/modules/service-client';
import branding from 'src/js/modules/branding';
import i18n from 'src/js/modules/i18n';
import subscriptions from 'src/js/modules/subscriptions';
import webappPlugins from 'src/js/modules/webapp-plugins';
import helpCenter from 'src/js/modules/help-center';

export default [
  thunk,
  reduxCatch(error.helpers.catchReduxError),
  error.middleware.errorHandler,
  analytics.segment.middleware.segmentLoader,
  serviceClient.middleware.clientLoader,
  user.middleware.userPreferencesManager,
  featureFlags.middleware.clientLoader,
  templates.middleware.createSiteByTemplate,
  branding.middleware.colorsInjector,
  i18n.middleware.translationsLoader,
  subscriptions.middleware.subscriptionsFetcher,
  webappPlugins.middleware.configuration,
  helpCenter.middleware.initHelpCenter,
];
