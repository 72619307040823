import yousersjs from '@yola/yousersjs';
import { snakeCaseObjectToCamelCase } from 'src/js/utils/convert-case';
import actions from '../actions';

const fetchUserPreferences = () => async (dispatch) => {
  try {
    const response = await yousersjs.preferences().get();
    const data = snakeCaseObjectToCamelCase(response.body);
    dispatch(actions.setUserPreferences(data));
  } catch (e) {
    console.error('Failed to fetch user preferences');
    console.error(e);
  }
};

export default fetchUserPreferences;
