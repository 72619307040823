import getLocaleFromUrl from '../../modules/i18n/helpers/get-locale-from-url';
import getSearchParamsString from '../../utils/get-search-params-string';

const normalizePath = (path) => (path ? `/${path}` : '');

let localesPattern = ':locale?';
let categoriesPattern = ':category?';
let locale = '';
let sort = ':sort?';

export const initializeRoutesMap = (locales = [], categories = []) => {
  const localeFromUrl = getLocaleFromUrl();
  locale = normalizePath(locales.includes(localeFromUrl) && localeFromUrl);
  localesPattern = `:locale(${locales.join('|')})?`;
  categoriesPattern = `:category(${categories.join('|')})`;
  sort = `:sort(popular)?`;
};

export default {
  index: {
    pattern: () => `/${localesPattern}`,
    url: () => `${locale}${getSearchParamsString()}`,
  },
  userArchetypes: {
    pattern: () => `/${localesPattern}/user-archetypes`,
    url: () => `${locale}/user-archetypes${getSearchParamsString()}`,
  },
  siteCategory: {
    pattern: () => `/${localesPattern}/specify-category`,
    url: () => `${locale}/specify-category${getSearchParamsString()}`,
  },
  gallery: {
    pattern: () => `/${localesPattern}/${categoriesPattern}`,
    url: ({ category }) => `${locale}/${category}${getSearchParamsString()}`,
  },
  siteName: {
    pattern: () => `/${localesPattern}/site-name/`,
    url: () => `${locale}/site-name${getSearchParamsString()}`,
  },
  businessName: {
    pattern: () => `/${localesPattern}/business-name`,
    url: () => `${locale}/business-name${getSearchParamsString()}`,
  },
  businessDetails: {
    pattern: () => `/${localesPattern}/business-details`,
    url: () => `${locale}/business-details${getSearchParamsString()}`,
  },
  templateDetails: {
    pattern: () => `/${localesPattern}/${sort}/template/${categoriesPattern}/:templateSlug`,
    url: ({ category, templateSlug }) =>
      `${locale}/template/${category}/${templateSlug}${getSearchParamsString()}`,
  },
  createSite: {
    pattern: () => `/${localesPattern}/:step?/${categoriesPattern}?/:templateSlug?/create`,
    url: ({ currentPath }) => `${currentPath}/create`,
  },
  platformSelector: {
    pattern: () => `/${localesPattern}/platform`,
    url: () => `${locale}/platform${getSearchParamsString()}`,
  },
  useTemplate: {
    pattern: () => `/${localesPattern}/use-template/:templateSlug`,
    url: ({ templateSlug }) => `/use-template/${templateSlug}${getSearchParamsString()}`,
  },
  import: {
    pattern: () => `/import`,
    url: () => `/import${getSearchParamsString()}`,
  },
  legacyTemplates: {
    pattern: () => `/${localesPattern}/legacy-templates`,
    url: () => `${locale}/legacy-templates${getSearchParamsString()}`,
  },
  howToBuild: {
    pattern: () => `/${localesPattern}/select-how-to-build`,
    url: () => `${locale}/select-how-to-build${getSearchParamsString()}`,
  },
};
