import React from 'react';
import PropTypes from 'prop-types';
import DefaultHeader from '../../containers/header-gallery-page-container';
import LoadingOverlayContainer from '../../containers/loading-overlay-container';
import YBotAuthContainer from '../y-bot-auth/index';

const Layout = ({ children, Header, headerOnSkip }) => (
  <React.Fragment>
    <YBotAuthContainer />
    <Header onSkip={headerOnSkip} />
    {children}
    <LoadingOverlayContainer />
  </React.Fragment>
);

Layout.propTypes = {
  children: PropTypes.node,
  Header: PropTypes.elementType,
  headerOnSkip: PropTypes.func,
};

Layout.defaultProps = {
  children: null,
  Header: DefaultHeader,
  headerOnSkip: null,
};

export default Layout;
