/* eslint-disable yola/import/no-extraneous-dependencies */
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import reducer from '../reducer';
import middleware from '../middleware';
import connectedStore from './connected-store';

const store = createStore(reducer, composeWithDevTools(applyMiddleware(...middleware)));

connectedStore.set(store);

export default store;
