import actionTypes from 'src/js/modules/templates/constants/action-types';

const createSiteByTemplate = (conceptId, name, templateSlug, locale, properties, settings) => ({
  type: actionTypes.CREATE_SITE,
  payload: {
    conceptId,
    name,
    templateSlug,
    locale,
    properties,
    settings,
  },
});

export default createSiteByTemplate;
