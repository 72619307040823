import actionTypes from '../constants/action-types';

const trackError = (error) => ({
  type: actionTypes.TRACK_ERROR,
  payload: {
    logSentry: true,
    error,
  },
});

export default trackError;
