import actionTypes from '../constants/action-types';

const active = (state = [], action) => {
  switch (action.type) {
    case actionTypes.SET_ACTIVE_SUBSCRIPTIONS:
      return action.payload.subscriptions;
    default:
      return state;
  }
};

export default active;
