import constants from '../constants';
import getCalculatedSiteSetupStepsMap from './get-calculated-site-setup-steps-map';
import categories from '../../categories';

const { siteSetupNames } = constants;

const { categoryIds } = categories;

const getCurrentStep = () => {
  const siteSetupStepsMap = getCalculatedSiteSetupStepsMap();
  let pathName;

  Object.values({ ...siteSetupNames, ...categoryIds }).forEach((item) => {
    if (pathName) return;
    pathName = window.location.pathname.split('/').find((step) => step === item);
  });

  const number = siteSetupStepsMap[pathName];
  const stepName =
    number === siteSetupStepsMap[categoryIds.ALL] ? siteSetupNames.GALLERY : pathName;

  return {
    number,
    stepName,
  };
};

export default getCurrentStep;
