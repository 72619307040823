import get from 'lodash.get';
import subscriptions from 'src/js/modules/subscriptions';
import isOnlineStoreTemplate from '../verifiers/is-online-store-template';
import isBlankTemplate from '../verifiers/is-blank-template';

const filterAvailableTemplates = (
  templates,
  featureFlags,
  availableSubscriptionTypes,
  isUserAuthenticated
) => {
  const {
    legacy_latitude_templates: isLegacyLatitudeTemplatesPermitted,
    blank_template: isBlankTemplatePermitted,
  } = featureFlags;
  const hasEcommerceSubscription = subscriptions.verifiers.hasEcommerceSubscription(
    availableSubscriptionTypes
  );

  let filteredTemplates = templates;

  if (isUserAuthenticated && !hasEcommerceSubscription) {
    filteredTemplates = templates.filter((template) => !isOnlineStoreTemplate(template));
  }

  if (!isBlankTemplatePermitted) {
    filteredTemplates = filteredTemplates.filter((template) => !isBlankTemplate(template));
  }

  if (isLegacyLatitudeTemplatesPermitted) {
    return filteredTemplates;
  }

  return filteredTemplates.filter((template) => !get(template, 'properties.hidden'));
};

export default filterAvailableTemplates;
