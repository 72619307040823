import yousersjs from '@yola/yousersjs';
import user from 'src/js/modules/user';
import { snakeCaseObjectToCamelCase } from 'src/js/utils/convert-case';

const removeSSOParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.delete('auth_token');
  searchParams.delete('expires');
  searchParams.delete('user_id');
  searchParams.delete('employee_id');
  let searchString = searchParams.toString();
  if (searchString.length) {
    searchString = `?${searchString}`;
  }
  const URLWithoutSSOParams = `${window.location.pathname}${searchString}`;
  window.history.replaceState(null, window.document.title, URLWithoutSSOParams);
};

const authenticate = () => async (dispatch) => {
  try {
    const response = await yousersjs.authenticate();
    const data = snakeCaseObjectToCamelCase(response.body);

    removeSSOParams();
    dispatch(user.actions.setUserData(data));
  } catch (e) {
    console.error('Failed to authorize the user');
    console.error(e);
  }
};

export default authenticate;
