import subscriptionTypes from '../constants/subscription-types';

const {
  ECOMMERCE_FREE,
  ECOMMERCE_BASIC,
  ECOMMERCE_PROFESSIONAL,
  ECOMMERCE_UNLIMITED,
  B2C_ECOMMERCE,
  B2C_ECOMMERCE_FREE,
  B2C_ECOMMERCE_BASIC,
  B2C_ECOMMERCE_PROFESSIONAL,
  B2C_ECOMMERCE_UNLIMITED,
  WL_PREMIUM_ECOMM,
  ECOMMERCE_LEGACY,
} = subscriptionTypes;

const hasEcommerceSubscription = (activeSubscriptions) =>
  activeSubscriptions.some(
    (subscription) =>
      subscription.toLowerCase() === ECOMMERCE_FREE ||
      subscription.toLowerCase() === ECOMMERCE_BASIC ||
      subscription.toLowerCase() === ECOMMERCE_PROFESSIONAL ||
      subscription.toLowerCase() === ECOMMERCE_UNLIMITED ||
      subscription.toLowerCase() === ECOMMERCE_LEGACY ||
      subscription.toLowerCase() === B2C_ECOMMERCE ||
      subscription.toLowerCase() === B2C_ECOMMERCE_FREE ||
      subscription.toLowerCase() === B2C_ECOMMERCE_BASIC ||
      subscription.toLowerCase() === B2C_ECOMMERCE_PROFESSIONAL ||
      subscription.toLowerCase() === B2C_ECOMMERCE_UNLIMITED ||
      subscription.toLowerCase() === WL_PREMIUM_ECOMM
  );

export default hasEcommerceSubscription;
