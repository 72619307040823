import yosubscriptionsjs from '@yola/yosubscriptionsjs';
import config from 'src/js/modules/config';
import actionTypes from '../constants/action-types';
import setActive from '../actions/set-active';
import subscriptionTypes from '../constants/subscription-types';

const subscriptionsFetcher = (store) => (next) => (action) => {
  switch (action.type) {
    case actionTypes.FETCH_ACTIVE_SUBSCRIPTIONS: {
      const state = store.getState();
      const origin = config.selectors.getApiUrl(state);
      yosubscriptionsjs({ origin })
        .subscriptions({
          statusIn: subscriptionTypes.ACTIVE_TYPES,
        })
        .then((data) => {
          store.dispatch(setActive(data.body));
          next(action);
        })
        .catch(() => {
          next(action);
        });
      break;
    }
    default:
      next(action);
  }
};

export default subscriptionsFetcher;
