import actionTypes from '../constants/action-types';

const defaultState = {
  spinnerText: null,
  spinnerIsShown: null,
};

const spinner = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_SPINNER:
      return action.payload;
    case actionTypes.HIDE_SPINNER:
      return action.payload;
    default:
      return state;
  }
};

export default spinner;
