import getUserData from './get-user-data';
import getUserPreferences from './user-preferences';
import getUserAuthorizationStatus from './user-authorization-status';
import getUserPartnerId from './user-partner-id';

export default {
  getUserData,
  getUserPreferences,
  getUserAuthorizationStatus,
  getUserPartnerId,
};
