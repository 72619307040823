import isObject from './is-object';

export function capitalize(word) {
  if (!word) {
    return '';
  }
  return `${word[0].toUpperCase()}${word.slice(1)}`;
}

export function decapitalize(word) {
  if (!word) {
    return '';
  }
  return `${word[0].toLowerCase()}${word.slice(1)}`;
}

export function camelCaseToSnakeCase(string) {
  return string
    .replace(/(.)([A-Z][a-z]+)/g, '$1_$2') // insert underscores before words
    .replace(/([a-z0-9])([A-Z]+)/g, '$1_$2') // insert underscores before abbreviations
    .toLowerCase();
}

export function snakeCaseToCamelCase(string) {
  const camelCaseString = string
    .split('_')
    .map(capitalize)
    .join('');
  return decapitalize(camelCaseString);
}

export function snakeCaseObjectToCamelCase(obj) {
  const isArray = Array.isArray(obj);
  return Object.keys(obj)
    .map((key) => {
      let value = obj[key];
      if (isObject(value) || Array.isArray(value)) {
        value = snakeCaseObjectToCamelCase(value);
      }
      return { [snakeCaseToCamelCase(key)]: value };
    })
    .reduce(
      (accumulator, currentValue) => Object.assign(accumulator, currentValue),
      isArray ? [] : {}
    );
}

export function camelCaseObjectToSnakeCase(obj) {
  const isArray = Array.isArray(obj);
  return Object.keys(obj)
    .map((key) => {
      let value = obj[key];
      if (isObject(value) || Array.isArray(value)) {
        value = camelCaseObjectToSnakeCase(value);
      }
      return { [camelCaseToSnakeCase(key)]: value };
    })
    .reduce(
      (accumulator, currentValue) => Object.assign(accumulator, currentValue),
      isArray ? [] : {}
    );
}
