import { get } from 'lodash';
import isAuthComponentAvailable from '../../partner/verifiers/is-auth-component-available';
import getPartnerLoginUrl from '../../partner/selectors/partner-login-url';
import getIsB2C from './is-b2c';

const getLoginUrl = (rootState) => {
  if (getIsB2C(rootState)) {
    return get(rootState, 'config.service.common.auth-gateway.url', null);
  }

  const partnerLoginUrl = getPartnerLoginUrl(rootState);

  if (!isAuthComponentAvailable() && partnerLoginUrl) {
    return partnerLoginUrl;
  }

  return get(rootState, 'config.service.common.auth-gateway.wl_url', null);
};

export default getLoginUrl;
