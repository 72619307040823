const shouldSiteRecreate = (multisiteMode, siteIdRecreate, userSites) => {
  const { results, count } = userSites;
  if (!count) return false;

  if (!multisiteMode) {
    return !!results[0].resetAt;
  }

  if (!siteIdRecreate) return false;

  const currentUserSiteData = results.find((site) => site.id === siteIdRecreate);
  return !!currentUserSiteData.resetAt;
};

export default shouldSiteRecreate;
