/**
 * VH units workaround for iFrame on iOS 10+ (May 17 2018)
 *
 * The Width/Height of IFrame on iOS, whatever the values you set, will always have width/height
 * equal to width/height of the document, which gets rendered inside that iFrame. Furthermore,
 * the width/height of iFrame is calculated regarding the entire render tree, not its visible parts.
 *
 * This behavior brings tons of bugs and issues. One of those issues is solved by this workaround.
 *
 * This, solve the issue with css value defined with VH units. Workaround create a cascade
 * style overrides for all selectors with vh units defined in all <link>, <style> tags of the page
 * and inline styles of elements.
 *
 * However, this workaround is not solve the problem completely, since vh values can still be
 * create dynamically with JS. In order to fix it completely, this workaround should be extended
 * with monkey-patching of following HTMLElement methods/props:
 * - HTMLElement.prototype.innerHTML
 * - HTMLElement.prototype.appendChild
 * - HTMLElement.prototype.insertBefore
 * - HTMLElement.prototype.insertAdjacentElement
 * - HTMLElement.prototype.insertAdjacentHTML
 * - HTMLElement.prototype.style
 * - HTMLElement.prototype.setAttribute
 *
 * And other methods that can introduce new styles on the page.
 */

import axios from 'axios';
import { VirtualStyleSheet } from 'virtual-stylesheets';
import isAbsoluteURL from './is-absolute-url';
import getBasePath from './get-base-path';
import isOldIOSBrowser from './is-old-ios-browser';

const STYLE_RULE = 1;
const HAS_VH_VALUES = /\d+vh/i;
const VH_VALUES = /(\d?\.?\d+)vh/gi;
const BUGFIX_CLASSNAME = 'ios-vh-units-bugfix';
const ORIGINAL_STYLE_CONTAINER = 'data-ios-vh-style';
// This value is used for adding to windowHeight in order to make iframe document scrollable
// which prevent elastic scroll on the root document
const PIXEL = 1;

const getWindowSize = (frameDocument) => {
  const frame = frameDocument.defaultView.frameElement;
  const syncyFrame = frame.parentNode;
  const view = syncyFrame.parentNode;
  const { clientHeight, clientWidth } = view;
  const bounds = getComputedStyle(view);

  return {
    width: clientWidth - parseInt(bounds.paddingLeft, 10) - parseInt(bounds.paddingRight, 10),
    height: clientHeight - parseInt(bounds.paddingTop, 10) - parseInt(bounds.paddingBottom, 10),
  };
};

const generateCSS = (frameDocument, rules) => {
  if (!rules.length) return null;

  const { height } = getWindowSize(frameDocument);
  const windowHeight = height + PIXEL;

  return rules.reduce((cssRules, rule) => {
    const selector = rule.selectorText;
    const declarations = rule.style
      .filter((declaration) => HAS_VH_VALUES.test(declaration.value))
      .reduce((cssDeclarations, declaration) => {
        const value = declaration.value.replace(
          VH_VALUES,
          (match, p1) => `${(p1 / 100) * windowHeight}px`
        );
        return `${cssDeclarations}${declaration.property}:${value};`;
      }, '');

    let cssRule = `${selector}{${declarations}}`;
    if (rule.parentRule.media) cssRule = `@media ${rule.parentRule.media}{${cssRule}}`;

    return cssRules + cssRule;
  }, '');
};

const findRulesWithVHUnits = (rule) => {
  if (!rule || !rule.rules) return [];
  // eslint-disable-next-line no-underscore-dangle
  return rule.rules._items.reduce((filteredRules, subRule) => {
    if (subRule.type === STYLE_RULE && HAS_VH_VALUES.test(subRule.cssText)) {
      filteredRules.push(subRule);
      return filteredRules;
    }

    const subRules = findRulesWithVHUnits(subRule);
    if (subRules && subRules.length) return filteredRules.concat(subRules);

    return filteredRules;
  }, []);
};

const createPixelOverrides = (frameDocument, cssText) => {
  const vsm = new VirtualStyleSheet(cssText);
  const rules = findRulesWithVHUnits(vsm);
  return generateCSS(frameDocument, rules);
};

const injectPixelOverrides = (refElement, cssText) => {
  if (!cssText) return;
  const frameDocument = refElement.ownerDocument;
  const nextSibling = refElement.nextElementSibling;
  const isFixInjectedAlready = nextSibling && nextSibling.className.indexOf(BUGFIX_CLASSNAME) > -1;

  const style = isFixInjectedAlready ? nextSibling : frameDocument.createElement('style');
  style.className = BUGFIX_CLASSNAME;
  style.innerHTML = cssText;

  refElement.insertAdjacentElement('afterend', style);
};

const processLinks = (frameDocument, selector) => {
  const links = frameDocument.querySelectorAll(selector);
  const base = frameDocument.querySelector('base');
  const basepath = base ? base.href : getBasePath(frameDocument.location.href);

  links.forEach((link) => {
    const type = link.getAttribute('type');
    const rel = link.getAttribute('rel');
    const href = link.getAttribute('href');

    if (!href) return;
    if (rel && rel !== 'stylesheet') return;
    if (type && type !== 'text/css') return;

    const url = isAbsoluteURL(href) ? href : basepath + href;

    // const withCredentials = url.includes(state.ws.config.service.common['ws-api'].url);
    // axios.get(url, { withCredentials }).then((response) => {
    axios.get(url).then((response) => {
      injectPixelOverrides(link, createPixelOverrides(frameDocument, response.data));
    });
  });
};

const processStyles = (frameDocument, selector) => {
  const styles = frameDocument.querySelectorAll(selector);

  styles.forEach((style) => {
    injectPixelOverrides(style, createPixelOverrides(frameDocument, style.innerHTML));
  });
};

const processInlineStyles = (frameDocument, selector) => {
  const elements = frameDocument.querySelectorAll(selector);
  elements.forEach((element) => {
    const style = element.getAttribute(ORIGINAL_STYLE_CONTAINER) || element.getAttribute('style');
    const newStyle = style.replace(VH_VALUES, (match, p1) => {
      const frame = frameDocument.defaultView.frameElement;
      const syncyFrame = frame.parentNode;
      const view = syncyFrame.parentNode;
      const windowHeight = view.offsetHeight;
      return `${(p1 / 100) * windowHeight}px`;
    });

    element.setAttribute(ORIGINAL_STYLE_CONTAINER, style);
    element.setAttribute('style', newStyle);
  });
};

const insertVariables = (frameDocument) => {
  const { width, height } = getWindowSize(frameDocument);
  const style = frameDocument.createElement('style');
  const viewportUnit = 1;

  style.innerHTML = `
    :root {
      --ws-pixels-in-1-vh: ${((viewportUnit / 100) * height).toFixed(3)}px;
      --ws-pixels-in-1-vw: ${((viewportUnit / 100) * width).toFixed(3)}px;
    }
  `;

  frameDocument.head.appendChild(style);
};

const process = (frameDocument) => {
  insertVariables(frameDocument);
  processLinks(frameDocument, 'link');
  processStyles(frameDocument, 'style');
  processInlineStyles(frameDocument, `[style*=vh], [${ORIGINAL_STYLE_CONTAINER}*=vh]`);
};

const replaceVhUnits = (frameDocument) => {
  if (!isOldIOSBrowser()) return;
  process(frameDocument);

  frameDocument.defaultView.addEventListener('resize', () => process(frameDocument));
  frameDocument.defaultView.addEventListener('orientationchange', () => process(frameDocument));
};

export default replaceVhUnits;
