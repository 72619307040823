import i18next from 'i18next';
import ICU from 'i18next-icu';
import en from 'i18next-icu/locale-data/en';
import de from 'i18next-icu/locale-data/de';
import es from 'i18next-icu/locale-data/es';
import fr from 'i18next-icu/locale-data/fr';
import nb from 'i18next-icu/locale-data/nb';
import nl from 'i18next-icu/locale-data/nl';
import pl from 'i18next-icu/locale-data/pl';
import pt from 'i18next-icu/locale-data/pt';
import ru from 'i18next-icu/locale-data/ru';
import sv from 'i18next-icu/locale-data/sv';
import cs from 'i18next-icu/locale-data/cs';
import da from 'i18next-icu/locale-data/da';
import hu from 'i18next-icu/locale-data/hu';
import id from 'i18next-icu/locale-data/id';
import it from 'i18next-icu/locale-data/it';
import sk from 'i18next-icu/locale-data/sk';
import fi from 'i18next-icu/locale-data/fi';

const initializeI18next = () =>
  i18next.use(ICU).init({
    nsSeparator: false,
    keySeparator: false,
    lowerCaseLng: true,
    i18nFormat: {
      localeData: [en, de, es, fr, nb, nl, pl, pt, ru, sv, cs, da, hu, id, it, sk, fi],
    },
  });

export default initializeI18next;
