import React, { forwardRef } from 'react';
import { designSystem } from '@yola/ws-ui';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import TemplateThumb from '../template-thumb';

const { Container } = designSystem;

const GalleryGrid = forwardRef(
  ({ templates, onTemplatePreviewClick, onTemplateEditClick }, ref) => (
    <Container>
      <div ref={ref}>
        <ReactCSSTransitionGroup
          className="gallery-grid-container"
          transitionName="gallery-grid"
          transitionAppear
          transitionAppearTimeout={500}
          transitionEnterTimeout={500}
          transitionLeaveTimeout={500}
        >
          <div className="gallery-grid">
            {templates.map(({ id, slug, previewUrl, name }) => (
              <TemplateThumb
                key={id}
                name={name}
                previewUrl={previewUrl}
                templateSlug={slug}
                onTemplatePreviewClick={onTemplatePreviewClick}
                onTemplateEditClick={onTemplateEditClick}
              />
            ))}
          </div>
        </ReactCSSTransitionGroup>
      </div>
    </Container>
  )
);

GalleryGrid.defaultProps = {
  templates: [],
  onTemplatePreviewClick: Function.prototype,
  onTemplateEditClick: Function.prototype,
};

GalleryGrid.propTypes = {
  templates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      slug: PropTypes.string,
      previewUrl: PropTypes.string,
    })
  ),
  onTemplatePreviewClick: PropTypes.func,
  onTemplateEditClick: PropTypes.func,
};

export default GalleryGrid;
