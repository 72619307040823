import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import animatedIcons from 'src/js/constants/site-setup/how-to-build-step/animated-icons';

const { Stack, Paragraph, Box, Icon, HorizontalStack, Heading, Mark } = designSystem;

const CardItem = ({ title, iconGlyph, subtitle, description, withDecoration, onClick }) => {
  const cardClasses = classNames('ws-site-setup-layout-how-to-build__card', {
    'ws-site-setup-layout-how-to-build__card--with-decoration': withDecoration,
  });
  const cardContainerClasses = classNames('ws-site-setup-layout-how-to-build__card-container', {
    'ws-site-setup-layout-how-to-build__card-container--with-decoration': withDecoration,
  });

  return (
    <div className={cardClasses} onClick={onClick}>
      <div className={cardContainerClasses}>
        <Box
          paddingLeft="spacing-m"
          paddingRight="spacing-m"
          paddingTop="spacing-xs"
          paddingBottom="spacing-xs"
          breakpoints={{
            md: {
              paddingLeft: 'spacing-l',
              paddingRight: 'spacing-l',
              paddingTop: 'spacing-m',
              paddingBottom: 'spacing-m',
            },
          }}
        >
          <HorizontalStack gap="spacing-xs">
            <Icon glyph={iconGlyph} type="accent" />
            <Stack>
              {subtitle && (
                <Box marginTop="spacing-4-xs">
                  <Paragraph size="small">
                    <Mark appearance="accent">
                      <b>{subtitle.toUpperCase()}</b>
                    </Mark>
                  </Paragraph>
                </Box>
              )}
              <Heading type="heading-4">{title}</Heading>
              <Paragraph>{description}</Paragraph>
            </Stack>
          </HorizontalStack>
        </Box>
      </div>
      {withDecoration &&
        animatedIcons.map((props) => (
          <img className="ws-site-setup-layout-how-to-build__card-icon" {...props} alt="" />
        ))}
    </div>
  );
};

CardItem.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  description: PropTypes.string.isRequired,
  iconGlyph: PropTypes.string.isRequired,
  withDecoration: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

CardItem.defaultProps = {
  subtitle: '',
  withDecoration: false,
};

export default CardItem;
