import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Logo from '../components/logo';
import getPartnerLogo from '../modules/partner/selectors/partner-logo';
import getUserAuthorizationStatus from '../modules/user/selectors/user-authorization-status';

const LogoContainer = (props) => {
  const { alt } = props;

  return <Logo {...props} alt={alt} />;
};

LogoContainer.propTypes = {
  alt: PropTypes.string,
};

LogoContainer.defaultProps = {
  alt: '',
};

const mapStateToProps = (state) => ({
  partnerLogoUrl: getPartnerLogo(state),
  isUserAuthorized: getUserAuthorizationStatus(state),
});

export default connect(mapStateToProps)(LogoContainer);
