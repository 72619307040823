import { SESSION_STORAGE_DATA_NAME } from 'src/js/constants/common';

// Added as a fallback for cases when user disabled sessionStorage
let storage = {};

const SiteSetupStorage = {
  setData(data) {
    if (!data) return;

    try {
      sessionStorage.setItem(SESSION_STORAGE_DATA_NAME, JSON.stringify(data));
    } catch (e) {
      storage = {
        ...data,
      };
      console.error(e);
    }
  },

  getData() {
    try {
      const sessionStorageItem = sessionStorage.getItem(SESSION_STORAGE_DATA_NAME);
      if (sessionStorageItem) return JSON.parse(sessionStorageItem);
    } catch (e) {
      console.error(e);
      return storage;
    }

    return {};
  },

  clear() {
    try {
      sessionStorage.removeItem(SESSION_STORAGE_DATA_NAME);
    } catch (e) {
      console.error(e);
      storage = {};
    }
  },
};

export default SiteSetupStorage;
