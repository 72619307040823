export const APP_NAME = 'yola-template-gallery';
export const PRODUCT_CATEGORY = 'latitude';

export const premiumFeatures = {
  SITES_COUNT: 'sites_count',
};

export const DEFAULT_CATEGORY_FOR_URI = 'all';

export const SITE_ID_TO_RECREATE = 'site_id_to_recreate';
export const SITE_ID_TO_SWITCH = 'site_id_to_switch';
export const TEMPLATE_BUILD_SLUG = 'template_build_slug';
export const BACK_SEARCH_PARAM = 'back';

export const USE_TEMPLATE_ROUTE = 'use-template';

export const DEFAULT_LOGO_URL = 'https://assets.yolacdn.net/wl-logos/yola-black-f8e63d97.svg';
export const SESSION_STORAGE_DATA_NAME = 'ws_site_setup';

export const YOLA_USER_COOKIE = 'yola_user';

export const AI_SITE_WIZARD_FLAG = 'ai_site_wizard';
export const AI_PAGE_WIZARD_FLAG = 'ai_page_wizard';
export const BUSINESS_TAXONOMY_FLAG = 'business_taxonomy';
export const AI_OPTIONAL_TEMPLATE_SELECTION_STEP_FLAG = 'ai_optional_template_selection_step';
export const BLANK_TEMPLATE_FLAG = 'blank_template';

export const ENTER_KEY = 'Enter';

export const SOURCE_ID = 'template-gallery';
