import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import Layout from 'src/js/components/layout';
import HeaderGalleryPageContainer from 'src/js/containers/header-gallery-page-container';
import SiteSetupLayout from 'src/js/components/site-setup/site-setup-layout';
import CardItem from './components/card-item';

const { Container, Stack, Paragraph } = designSystem;

const HowToBuildStep = ({
  captions,
  currentStep,
  cards,
  legacySitebuilderUrl,
  isLegacySitebuilderAvailable,
  isBlankTemplateAvailable,
  onCardClick,
  onBlankTemplateClick,
}) => (
  <Layout Header={HeaderGalleryPageContainer}>
    <SiteSetupLayout
      className="ws-site-setup-layout-how-to-build"
      captions={captions}
      currentStep={currentStep}
    >
      <Container>
        <Stack
          gap="spacing-s"
          breakpoints={{
            md: {
              gap: 'spacing-m',
            },
          }}
        >
          {cards.map(({ id, ...restProps }) => (
            <CardItem key={id} {...restProps} onClick={() => onCardClick(id)} />
          ))}
          <Stack
            gap="spacing-2-xs"
            breakpoints={{
              md: {
                gap: 'spacing-xs',
              },
            }}
          >
            {isBlankTemplateAvailable && (
              <Paragraph>
                {captions.blankTemplate.title}{' '}
                {/* eslint-disable-next-line yola/jsx-a11y/anchor-is-valid */}
                <a
                  href="#"
                  className="ws-site-setup-layout-how-to-build__link"
                  onClick={onBlankTemplateClick}
                >
                  <b>{captions.blankTemplate.link}</b>
                </a>
              </Paragraph>
            )}
            {isLegacySitebuilderAvailable && (
              <Paragraph>
                {captions.legacyTemplate.title}{' '}
                <a href={legacySitebuilderUrl} className="ws-site-setup-layout-how-to-build__link">
                  <b>{captions.legacyTemplate.link}</b>
                </a>
              </Paragraph>
            )}
          </Stack>
        </Stack>
      </Container>
    </SiteSetupLayout>
  </Layout>
);

HowToBuildStep.propTypes = {
  currentStep: PropTypes.number.isRequired,
  captions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    blankTemplate: PropTypes.shape({
      title: PropTypes.string,
      link: PropTypes.string,
    }),
    legacyTemplate: PropTypes.shape({
      title: PropTypes.string,
      link: PropTypes.string,
    }),
  }).isRequired,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string,
      description: PropTypes.string.isRequired,
      iconGlyph: PropTypes.string.isRequired,
      withDecoration: PropTypes.bool,
    })
  ).isRequired,
  isLegacySitebuilderAvailable: PropTypes.bool,
  legacySitebuilderUrl: PropTypes.string.isRequired,
  isBlankTemplateAvailable: PropTypes.bool,
  onCardClick: PropTypes.func.isRequired,
  onBlankTemplateClick: PropTypes.func.isRequired,
};

HowToBuildStep.defaultProps = {
  isLegacySitebuilderAvailable: false,
  isBlankTemplateAvailable: false,
};

export default HowToBuildStep;
