import { useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import routesMap from 'src/js/router/helpers/routes-map';
import redirectUnauthorizedUser from 'src/js/helpers/redirect-unauthorized-user';
import getGalleryCategory from 'src/js/helpers/get-gallery-category';
import { SiteSetupContext } from '../helpers/site-setup-context';
import getUserAuthorizationStatus from '../../user/selectors/user-authorization-status';
import getSiteIdToSwitch from '../../sites/selectors/site-id-to-switch-template';
import getSiteIdToRecreate from '../../sites/selectors/get-site-id-recreate';
import trackers from '../../analytics/segment/trackers';

const { trackUseTemplateButtonClicked } = trackers;

const useOnTemplateSelect = ({ isTrackEventsAsync = false } = {}) => {
  const isUserAuthenticated = useSelector(getUserAuthorizationStatus);
  const siteIdToSwitch = useSelector(getSiteIdToSwitch);
  const siteIdToRecreate = useSelector(getSiteIdToRecreate);
  const siteId = siteIdToRecreate || siteIdToSwitch || null;
  const context = useContext(SiteSetupContext);
  const history = useHistory();
  const { updateData, getData } = context;
  const { archetype = null, businessCategorySlug } = getData();

  const onTemplateSelect = useCallback(
    async (templateBuildSlug, triggerId) => {
      updateData({
        templateBuildSlug,
      });

      await trackUseTemplateButtonClicked(
        {
          siteId,
          selectedTemplateCategory: getGalleryCategory(archetype) || 'all',
          templateBuildSlug,
          businessCategory: businessCategorySlug,
          triggerId,
        },
        isTrackEventsAsync
      );

      if (isUserAuthenticated) {
        if (siteIdToSwitch) {
          history.replace(routesMap.createSite.url({ currentPath: history.location.pathname }));
        } else {
          history.push(routesMap.siteName.url());
        }
      } else {
        redirectUnauthorizedUser();
      }
    },
    [
      updateData,
      siteId,
      archetype,
      businessCategorySlug,
      isTrackEventsAsync,
      isUserAuthenticated,
      siteIdToSwitch,
      history,
    ]
  );

  return onTemplateSelect;
};

export default useOnTemplateSelect;
