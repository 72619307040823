const isValidUrl = (url) => {
  const domain = /^[a-z0-9-.]{1,256}\.[a-z]{2,6}/gi;

  try {
    const { host } = new URL(url);
    if (host.length > 253) return false;

    return domain.test(host);
  } catch (_) {
    return false;
  }
};

const isURL = (str) => {
  const trimmedStr = str.trim();

  if (trimmedStr.includes(' ') || trimmedStr.includes('..')) {
    return false;
  }

  if (
    trimmedStr.toLowerCase().startsWith('http://') ||
    trimmedStr.toLowerCase().startsWith('https://')
  ) {
    return isValidUrl(trimmedStr);
  }

  if (trimmedStr.startsWith('//')) {
    return isValidUrl(`https:${trimmedStr}`);
  }

  return isValidUrl(`https://${trimmedStr}`);
};

export default isURL;
