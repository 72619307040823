import store from 'src/js/store';
import getIsB2C from 'src/js/modules/config/selectors/is-b2c';
import getUserPartnerId from 'src/js/modules/user/selectors/user-partner-id';
import constants from '../constants';
import getPartnerIdFromUrl from './get-partner-id-from-url';
import getPartnerIdFromStorage from './get-partner-id-from-storage';

const getPartnerId = () => {
  const state = store.getState();

  if (getIsB2C(state)) {
    return constants.YOLA_PARTNER_ID;
  }

  const partnerId = getUserPartnerId(state);

  return partnerId || getPartnerIdFromUrl() || getPartnerIdFromStorage();
};

export default getPartnerId;
