import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import { useHistory } from 'react-router-dom';
import { APP_NAME } from 'src/js/constants/common';
import analytics from 'src/js/modules/analytics';
import config from 'src/js/modules/config';
import routesMap from 'src/js/router/helpers/routes-map';
import provideViewportHeight from '../utils/provide-viewport-height';
import PlatformSelector from '../components/platform-selector';

const {
  segment: { eventTypes },
} = analytics;

const getCaptions = () => ({
  latitude: {
    title: t('Introducing Sitebuilder+'),
    description: t(
      'The easiest way to get online fast. Create a beautiful, professional website on a desktop, mobile, or tablet in minutes using the entire library of pre-designed blocks'
    ),
    link: t('Get started for free'),
  },
  legacy: {
    title: t('Sitebuilder Classic'),
    description: t('Know and love Sitebuilder Classic?'),
    link: t('Continue with classic'),
  },
  alt: {
    logo: t('Yola logo'),
    media: t('Latitude screenshot'),
  },
});

function PlatformSelectorContainer({ latitudePath }) {
  const captions = getCaptions();
  const [isVisible, setVisibility] = useState(false);
  const yolacomUrl = useSelector(config.selectors.getYolacomUrl);
  const history = useHistory();
  const legacyPath = routesMap.legacyTemplates
    .url()
    .split('/')
    .slice(1)
    .join('/');

  useEffect(() => {
    analytics.segment.track(eventTypes.PLATFORM_SELECTOR_DISPLAYED, {
      appName: APP_NAME,
    });
    setVisibility(true);
  }, []);

  provideViewportHeight();

  const latitudeHandler = (event) => {
    event.preventDefault();
    analytics.segment
      .trackAsync(eventTypes.PLATFORM_SELECTED, {
        appName: APP_NAME,
        platform: 'latitude',
      })
      .then(() => {
        history.replace(latitudePath);
      });
  };

  const legacyHandler = (event) => {
    const {
      target: { href },
    } = event;
    event.preventDefault();
    analytics.segment
      .trackAsync(eventTypes.PLATFORM_SELECTED, {
        appName: APP_NAME,
        platform: 'legacy',
      })
      .then(() => {
        document.location.assign(href);
      });
  };

  const latitudeLink = {
    path: latitudePath,
    handler: latitudeHandler,
  };

  const legacyLink = {
    path: `${yolacomUrl}${legacyPath}`,
    handler: legacyHandler,
  };

  return (
    <PlatformSelector
      isVisible={isVisible}
      captions={captions}
      latitudeLink={latitudeLink}
      legacyLink={legacyLink}
    />
  );
}

PlatformSelectorContainer.propTypes = {
  latitudePath: PropTypes.string.isRequired,
};

export default PlatformSelectorContainer;
