import categories from '../../categories';

const { PERSONAL, BUSINESS, ONLINE_STORE } = categories.categoryIds;

export default {
  PERSONAL,
  BUSINESS,
  ONLINE_STORE,
  NON_PROFIT: 'non-profit',
  EVENTS: 'events',
  COMMUNITY: 'community',
  OTHER: 'other',
};
