import subscriptions from 'src/js/modules/subscriptions';
import { ONLINE_STORE } from '../constants/category-ids';

const filterAvailableCategories = (categories, availableSubscriptionTypes, isUserAuthenticated) => {
  if (
    isUserAuthenticated &&
    !subscriptions.verifiers.hasEcommerceSubscription(availableSubscriptionTypes)
  ) {
    return categories.filter(({ id }) => id !== ONLINE_STORE);
  }

  return categories;
};

export default filterAvailableCategories;
