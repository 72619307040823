import store from 'src/js/store';
import selectors from '../selectors';

const getSiteIdToSwitchTemplate = () => {
  const state = store.getState();

  return selectors.getSiteIdToSwitchTemplate(state);
};

export default getSiteIdToSwitchTemplate;
