import analytics from 'src/js/modules/analytics';
import config from 'src/js/modules/config';
import user from 'src/js/modules/user';
import dialogTypes from 'src/js/modules/dialogs/constants/dialog-types';
import { APP_NAME } from 'src/js/constants/common';
import configureSnippet from './configure-snippet';
import loadSnippet from './load-snippet';
import { sourceId } from './constants';
import i18n from '../../i18n';
import store from '../../../store';

const { track, eventTypes } = analytics.segment;

const getSnippetInstance = async (options = { onHide: () => {} }) => {
  const state = store.getState();

  const locale = i18n.selectors.getLocale(state);
  const zendeskWidgetUrl = config.selectors.getZendeskWidgetUrl(state);
  const userData = user.selectors.getUserData(state);

  configureSnippet(document.body);

  const snippet = await loadSnippet(zendeskWidgetUrl, document.body);

  snippet('webWidget', 'setLocale', locale);

  snippet('webWidget:on', 'close', async () => {
    const snippetCurrent = await loadSnippet(zendeskWidgetUrl, document.body);
    snippetCurrent('webWidget', 'updateSettings', {
      webWidget: {
        contactOptions: {
          enabled: true,
        },
        helpCenter: {
          suppress: false,
        },
        contactForm: {
          suppress: false,
        },
        chat: {
          suppress: false,
        },
      },
    });
    snippetCurrent('webWidget', 'close');
    options.onHide();
  });

  snippet('webWidget', 'prefill', {
    email: {
      value: userData.email,
      readOnly: true,
    },
    name: {
      value: userData.name,
      readOnly: true,
    },
  });

  snippet('webWidget:on', 'open', () => {
    track(eventTypes.HELP_DISPLAYED, {
      appName: APP_NAME,
      dialogId: dialogTypes.HELP_DISPLAYED,
      sourceId,
    });
  });

  return {
    hide() {
      snippet('webWidget', 'close');
      options.onHide();
    },
    showContactForm() {
      snippet('webWidget', 'updateSettings', {
        webWidget: {
          contactOptions: {
            enabled: true,
          },
          helpCenter: {
            suppress: true,
          },
          contactForm: {
            suppress: false,
          },
          chat: {
            suppress: false,
          },
        },
      });
      snippet('webWidget', 'open');
    },
    showSearchForm() {
      snippet('webWidget', 'updateSettings', {
        webWidget: {
          contactOptions: {
            enabled: false,
          },
          chat: {
            suppress: true,
          },
          contactForm: {
            suppress: true,
          },
          helpCenter: {
            suppress: false,
          },
        },
      });
      snippet('webWidget', 'open');
    },
  };
};

export default getSnippetInstance;
