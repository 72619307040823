import yousersjs from '@yola/yousersjs';
import store from 'src/js/store';
import i18n from 'src/js/modules/i18n';
import analytics from 'src/js/modules/analytics';
import config from 'src/js/modules/config';
import user from 'src/js/modules/user';
import sites from 'src/js/modules/sites';
import auth from 'src/js/modules/auth';
import partner from 'src/js/modules/partner';
import subscriptions from 'src/js/modules/subscriptions';
import templates from 'src/js/modules/templates';
import getTemplateBuildSlug from 'src/js/utils/get-template-build-slug';
import getSiteIdToSwitchTemplate from 'src/js/utils/get-site-id-to-switch-template';
import getSiteIdToRecreateTemplate from 'src/js/utils/get-site-id-to-recreate-template';
import redirectTo403 from 'src/js/utils/redirect-to-403';
import isGalleryAllowed from 'src/js/helpers/is-gallery-allowed';
import { redirectToUrl } from 'src/js/utils/redirect-to-url';

const initApp = async () => {
  await store.dispatch(config.thunks.fetchConfig());

  const apiURL = config.selectors.getApiUrl(store.getState());
  yousersjs.setAPIRoot(apiURL);

  await store.dispatch(auth.thunks.authenticate());
  const isAuthorized = user.selectors.getUserAuthorizationStatus(store.getState());

  const partnerId = partner.accessors.getPartnerId();

  if (!partnerId) {
    redirectTo403();
  }

  if (isAuthorized) {
    await store.dispatch(user.thunks.fetchUserPreferences());
    store.dispatch(subscriptions.actions.fetchActiveSubscriptions());
  }

  await i18n.initializers.initializeI18next();
  analytics.init(store);

  const locale = i18n.helpers.defineLocale(store.getState());
  await store.dispatch(partner.thunks.fetchPartnerData(partnerId, locale));
  const isAuthComponentAvailable = partner.verifiers.isAuthComponentAvailable();
  const isPartnerSignupUrlSpecified = Boolean(
    partner.selectors.getPartnerSignupUrl(store.getState())
  );

  if (!isAuthorized && !isAuthComponentAvailable && !isPartnerSignupUrlSpecified) {
    redirectTo403();
  }

  store.dispatch(i18n.actions.loadTranslations(locale));

  const templateBuildSlug = getTemplateBuildSlug();
  const siteIdToSwitchTemplate = getSiteIdToSwitchTemplate();
  const siteIdToRecreate = getSiteIdToRecreateTemplate();

  if (siteIdToRecreate) {
    store.dispatch(sites.actions.setSiteIdRecreate(siteIdToRecreate));
    store.dispatch(sites.actions.setTemplateBuildSlug(templateBuildSlug));
  }

  if (siteIdToSwitchTemplate) {
    store.dispatch(sites.actions.setSiteIdToSwitchTemplate(siteIdToSwitchTemplate));
    store.dispatch(sites.actions.setTemplateBuildSlug(templateBuildSlug));
  }

  if (isAuthorized) {
    await store.dispatch(sites.thunks.fetchSitesData());
    await store.dispatch(subscriptions.thunks.fetchCurrentPackage());

    const state = store.getState();
    if (!isGalleryAllowed(state)) {
      const yolaEditorUrl = config.selectors.getYolaEditorUrl(state);
      redirectToUrl(yolaEditorUrl);

      return;
    }
  }

  await store.dispatch(
    templates.thunks.fetchTemplatesData({
      pageSize: 500,
      locale,
    })
  );
};

export default initApp;
