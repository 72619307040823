import { t } from 'i18next';
import {
  ALL,
  PERSONAL,
  BUSINESS,
  ONLINE_STORE,
  COMMUNITY_OR_ORGANIZATION,
} from '../constants/category-ids';

const getCategories = () => [
  {
    title: t('All templates'),
    id: ALL,
  },
  {
    title: t('Personal'),
    id: PERSONAL,
  },
  {
    title: t('Business'),
    id: BUSINESS,
  },
  {
    title: t('Online Store'),
    id: ONLINE_STORE,
  },
  {
    title: t('Community / Organization'),
    id: COMMUNITY_OR_ORGANIZATION,
  },
];

export default getCategories;
