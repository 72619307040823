import bowser from 'yola-bowser';
import getNetworkErrorDetails from 'src/js/modules/error/helpers/get-network-error-details';
import { APP_NAME } from 'src/js/constants/common';
import supportedBrowsers from '../constants/supported-browsers';
import actionTypes from '../constants/action-types';
import errorTypes from '../constants/error-types';
import sentry from '../../analytics/sentry';
import segment from '../../analytics/segment';
import detectDeviceOrientation from '../helpers/detect-device-orientation';
import selectors from '../../sites/selectors';

const errorHandler = (store) => (next) => (action) => {
  switch (action.type) {
    case actionTypes.TRACK_ERROR:
    case actionTypes.SET_CRITICAL_ERROR: {
      const { error, errorInfo, logSentry } = action.payload;
      const isNetworkError = error.message.includes(errorTypes.NETWORK_ERROR);
      const state = store.getState();
      if (sentry.instance.isInit() && logSentry) {
        const sentryInstance = sentry.instance.getInstance();

        if (errorInfo) {
          sentryInstance.withScope((scope) => {
            scope.setExtras(errorInfo);
            sentryInstance.captureException(error);
          });
        } else {
          sentryInstance.captureException(error);
        }
      }

      /*
        In order to be sure that "beforeunload" event fired before
        tracking "ERROR_CAUGHT" event we need to wrap it into macrotask
      */
      setTimeout(() => {
        // eslint-disable-next-line no-underscore-dangle
        if ((isNetworkError && !navigator.onLine) || window._ws_resources_unloaded) {
          next(action);
          return;
        }

        const siteIdRecreate = selectors.getSiteIdRecreate(state);
        const siteIdSwitchTemplate = selectors.getSiteIdToSwitchTemplate(state);

        const segmentProps = {
          appName: APP_NAME,
          errorName: error.name,
          errorMessage: error.message,
          deviceOrientation: detectDeviceOrientation(),
          siteId: siteIdRecreate || siteIdSwitchTemplate,
          templateBuildSlug: selectors.getTemplateBuildSlug(state),
          isSupportedBrowser: bowser.isSatisfied(supportedBrowsers),
        };

        if (isNetworkError) {
          segmentProps.networkErrorDetails = getNetworkErrorDetails(error);
        }
        segment.track(segment.eventTypes.ERROR_CAUGHT, segmentProps);

        next(action);
      }, 0);

      break;
    }
    default:
      next(action);
  }
};

export default errorHandler;
