import bowser from 'yola-bowser';
import { DESKTOP, TABLET, MOBILE } from '../constants/index';

const getPreviewModeForDevice = () => {
  if (bowser.mobile) return MOBILE;
  if (bowser.tablet) return TABLET;

  return DESKTOP;
};

export default getPreviewModeForDevice;
