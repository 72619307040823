import React from 'react';
import PropTypes from 'prop-types';
import noop from '../../../utils/noop';

const Layout = ({ headerTitle, children, footerTitle, onCloseClick, onSubmitClick }) => (
  <div className="modal-layout">
    <div className="modal-close" onClick={onCloseClick} />
    <div className="modal-header">{headerTitle}</div>
    <div className="modal-content">{children}</div>
    {footerTitle && (
      <div className="modal-footer" onClick={onSubmitClick}>
        {footerTitle}
      </div>
    )}
  </div>
);

Layout.defaultProps = {
  headerTitle: '',
  footerTitle: '',
  onCloseClick: noop,
  onSubmitClick: noop,
};

Layout.propTypes = {
  headerTitle: PropTypes.string,
  children: PropTypes.element.isRequired,
  footerTitle: PropTypes.string,
  onCloseClick: PropTypes.func,
  onSubmitClick: PropTypes.func,
};

export default Layout;
