import config from 'src/js/modules/config';
import user from 'src/js/modules/user';
import getCookie from 'src/js/utils/get-cookie';
import setCookie from 'src/js/utils/set-cookie';
import getLocaleFromUrl from './get-locale-from-url';
import { DEFAULT_LOCALE } from '../constants';

const defineLocale = (state) => {
  const localeCookieKey = config.selectors.getLocaleCookie(state);
  const supportedLocales = config.selectors.getBaseLanguageCodes(state);
  const currentDomain = config.selectors.getCurrentDomain(state);

  const localeFromUrl = getLocaleFromUrl();
  if (localeFromUrl && supportedLocales.includes(localeFromUrl)) {
    setCookie(localeCookieKey, localeFromUrl, currentDomain);
    return localeFromUrl;
  }

  const localeFromCookie = getCookie(localeCookieKey);
  if (localeFromCookie) return localeFromCookie;

  const preferences = user.selectors.getUserPreferences(state);
  if (preferences && preferences.locale && supportedLocales.includes(preferences.locale)) {
    return preferences.locale;
  }

  return DEFAULT_LOCALE;
};

export default defineLocale;
