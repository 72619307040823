import React from 'react';
import { t } from 'i18next';
import { designSystem } from '@yola/ws-ui';

const { Flag } = designSystem;

const getCountries = () => ({
  af: { name: t('Afghanistan'), iconComponent: <Flag code="af" /> },
  al: { name: t('Albania'), iconComponent: <Flag code="al" /> },
  dz: { name: t('Algeria'), iconComponent: <Flag code="dz" /> },
  ar: { name: t('Argentina'), iconComponent: <Flag code="ar" /> },
  am: { name: t('Armenia'), iconComponent: <Flag code="am" /> },
  au: { name: t('Australia'), iconComponent: <Flag code="au" /> },
  at: { name: t('Austria'), iconComponent: <Flag code="at" /> },
  az: { name: t('Azerbaijan'), iconComponent: <Flag code="az" /> },
  bh: { name: t('Bahrain'), iconComponent: <Flag code="bh" /> },
  by: { name: t('Belarus'), iconComponent: <Flag code="by" /> },
  be: { name: t('Belgium'), iconComponent: <Flag code="be" /> },
  bz: { name: t('Belize'), iconComponent: <Flag code="bz" /> },
  bo: { name: t('Bolivia'), iconComponent: <Flag code="bo" /> },
  ba: { name: t('Bosnia and Herzegovina'), iconComponent: <Flag code="ba" /> },
  bw: { name: t('Botswana'), iconComponent: <Flag code="bw" /> },
  br: { name: t('Brazil'), iconComponent: <Flag code="br" /> },
  bg: { name: t('Bulgaria'), iconComponent: <Flag code="bg" /> },
  ca: { name: t('Canada'), iconComponent: <Flag code="ca" /> },
  cl: { name: t('Chile'), iconComponent: <Flag code="cl" /> },
  cn: { name: t('China'), iconComponent: <Flag code="cn" /> },
  co: { name: t('Colombia'), iconComponent: <Flag code="co" /> },
  cr: { name: t('Costa Rica'), iconComponent: <Flag code="cr" /> },
  hr: { name: t('Croatia'), iconComponent: <Flag code="hr" /> },
  cz: { name: t('Czech Republic'), iconComponent: <Flag code="cz" /> },
  dk: { name: t('Denmark'), iconComponent: <Flag code="dk" /> },
  do: { name: t('Dominican Republic'), iconComponent: <Flag code="do" /> },
  ec: { name: t('Ecuador'), iconComponent: <Flag code="ec" /> },
  eg: { name: t('Egypt'), iconComponent: <Flag code="eg" /> },
  ee: { name: t('Estonia'), iconComponent: <Flag code="ee" /> },
  fi: { name: t('Finland'), iconComponent: <Flag code="fi" /> },
  fr: { name: t('France'), iconComponent: <Flag code="fr" /> },
  ge: { name: t('Georgia'), iconComponent: <Flag code="ge" /> },
  de: { name: t('Germany'), iconComponent: <Flag code="de" /> },
  gr: { name: t('Greece'), iconComponent: <Flag code="gr" /> },
  gt: { name: t('Guatemala'), iconComponent: <Flag code="gt" /> },
  hn: { name: t('Honduras'), iconComponent: <Flag code="hn" /> },
  hk: { name: t('Hong Kong'), iconComponent: <Flag code="hk" /> },
  hu: { name: t('Hungary'), iconComponent: <Flag code="hu" /> },
  is: { name: t('Iceland'), iconComponent: <Flag code="is" /> },
  in: { name: t('India'), iconComponent: <Flag code="in" /> },
  id: { name: t('Indonesia'), iconComponent: <Flag code="id" /> },
  ir: { name: t('Iran'), iconComponent: <Flag code="ir" /> },
  iq: { name: t('Iraq'), iconComponent: <Flag code="iq" /> },
  ie: { name: t('Ireland'), iconComponent: <Flag code="ie" /> },
  il: { name: t('Israel'), iconComponent: <Flag code="il" /> },
  it: { name: t('Italy'), iconComponent: <Flag code="it" /> },
  jm: { name: t('Jamaica'), iconComponent: <Flag code="jm" /> },
  jp: { name: t('Japan'), iconComponent: <Flag code="jp" /> },
  jo: { name: t('Jordan'), iconComponent: <Flag code="jo" /> },
  kz: { name: t('Kazakhstan'), iconComponent: <Flag code="kz" /> },
  kr: { name: t('Korea'), iconComponent: <Flag code="kr" /> },
  kw: { name: t('Kuwait'), iconComponent: <Flag code="kw" /> },
  lv: { name: t('Latvia'), iconComponent: <Flag code="lv" /> },
  lb: { name: t('Lebanon'), iconComponent: <Flag code="lb" /> },
  ly: { name: t('Libyan Arab Jamahiriya'), iconComponent: <Flag code="ly" /> },
  li: { name: t('Liechtenstein'), iconComponent: <Flag code="li" /> },
  lt: { name: t('Lithuania'), iconComponent: <Flag code="lt" /> },
  lu: { name: t('Luxembourg'), iconComponent: <Flag code="lu" /> },
  mo: { name: t('Macau'), iconComponent: <Flag code="mo" /> },
  mk: { name: t('Macedonia'), iconComponent: <Flag code="mk" /> },
  my: { name: t('Malaysia'), iconComponent: <Flag code="my" /> },
  mt: { name: t('Malta'), iconComponent: <Flag code="mt" /> },
  mx: { name: t('Mexico'), iconComponent: <Flag code="mx" /> },
  md: { name: t('Moldova'), iconComponent: <Flag code="md" /> },
  ma: { name: t('Morocco'), iconComponent: <Flag code="ma" /> },
  nl: { name: t('Netherlands'), iconComponent: <Flag code="nl" /> },
  nz: { name: t('New Zealand'), iconComponent: <Flag code="nz" /> },
  ni: { name: t('Nicaragua'), iconComponent: <Flag code="ni" /> },
  no: { name: t('Norway'), iconComponent: <Flag code="no" /> },
  om: { name: t('Oman'), iconComponent: <Flag code="om" /> },
  pk: { name: t('Pakistan'), iconComponent: <Flag code="pk" /> },
  pa: { name: t('Panama'), iconComponent: <Flag code="pa" /> },
  py: { name: t('Paraguay'), iconComponent: <Flag code="py" /> },
  pe: { name: t('Peru'), iconComponent: <Flag code="pe" /> },
  pl: { name: t('Poland'), iconComponent: <Flag code="pl" /> },
  pt: { name: t('Portugal'), iconComponent: <Flag code="pt" /> },
  pr: { name: t('Puerto Rico'), iconComponent: <Flag code="pr" /> },
  qa: { name: t('Qatar'), iconComponent: <Flag code="qa" /> },
  ro: { name: t('Romania'), iconComponent: <Flag code="ro" /> },
  ru: { name: t('Russian Federation'), iconComponent: <Flag code="ru" /> },
  sa: { name: t('Saudi Arabia'), iconComponent: <Flag code="sa" /> },
  rs: { name: t('Serbia'), iconComponent: <Flag code="rs" /> },
  sg: { name: t('Singapore'), iconComponent: <Flag code="sg" /> },
  sk: { name: t('Slovakia'), iconComponent: <Flag code="sk" /> },
  si: { name: t('Slovenia'), iconComponent: <Flag code="si" /> },
  za: { name: t('South Africa'), iconComponent: <Flag code="za" /> },
  es: { name: t('Spain'), iconComponent: <Flag code="es" /> },
  se: { name: t('Sweden'), iconComponent: <Flag code="se" /> },
  ch: { name: t('Switzerland'), iconComponent: <Flag code="ch" /> },
  sy: { name: t('Syrian Arab Republic'), iconComponent: <Flag code="sy" /> },
  tw: { name: t('Taiwan'), iconComponent: <Flag code="tw" /> },
  tj: { name: t('Tajikistan'), iconComponent: <Flag code="tj" /> },
  th: { name: t('Thailand'), iconComponent: <Flag code="th" /> },
  tt: { name: t('Trinidad and Tobago'), iconComponent: <Flag code="tt" /> },
  tn: { name: t('Tunisia'), iconComponent: <Flag code="tn" /> },
  tr: { name: t('Turkey'), iconComponent: <Flag code="tr" /> },
  tm: { name: t('Turkmenistan'), iconComponent: <Flag code="tm" /> },
  ua: { name: t('Ukraine'), iconComponent: <Flag code="ua" /> },
  ae: { name: t('United Arab Emirates'), iconComponent: <Flag code="ae" /> },
  gb: { name: t('United Kingdom'), iconComponent: <Flag code="gb" /> },
  us: { name: t('United States'), iconComponent: <Flag code="us" /> },
  uy: { name: t('Uruguay'), iconComponent: <Flag code="uy" /> },
  uz: { name: t('Uzbekistan'), iconComponent: <Flag code="uz" /> },
  ve: { name: t('Venezuela'), iconComponent: <Flag code="ve" /> },
  vn: { name: t('Viet Nam'), iconComponent: <Flag code="vn" /> },
  ye: { name: t('Yemen'), iconComponent: <Flag code="ye" /> },
  zw: { name: t('Zimbabwe'), iconComponent: <Flag code="zw" /> },
  sv: { name: t('El Salvador'), iconComponent: <Flag code="sv" /> },
  // -------------- Custom codes
  arl: { name: t('Arab League'), iconComponent: <Flag code="arl" /> },
  eu: { name: t('Basque'), iconComponent: <Flag code="eu" /> },
  est: { name: t('Estelada'), iconComponent: <Flag code="est" /> },
  gd: { name: t('Scotland'), iconComponent: <Flag code="gd" /> },
  wls: { name: t('Wales'), iconComponent: <Flag code="wls" /> },
  moh: { name: t('Mohawk'), iconComponent: <Flag code="moh" /> },
  ku: { name: t('Kurdistan'), iconComponent: <Flag code="ku" /> },
});

export default getCountries;
