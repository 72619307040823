import React, { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line yola/import/no-webpack-loader-syntax
import styles from '!!raw-loader!../css/styles.css';
import waitWsBlocksLoading from '../helpers/wait-ws-blocks-loading';
import wrapWithSVG from '../helpers/wrap-with-svg';
import proxyClickToParentContext from '../helpers/proxy-click-to-parent-context';
import isLinkWithHref from '../helpers/is-link-with-href';
import replaceVhUnits from '../helpers/replace-vh-units';
import fixIOSWindowInnerHeight from '../helpers/fix-window-inner-height';
import isOldIOSBrowser from '../helpers/is-old-ios-browser';
import preventClick from '../helpers/prevent-click';
import injectStyles from '../helpers/inject-styles';

import { PREVIEW_STYLES_ID } from '../constants';

function LivePreview({
  name,
  content,
  size,
  isolate,
  onLoad,
  preventLinkFollowing,
  scrollTopAfterSizeUpdate,
}) {
  const iframe = useRef(null);

  const { width, height } = size;

  const handleClick = useCallback(
    (e) => {
      proxyClickToParentContext(e);
      if (preventLinkFollowing) {
        const { target } = e;

        if (isLinkWithHref(target) || isLinkWithHref(target.closest('a'))) {
          e.preventDefault();
          e.stopPropagation();
        }
      }
    },
    [preventLinkFollowing]
  );

  useEffect(() => {
    const { contentWindow } = iframe.current;
    contentWindow.scrollTo(0, 0);
  }, [scrollTopAfterSizeUpdate, size]);

  useEffect(() => {
    const { contentDocument: document } = iframe.current;

    document.open();
    document.onreadystatechange = () => {
      injectStyles(document, styles, PREVIEW_STYLES_ID);

      if (document.readyState === 'interactive') {
        document.body.addEventListener('click', handleClick);
        if (content) waitWsBlocksLoading(document, onLoad, onLoad);
        preventClick(document, ['ws-online-store']);
        if (isOldIOSBrowser()) {
          fixIOSWindowInnerHeight(document.defaultView);
          replaceVhUnits(document);
          preventClick(document, ['ws-media-container', 'ws-contact-form-container']);
        }
      }
    };

    const documentContent = isolate ? wrapWithSVG(content, size) : content;
    document.write(documentContent);
    document.close();

    return () => document.body && document.body.removeEventListener('click', handleClick);
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, [name, content, isolate]);

  useEffect(() => {
    const { body } = iframe.current.contentDocument;
    if (!body) return;

    const svg = body.querySelector('svg#render');
    const foreignObject = iframe.current.contentDocument.body.querySelector(
      'svg#render > foreignObject'
    );

    if (svg && foreignObject) {
      svg.setAttribute('viewBox', `0 0 ${width} ${height}`);
      foreignObject.setAttribute('width', width);
      foreignObject.setAttribute('height', height);
    }
  }, [width, height]);

  return (
    <iframe
      width={width}
      height={height}
      ref={iframe}
      title={name}
      sandbox="allow-scripts allow-forms allow-same-origin allow-popups allow-pointer-lock allow-presentation"
    />
  );
}

LivePreview.propTypes = {
  name: PropTypes.string.isRequired,
  content: PropTypes.string,
  isolate: PropTypes.bool,
  size: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
  onLoad: PropTypes.func,
  preventLinkFollowing: PropTypes.bool,
  scrollTopAfterSizeUpdate: PropTypes.bool,
};
LivePreview.defaultProps = {
  isolate: true,
  onLoad: Function.prototype,
  content: null,
  preventLinkFollowing: false,
  scrollTopAfterSizeUpdate: false,
};

export default LivePreview;
