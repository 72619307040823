import get from 'lodash.get';
import { viewportCheckpoints } from '../constants';

const calculateViewportSize = (elementContainer, realMode, virtualMode) => {
  if (realMode === virtualMode) {
    return {
      width: elementContainer.offsetWidth,
      height: elementContainer.offsetHeight,
    };
  }

  return get(viewportCheckpoints, `${virtualMode}`, {
    width: 1280,
    height: 768,
  });
};

export default calculateViewportSize;
