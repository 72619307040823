import actionTypes from '../constants/action-types';

const userData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SET_USER_DATA:
      return action.payload.userData;
    default:
      return state;
  }
};

export default userData;
