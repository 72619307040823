const setMobileAnimation = (
  isFirstSlideActive = true,
  setFirstSlideStyles = Function.prototype,
  setSecondSlideStyles = Function.prototype,
  onAnimationEnd = Function.prototype
) => {
  if (isFirstSlideActive) {
    setFirstSlideStyles({
      from: {
        transform: 'translateX(0%)',
        opacity: 0,
        zIndex: -1,
      },
      to: {
        transform: 'translateX(0%)',
        opacity: 1,
        zIndex: 1,
      },
      immediate: false,
    });
    setSecondSlideStyles({
      from: {
        transform: 'translateX(0%)',
        opacity: 1,
        zIndex: 1,
      },
      to: {
        transform: 'translateX(0%)',
        opacity: 0,
        zIndex: -1,
      },
      immediate: false,
      onRest: onAnimationEnd,
    });
  } else {
    setFirstSlideStyles({
      from: {
        transform: 'translateX(0%)',
        opacity: 1,
        zIndex: 1,
      },
      to: {
        transform: 'translateX(0%)',
        opacity: 0,
        zIndex: -1,
      },
      immediate: false,
    });
    setSecondSlideStyles({
      from: {
        transform: 'translateX(0%)',
        opacity: 0,
        zIndex: -1,
      },
      to: {
        transform: 'translateX(0%)',
        opacity: 1,
        zIndex: 1,
      },
      immediate: false,
      onRest: onAnimationEnd,
    });
  }
};

export default setMobileAnimation;
