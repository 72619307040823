import getAvailablePlatformComponents from './available-platform-components';
import getPartnerData from './partner-data';
import getPartnerBranding from './partner-branding';
import getPartnerName from './partner-name';
import getPartnerLogoMobileWidth from './partner-logo-mobile-width';
import getPartnerLogoWidth from './partner-logo-width';
import getPartnerLogo from './partner-logo';
import getMultisiteMode from './multisite-mode';
import getAvailableSubscriptionTypes from './available-subscription-types';
import getPartnerSignupUrl from './partner-signup-url';
import getPartnerLoginUrl from './partner-login-url';

export default {
  getAvailablePlatformComponents,
  getPartnerData,
  getPartnerBranding,
  getPartnerName,
  getPartnerLogoMobileWidth,
  getPartnerLogoWidth,
  getPartnerLogo,
  getMultisiteMode,
  getAvailableSubscriptionTypes,
  getPartnerSignupUrl,
  getPartnerLoginUrl,
};
