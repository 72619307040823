import React, { useCallback, useContext, useMemo, useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import { designSystem, reactHookForm } from '@yola/ws-ui';
import { useHistory } from 'react-router-dom';
import siteSetup from 'src/js/modules/site-setup';
import trackers from 'src/js/modules/analytics/segment/trackers';
import { SiteSetupContext } from 'src/js/modules/site-setup/helpers/site-setup-context';
import Layout from 'src/js/components/layout';
import getSiteIdToRecreate from 'src/js/modules/sites/selectors/get-site-id-recreate';
import getSiteIdToSwitch from 'src/js/modules/sites/selectors/site-id-to-switch-template';
import HeaderGalleryPageContainer from 'src/js/containers/header-gallery-page-container';
import SiteSetupLayout from 'src/js/components/site-setup/site-setup-layout';
import serviceClient from 'src/js/modules/service-client/service-client';
import routesMap from '../../router/helpers/routes-map';
import categories from '../../modules/categories';
import getGalleryCategory from '../../helpers/get-gallery-category';
import getLocale from '../../modules/i18n/selectors/locale';
import getSuggestions from '../../helpers/get-suggestions';
import useFeatureFlags from '../../modules/feature-flags/hooks/use-feature-flags';
import {
  BUSINESS_TAXONOMY_FLAG,
  AI_OPTIONAL_TEMPLATE_SELECTION_STEP_FLAG,
  ENTER_KEY,
  SOURCE_ID,
} from '../../constants/common';

const { constants } = siteSetup;
const { siteSetupNames } = constants;
const {
  trackSiteSetupSkipButtonClicked,
  trackBusinessCategoryInputFocused,
  trackBusinessCategorySelected,
  trackSitesetupBusinessCategoryProvided,
} = trackers;
const { AutocompleteField, ActionButton, Paragraph, Box, Icon } = designSystem;
const { useForm } = reactHookForm;

const MAX_MENU_HEIGHT = 352;

const getCaptions = () => ({
  title: t('What type of website do you want to create?'),
  placeholder: t('Search for your business or website type'),
  buttons: {
    next: t('Continue'),
  },
  error: {
    required: t('This field is required'),
  },
  noResultsText: t('No result'),
});

const usePrevious = (value) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

function CategoryStepContainer() {
  const history = useHistory();
  const autocompleteRef = useRef(null);
  const captions = useMemo(() => getCaptions(), []);
  const suggestions = useMemo(() => getSuggestions(), []);
  const context = useContext(SiteSetupContext);
  const stepId = siteSetupNames.CATEGORY;
  const { updateData, getData } = context;
  const contextData = getData();
  const [businessCategoryValue, setBusinessCategoryValue] = useState(
    contextData.businessCategory || null
  );
  const prevBusinessCategoryValue = usePrevious(businessCategoryValue);
  const [isFocused, setIsFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(
    contextData.businessCategory
      ? {
          label: contextData.businessCategory,
          value: contextData.businessCategorySlug,
          archetype: contextData.archetype,
        }
      : null
  );
  const [isNextDisabled, setIsNextDisabled] = useState(!selectedOption);
  const locale = useSelector(getLocale);
  const siteIdToRecreate = useSelector(getSiteIdToRecreate);
  const siteIdToSwitch = useSelector(getSiteIdToSwitch);
  const siteId = siteIdToRecreate || siteIdToSwitch || null;

  const { number: currentStep } = useMemo(siteSetup.helpers.getCurrentStep, []);
  const { control, handleSubmit } = useForm({
    defaultValues: {
      businessCategory: contextData.businessCategory || '',
    },
    mode: 'onTouched',
  });

  const [featureFlags] = useFeatureFlags([
    BUSINESS_TAXONOMY_FLAG,
    AI_OPTIONAL_TEMPLATE_SELECTION_STEP_FLAG,
  ]);
  const {
    [BUSINESS_TAXONOMY_FLAG]: isBusinessTaxonomyEnabled,
    [AI_OPTIONAL_TEMPLATE_SELECTION_STEP_FLAG]: isOptionalTemplateSelectionStepEnabled,
  } = featureFlags;

  if (!isBusinessTaxonomyEnabled) {
    history.replace(routesMap.userArchetypes.url());
  }

  const onSkip = useCallback(() => {
    updateData({
      archetype: null,
      businessCategory: null,
      businessCategorySlug: null,
      templates: [],
    });
    trackSiteSetupSkipButtonClicked({
      stepId,
      archetypeId: null,
      businessCategory: null,
    });

    if (isOptionalTemplateSelectionStepEnabled) {
      history.push(routesMap.howToBuild.url());
    } else {
      history.push(routesMap.gallery.url({ category: categories.categoryIds.ALL }));
    }
  }, [updateData, stepId, isOptionalTemplateSelectionStepEnabled, history]);

  const onNext = useCallback(() => {
    handleSubmit(() => {
      const selectedArchetype = selectedOption ? selectedOption.archetype : contextData.archetype;
      if (selectedOption) {
        updateData({
          businessCategory: selectedOption.label,
          businessCategorySlug: selectedOption.value,
          archetype: selectedArchetype,
          templates: selectedOption.templates || [],
        });
      }

      trackSitesetupBusinessCategoryProvided({
        siteId,
        stepId,
        businessCategory: selectedOption?.value || contextData.businessCategorySlug,
        archetypeId: selectedArchetype,
      });

      if (contextData.useTemplateFlow) {
        history.push(routesMap.siteName.url());
      } else if (isOptionalTemplateSelectionStepEnabled) {
        history.push(routesMap.howToBuild.url());
      } else {
        history.push(
          routesMap.gallery.url({ category: getGalleryCategory(selectedArchetype) || 'all' })
        );
      }
    })();
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, [
    selectedOption,
    getGalleryCategory,
    contextData.businessCategorySlug,
    isOptionalTemplateSelectionStepEnabled,
  ]);

  const handleKeyPress = (e) => {
    if (e.key === ENTER_KEY && !isNextDisabled) {
      onNext();
    }
  };

  const loadOptions = async (inputValue) => {
    let options = [{}];

    setDefaultOptions([]);
    setIsLoading(true);

    try {
      const client = serviceClient.get();
      const { data } = await client.taxonomySearchPublicCategories({
        searchQuery: inputValue,
        locale,
      });

      if (data?.results) {
        options = data.results.map(({ title, category, archetypes, templates }) => ({
          label: title,
          value: category,
          archetype: archetypes[0],
          templates,
        }));
      }
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
    setDefaultOptions(options);
    return options;
  };

  const handleChange = (option) => {
    if (option && option.value) {
      setSelectedOption(option);
      trackBusinessCategorySelected({
        siteId,
        stepId,
        businessCategory: option.value,
        archetypeId: option.archetype,
        source: SOURCE_ID,
      });
      setIsNextDisabled(false);
    }
    setBusinessCategoryValue((option && option.label) || '');
  };

  const handleInputChange = (value) => {
    setBusinessCategoryValue(value);
    setIsNextDisabled(true);
  };

  const handleFocus = () => {
    trackBusinessCategoryInputFocused({
      siteId,
      stepId,
      source: SOURCE_ID,
      businessCategory: selectedOption?.value || null,
    });
    setIsFocused(true);
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  useEffect(() => {
    if (businessCategoryValue) {
      loadOptions(businessCategoryValue);
    }
    // eslint-disable-next-line yola/react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout Header={HeaderGalleryPageContainer} headerOnSkip={onSkip}>
      <SiteSetupLayout
        className="ws-site-setup-layout-business-category"
        captions={captions}
        currentStep={currentStep}
        next={{
          callback: onNext,
          disabled: isNextDisabled,
        }}
        areButtonsOnSeparateRow={false}
      >
        <div className="ws-site-setup-layout-business-category__content">
          <AutocompleteField
            name="businessCategory"
            ref={autocompleteRef}
            rules={{
              validate: (value) => {
                if (value && selectedOption && businessCategoryValue === selectedOption.label) {
                  setIsNextDisabled(false);
                } else if (!isNextDisabled) {
                  setIsNextDisabled(true);
                }

                return true;
              },
            }}
            asyncConfig={{
              loadOptions,
              defaultOptions,
              isLoading,
              cacheOptions: true,
            }}
            iconComponent={<Icon glyph="search" type="inherit" stroke="#141B21" />}
            placeholder={captions.placeholder}
            control={control}
            size="large"
            options={[]}
            inputValue={businessCategoryValue}
            onInputChange={handleInputChange}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={() => {
              setIsFocused(false);
              if (businessCategoryValue && businessCategoryValue !== prevBusinessCategoryValue) {
                loadOptions(businessCategoryValue);
              }
            }}
            menuIsOpen={
              (isNextDisabled && Boolean(businessCategoryValue)) ||
              (isFocused && Boolean(businessCategoryValue))
            }
            openMenuOnFocus={Boolean(businessCategoryValue)}
            filterOptionsFunction={(value) => value}
            maxMenuHeight={MAX_MENU_HEIGHT}
            noResultsText={captions.noResultsText}
            blurInputOnSelect
            shouldMarkSelectedOption
          />
          <Box marginTop="spacing-m">
            <Paragraph size="small" appearance="medium-emphasis">
              {t('Examples:')}
            </Paragraph>
            <div className="ws-site-setup-layout-business-category__suggestions">
              {suggestions.map(({ label }, index) => (
                <ActionButton
                  key={`${`${label.split(' ').join('-')}-${index}`}`}
                  label={label}
                  appearance="accent"
                  fontWeight="regular"
                  format="text"
                  fontSize="large"
                  onClick={async () => {
                    autocompleteRef?.current?.focus();

                    if (label !== businessCategoryValue) {
                      setBusinessCategoryValue(label);
                      const options = await loadOptions(label);
                      const matchingOption = options.find((option) => option.label === label);

                      if (matchingOption) {
                        autocompleteRef?.current?.selectOption(options[0]);
                      } else {
                        setIsNextDisabled(true);
                      }
                    }

                    autocompleteRef?.current?.focus();
                  }}
                />
              ))}
            </div>
          </Box>
        </div>
      </SiteSetupLayout>
    </Layout>
  );
}

export default CategoryStepContainer;
