const wrapWithSVG = (html, size) => {
  const { width, height } = size;
  return `
  <svg id='render' width="100%" viewBox="0 0 ${width} ${height}" xmlns="http://www.w3.org/2000/svg">
    <foreignObject class="svg-scroll-area" x="0" y="0" width="${width}" height="${height}">
      <div class="svg-scroll-area">${html}</div>
    </foreignObject>
  </svg>
  <style>
    body {
      padding: 0;
      margin: 0;
    }
    .svg-scroll-area {
      overflow: auto;
      max-height: 100%;
      -webkit-overflow-scrolling: touch;
    }
  </style>
  `;
};

export default wrapWithSVG;
