import { configure } from '@yola/webapp-plugins';
import i18n from 'src/js/modules/i18n';

const configuration = () => (next) => (action) => {
  switch (action.type) {
    case i18n.actionTypes.SET_LOCALE: {
      next(action);
      const { locale } = action.payload;
      configure({ locale });
      break;
    }
    default: {
      next(action);
    }
  }
};

export default configuration;
