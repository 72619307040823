import React from 'react';
import { designSystem } from '@yola/ws-ui';
import PropTypes from 'prop-types';
import Layout from '../layout';
import GalleryGrid from '../gallery-grid';
import CategoriesBar from '../categories-bar';
import HeaderGalleryPageContainer from '../../containers/header-gallery-page-container';
import { ALL } from '../../modules/categories/constants/category-ids';

const { Section, SectionHeader, SectionContent, Heading, Container } = designSystem;

function GalleryLayout({
  captions,
  activeCategory,
  categories,
  templates,
  categoriesBarRef,
  galleryGridRef,
  isBlankTemplateEnabled,
  onCategorySelect,
  onTemplatePreviewClick,
  onTemplateEditClick,
  onBlankTemplateClick,
}) {
  return (
    <Layout Header={HeaderGalleryPageContainer}>
      <div className="gallery-layout">
        <Section>
          <Container>
            <SectionHeader>
              <Heading type="heading-1">{captions.title}</Heading>
              <Heading type="heading-5">{captions.subtitle}</Heading>
            </SectionHeader>
          </Container>
          <SectionContent>
            <CategoriesBar
              ref={categoriesBarRef}
              categories={categories}
              activeCategory={activeCategory}
              isBlankTemplateEnabled={isBlankTemplateEnabled}
              onCategorySelect={onCategorySelect}
              onBlankTemplateClick={onBlankTemplateClick}
            />
            <div className="gallery-layout__list">
              <GalleryGrid
                ref={galleryGridRef}
                templates={templates}
                onTemplatePreviewClick={onTemplatePreviewClick}
                onTemplateEditClick={onTemplateEditClick}
              />
            </div>
          </SectionContent>
        </Section>
      </div>
    </Layout>
  );
}

GalleryLayout.propTypes = {
  captions: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
  }).isRequired,
  activeCategory: PropTypes.string,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    })
  ).isRequired,
  templates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      slug: PropTypes.string,
      previewUrl: PropTypes.string,
    })
  ).isRequired,
  categoriesBarRef: PropTypes.shape({
    current: PropTypes.any,
  }).isRequired,
  galleryGridRef: PropTypes.shape({
    current: PropTypes.any,
  }).isRequired,
  isBlankTemplateEnabled: PropTypes.bool,
  onCategorySelect: PropTypes.func,
  onTemplatePreviewClick: PropTypes.func,
  onTemplateEditClick: PropTypes.func,
  onBlankTemplateClick: PropTypes.func,
};

GalleryLayout.defaultProps = {
  activeCategory: ALL,
  isBlankTemplateEnabled: false,
  onCategorySelect: Function.prototype,
  onTemplatePreviewClick: Function.prototype,
  onTemplateEditClick: Function.prototype,
  onBlankTemplateClick: Function.prototype,
};

export default React.memo(GalleryLayout);
