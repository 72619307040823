import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import bowser from 'yola-bowser';
import errorModule from 'src/js/modules/error';
import getLocale from 'src/js/modules/i18n/selectors/locale';
import conceptPageIds from 'src/js/modules/templates/constants/concept-page-ids';

const { DEFAULT, ONLINE_STORE } = conceptPageIds;

const useUrl = (url) => {
  const locale = useSelector(getLocale);
  const [htmlContent, setHtmlContent] = useState(null);
  const dispatch = useDispatch();

  useMemo(() => {
    if (!url) return;

    axios({
      method: 'get',
      url,
    })
      .then((response) => {
        const conceptId = url.includes(ONLINE_STORE) ? ONLINE_STORE : DEFAULT;
        const baseUrl = url.replace(`/${locale}-${conceptId}.html`, '').replace(/\/$/gi, '');
        let html = response.data.replace('<head>', `<head><base href="${baseUrl}" >`);
        if (bowser.mobile) {
          html = html
            .replace(/\sfullscreen\s/g, ' fullscreen-safe ')
            .replace(/\sfullscreen=/g, ' fullscreen-safe=');
        }
        html = html.replace(
          /<ws-online-store/g,
          '<ws-online-store view-mode="product-browser" lazy-loading="true" '
        );
        setHtmlContent(html);
      })
      .catch((error) => {
        dispatch(errorModule.actions.trackError(error));
        setHtmlContent('404');
      });
  }, [url]);

  return htmlContent;
};

export default useUrl;
