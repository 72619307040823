import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { withRouter, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import { designSystem, reactHookForm } from '@yola/ws-ui';
import { SiteSetupContext } from 'src/js/modules/site-setup/helpers/site-setup-context';
import routesMap from 'src/js/router/helpers/routes-map';
import Layout from 'src/js/components/layout';
import HeaderGalleryPageContainer from 'src/js/containers/header-gallery-page-container';
import SiteSetupLayout from 'src/js/components/site-setup/site-setup-layout';
import siteSetup from 'src/js/modules/site-setup';
import user from 'src/js/modules/user';
import trackers from 'src/js/modules/analytics/segment/trackers';
import useFeatureFlags from 'src/js/modules/feature-flags/hooks/use-feature-flags';
import getTotalSteps from 'src/js/modules/site-setup/helpers/get-total-steps';
import {
  AI_SITE_WIZARD_FLAG,
  AI_PAGE_WIZARD_FLAG,
  BUSINESS_TAXONOMY_FLAG,
  ENTER_KEY,
} from 'src/js/constants/common';
import getLastStepButtonText from 'src/js/modules/site-setup/helpers/get-last-step-button-text';

const { constants, helpers } = siteSetup;
const { siteSetupNames, common, archetypesMap } = constants;
const { MAX_SITE_NAME_CHARS, MIN_SITE_NAME_CHARS, MAX_BUSINESS_DESCRIPTION_CHARS } = common;
const {
  trackSiteSetupBusinessNameAndIntroductionProvided,
  trackSiteSetupSkipButtonClicked,
} = trackers;

const { Container, ControlGroup, Stack, Checkbox, InputGroupField, TextAreaField } = designSystem;
const { useForm } = reactHookForm;

const getCaptionsByArchetype = (archetype) => {
  switch (archetype) {
    case archetypesMap.BUSINESS:
      return {
        title: t('Tell us more about your business'),
        labels: {
          businessName: t('Business name'),
          businessDescription: t('Brief introduction of your business'),
        },
        placeholders: {
          businessDescription: t(
            'We provide expert cleaning services, specializing in homes and offices. Using eco-friendly products, our trained team ensures a spotless, fresh, and healthy environment for our clients.'
          ),
        },
      };
    case archetypesMap.NON_PROFIT:
      return {
        title: t('Tell us more about your organization'),
        labels: {
          businessName: t('Organization name'),
          businessDescription: t('Brief introduction of your organization'),
        },
        placeholders: {
          businessDescription: t(
            'Our mission-driven organization is dedicated to preserving marine life. Through community engagement, research initiatives, and educational programs, we strive to make a difference for our oceans'
          ),
        },
      };

    case archetypesMap.ONLINE_STORE:
      return {
        title: t('Tell us more about your online store'),
        labels: {
          businessName: t('Store name'),
          businessDescription: t('Brief introduction of your online store'),
        },
        placeholders: {
          businessDescription: t(
            'Our e-commerce store offers a curated selection of handcrafted accessories, with a focus on sustainable materials and unique designs. Each purchase supports artisan communities.'
          ),
        },
      };
    default:
      return {
        title: t('Tell us more about your project'),
        labels: {
          businessName: t('Project name'),
          businessDescription: t('Brief introduction of your project'),
        },
        placeholders: {
          businessDescription: t(
            'Welcome to my personal space where I share my journey as a freelance photographer. Dive into my portfolio, travel diaries, and the stories behind each shot.'
          ),
        },
      };
  }
};

const getCaptions = (archetype, isLastStep, isAISiteWizardEnabled) => ({
  ...getCaptionsByArchetype(archetype),
  subtitle: t(
    'Get the most relevant content for your initial page version by filling in the fields below'
  ),
  buttons: {
    next: isLastStep ? getLastStepButtonText(isAISiteWizardEnabled) : t('Continue'),
  },
});

function BusinessNameStepContainer() {
  const history = useHistory();
  const [featureFlags, , requestedFeatureFlags] = useFeatureFlags([
    AI_PAGE_WIZARD_FLAG,
    AI_SITE_WIZARD_FLAG,
    BUSINESS_TAXONOMY_FLAG,
  ]);
  const {
    [AI_PAGE_WIZARD_FLAG]: isAIPageWizardEnabled,
    [AI_SITE_WIZARD_FLAG]: isAISiteWizardEnabled,
    [BUSINESS_TAXONOMY_FLAG]: isBusinessTaxonomyEnabled,
  } = featureFlags;
  const isAuthorized = useSelector(user.selectors.getUserAuthorizationStatus);
  const location = useLocation();
  const context = useContext(SiteSetupContext);
  const { getData, updateData } = context;
  const contextData = getData();
  const { archetype, siteName } = contextData;
  const isArchetypeSet = Boolean(archetype !== undefined);
  // Only business archetype has access to AI feature
  const isAIFeatureEnabled =
    (isAIPageWizardEnabled || isAISiteWizardEnabled) && archetype === archetypesMap.BUSINESS;

  if (!isArchetypeSet || !isAuthorized) {
    history.replace(
      isBusinessTaxonomyEnabled ? routesMap.siteCategory.url() : routesMap.userArchetypes.url()
    );
  }

  const { number: currentStep } = useMemo(helpers.getCurrentStep, []);
  const total = getTotalSteps(archetype, isAISiteWizardEnabled);
  const isLastStep = currentStep === total;
  const captions = useMemo(() => getCaptions(archetype, isLastStep, isAISiteWizardEnabled), [
    archetype,
    isLastStep,
    isAISiteWizardEnabled,
  ]);
  const isSkipAvailable = !isLastStep;
  const initialBusinessName = contextData.businessName || siteName;

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      businessName: initialBusinessName,
      businessDescription: contextData.businessDescription || '',
    },
    mode: isAISiteWizardEnabled ? 'onSubmit' : 'onTouched',
  });

  const currentBusinessDescription = watch('businessDescription', '');

  const currentBusinessName = watch('businessName');
  const [sameAsSiteName, setSameAsSiteName] = useState(initialBusinessName === siteName);

  useEffect(() => {
    if (sameAsSiteName && currentBusinessName !== siteName) {
      setSameAsSiteName(false);
    }
  }, [currentBusinessName, siteName]);

  const onCheckboxChange = useCallback(
    (checked) => {
      if (checked) {
        setValue('businessName', siteName);
        trigger('businessName');
      }

      setSameAsSiteName(checked);
    },
    [siteName, setValue, trigger]
  );

  const onSkip = () => {
    const stepId = siteSetupNames.BUSINESS_NAME;

    trackSiteSetupSkipButtonClicked({
      stepId,
      archetypeId: archetype,
      businessCategory: contextData.businessCategorySlug,
    });
    history.push(routesMap.businessDetails.url());
  };

  const onNext = useCallback(() => {
    handleSubmit((data) => {
      const { businessName, businessDescription } = data;
      const trimmedName = businessName.trim();
      const trimmedDescription = businessDescription.trim();

      trackSiteSetupBusinessNameAndIntroductionProvided({
        businessName: trimmedName.length > 0 ? trimmedName : null,
        businessDescription: trimmedDescription.length > 0 ? trimmedDescription : null,
        archetypeId: archetype,
        businessCategory: contextData.businessCategorySlug,
      });

      updateData({
        businessName: trimmedName,
        businessDescription: trimmedDescription,
      });

      if (isLastStep) {
        history.replace(routesMap.createSite.url({ currentPath: location.pathname }));
      } else {
        history.push(routesMap.businessDetails.url());
      }
    })();
  }, [archetype, isLastStep, location]);

  const handleKeyPress = (e) => {
    if (e.key === ENTER_KEY && (isAISiteWizardEnabled || isValid)) {
      onNext();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  if (
    !requestedFeatureFlags.includes(AI_PAGE_WIZARD_FLAG) &&
    !requestedFeatureFlags.includes(AI_SITE_WIZARD_FLAG)
  ) {
    return null;
  }

  return (
    <Layout Header={HeaderGalleryPageContainer} headerOnSkip={isSkipAvailable ? onSkip : null}>
      <SiteSetupLayout
        className="ws-site-setup-layout-site-name"
        captions={{
          ...captions,
          subtitle: isAIFeatureEnabled ? captions.subtitle : null,
        }}
        currentStep={currentStep}
        next={{
          callback: onNext,
          disabled: isAISiteWizardEnabled ? false : !isValid,
        }}
      >
        <Container>
          <Stack gap="spacing-m">
            <div>
              <Stack gap="spacing-2-xs">
                <ControlGroup title={captions.labels.businessName}>
                  <InputGroupField
                    name="businessName"
                    control={control}
                    size="large"
                    placeholder={siteName}
                    rules={{
                      maxLength: {
                        value: MAX_SITE_NAME_CHARS,
                        message: t(`Maximum number of characters {number}`, {
                          number: MAX_SITE_NAME_CHARS,
                        }),
                      },
                      minLength: {
                        value: MIN_SITE_NAME_CHARS,
                        message: t('Minimum number of characters {number}', {
                          number: MIN_SITE_NAME_CHARS,
                        }),
                      },
                    }}
                  />
                </ControlGroup>
                <Checkbox
                  id="sameAsSiteName"
                  label={t('Same as Sitename')}
                  onChange={onCheckboxChange}
                  checked={sameAsSiteName}
                  value="sameAsSiteName"
                />
              </Stack>
            </div>
            <ControlGroup
              title={captions.labels.businessDescription}
              additionalInfo={`${currentBusinessDescription.length}/${MAX_BUSINESS_DESCRIPTION_CHARS}`}
              additionalInfoAppearance="medium-emphasis"
            >
              <TextAreaField
                name="businessDescription"
                maxLength={MAX_BUSINESS_DESCRIPTION_CHARS}
                placeholder={captions.placeholders.businessDescription}
                control={control}
                size="large"
                resize
                rules={{
                  maxLength: {
                    value: MAX_BUSINESS_DESCRIPTION_CHARS,
                    message: t(`Maximum number of characters {number}`, {
                      number: MAX_BUSINESS_DESCRIPTION_CHARS,
                    }),
                  },
                }}
              />
            </ControlGroup>
          </Stack>
        </Container>
      </SiteSetupLayout>
    </Layout>
  );
}

export default withRouter(BusinessNameStepContainer);
