import showContactForm from './show-contact-form';
import showSearchForm from './show-search-form';
import hideForm from './hide-form';
import preload from './preload';

export default {
  showContactForm,
  showSearchForm,
  hideForm,
  preload,
};
