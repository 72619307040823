import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from 'i18next';
import errorModule from './modules/error';
import CriticalErrorDialog from './modules/error/components/critical-error-dialog';
import errorTypes from './modules/error/constants/error-types';

const getCaptions = () => ({
  title: t('Something went wrong'),
  description: t('Please reload the page'),
  dismiss: t('Reload'),
});

class ErrorBoundary extends React.Component {
  componentDidCatch(error, errorInfo) {
    const { setCriticalError } = this.props;

    setCriticalError({
      error,
      errorInfo,
      logSentry: true,
    });
  }

  render() {
    const { children, criticalError } = this.props;
    const captions = getCaptions();

    if (criticalError) {
      if (criticalError.message.includes(errorTypes.NETWORK_ERROR) && !navigator.onLine) {
        return children;
      }
      return <CriticalErrorDialog captions={captions} />;
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  criticalError: PropTypes.instanceOf(Error),
  setCriticalError: PropTypes.func.isRequired,
};

ErrorBoundary.defaultProps = {
  criticalError: null,
};

const mapStateToProps = (state) => ({
  criticalError: errorModule.selectors.getCriticalError(state),
});

const mapDispatchToProps = {
  setCriticalError: errorModule.actions.setCriticalError,
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);
