import yousersjs from '@yola/yousersjs';
import { snakeCaseToCamelCase } from 'src/js/utils/convert-case';
import actionTypes from '../constants/action-types';

const userPreferencesManager = () => (next) => (action) => {
  switch (action.type) {
    case actionTypes.UPDATE_USER_PREFERENCE: {
      const { property, value } = action.payload;
      yousersjs
        .preferences()
        .put(property, value)
        .then(({ body }) => {
          next({
            ...action,
            payload: {
              property: snakeCaseToCamelCase(body.name),
              value: body.value,
            },
          });
        })
        .catch(console.error);
      break;
    }

    default:
      next(action);
  }
};

export default userPreferencesManager;
