import React, { memo, useEffect, useState } from 'react';
import { designSystem } from '@yola/ws-ui';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { t } from 'i18next';
import isMatchMobile from 'src/js/helpers/is-match-mobile';
import ConditionalWrapper from 'src/js/common/components/conditional-wrapper';

const {
  ActionButton,
  Heading,
  Section,
  SectionContent,
  SectionHeader,
  ActionButtonGroup,
  Container,
} = designSystem;

function SiteSetupLayout(props) {
  const {
    captions,
    children,
    className,
    next,
    skip,
    currentStep,
    isStepCounter,
    areButtonsOnSeparateRow,
  } = props;
  const { title, subtitle, buttons } = captions;
  const siteSetupLayoutClassName = classnames('ws-site-setup-layout', className);

  const [isMobile, setIsMobile] = useState(isMatchMobile());

  useEffect(() => {
    const resize = () => setIsMobile(isMatchMobile());

    window.addEventListener('resize', resize);

    return () => window.removeEventListener('resize', resize);
  }, []);

  const buttonsMarkup = (
    <ConditionalWrapper
      condition={areButtonsOnSeparateRow || isMobile}
      wrapper={(children) => <ActionButtonGroup>{children}</ActionButtonGroup>}
    >
      <>
        {next.callback && (
          <ActionButton
            appearance="accent"
            format="solid"
            size="large"
            label={buttons?.next}
            onClick={next.callback}
            fullWidth={isMobile}
            disabled={next.disabled}
          />
        )}
        {skip.callback && (
          <ActionButton
            format="text"
            size="large"
            label={buttons?.skip}
            onClick={skip.callback}
            fullWidth={isMobile}
          />
        )}
      </>
    </ConditionalWrapper>
  );

  return (
    <div className={siteSetupLayoutClassName}>
      <Section>
        <Container>
          <SectionHeader>
            {isStepCounter && (
              <Heading type="heading-6" appearance="accent">
                {t('Step {current}', {
                  current: currentStep,
                })}
              </Heading>
            )}
            <Heading type="heading-1">{title}</Heading>
            {subtitle && <Heading type="heading-5">{subtitle}</Heading>}
          </SectionHeader>
        </Container>
        <SectionContent>
          {areButtonsOnSeparateRow ? (
            <>
              {children}
              <Container>{buttonsMarkup}</Container>
            </>
          ) : (
            <Container
              className={classnames({
                'ws-site-setup-layout__container ws-site-setup-layout__container--one-row': !isMobile,
              })}
            >
              {children}
              {buttonsMarkup}
            </Container>
          )}
        </SectionContent>
      </Section>
    </div>
  );
}

SiteSetupLayout.defaultProps = {
  isStepCounter: true,
  children: null,
  next: {
    callback: null,
    disabled: false,
  },
  skip: {
    callback: null,
  },
  className: '',
  areButtonsOnSeparateRow: true,
};

SiteSetupLayout.propTypes = {
  captions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    buttons: PropTypes.shape({
      next: PropTypes.string,
      skip: PropTypes.string,
    }),
  }).isRequired,
  children: PropTypes.node,
  currentStep: PropTypes.number.isRequired,
  next: PropTypes.shape({
    callback: PropTypes.func,
    disabled: PropTypes.bool,
  }),
  skip: PropTypes.shape({
    callback: PropTypes.func,
  }),
  isStepCounter: PropTypes.bool,
  className: PropTypes.string,
  areButtonsOnSeparateRow: PropTypes.bool,
};

export default memo(SiteSetupLayout);
