import get from 'lodash.get';
import user from '../../user';
import getIsB2C from './is-b2c';

const getYolaEditorUrl = (rootState) => {
  const userData = user.selectors.getUserData(rootState);

  if (!userData) {
    return null;
  }

  if (getIsB2C(rootState)) {
    return get(rootState, 'config.service.common["yola-editor"].url', null);
  }

  return get(rootState, 'config.service.common["yola-editor"].wl_url', null);
};

export default getYolaEditorUrl;
