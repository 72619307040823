import connectedStore from 'src/js/store/connected-store';
import getUserAuthorizationStatus from '../selectors/user-authorization-status';

const isUserAuthorized = () => {
  const store = connectedStore.get();
  const state = store.getState();

  return getUserAuthorizationStatus(state);
};

export default isUserAuthorized;
