import segment from './segment';
import sentry from './sentry';
import verifiers from './verifiers';
import tagManager from './tag-manager';
import init from './init';

export default {
  segment,
  sentry,
  verifiers,
  tagManager,
  init,
};
