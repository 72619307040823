import axios from 'axios';
import i18next from 'i18next';
import { DEFAULT_I18NEXT_NAMESPACE, DEFAULT_LOCALE } from '../constants';
import actionTypes from '../constants/action-types';
import getLocaleFromUrl from '../helpers/get-locale-from-url';
import config from '../../config';
import setCookie from '../../../utils/set-cookie';
import actions from '../actions';

const translationsLoader = (store) => (next) => (action) => {
  switch (action.type) {
    case actionTypes.LOAD_TRANSLATIONS: {
      const { locale } = action.payload;
      const { language } = i18next;

      if (locale === language) {
        next(action);
        break;
      }

      if (!i18next.hasResourceBundle(locale, DEFAULT_I18NEXT_NAMESPACE)) {
        axios
          .get(`/locale/${locale}.json`)
          .then(({ data }) => {
            i18next.addResourceBundle(locale, DEFAULT_I18NEXT_NAMESPACE, data, true, true);
            i18next.changeLanguage(locale).then(() => {
              store.dispatch(actions.setLocaLe(locale));
              next(action);
            });
          })
          .catch(() => {
            store.dispatch(actions.loadTranslations(DEFAULT_LOCALE));
          });
        break;
      }

      i18next.changeLanguage(locale).then(() => {
        store.dispatch(actions.setLocaLe(locale));
        next(action);
      });
      break;
    }
    case config.actionTypes.SET_SERVICE_CONFIG: {
      next(action);
      const state = store.getState();
      const localeCookieKey = config.selectors.getLocaleCookie(state);
      const supportedLocales = config.selectors.getBaseLanguageCodes(state);
      const currentDomain = config.selectors.getCurrentDomain(state);

      const locale = getLocaleFromUrl();
      if (localeCookieKey && locale && supportedLocales.includes(locale)) {
        setCookie(localeCookieKey, locale, currentDomain);
      }
      break;
    }

    default:
      next(action);
  }
};

export default translationsLoader;
