import data from './data';
import siteIdRecreate from './site-id-recreate';
import limit from './limit';
import siteIdToSwitchTemplate from './site-id-to-switch-template';
import templateBuildSlug from './template-build-slug';

export default {
  data,
  siteIdRecreate,
  limit,
  siteIdToSwitchTemplate,
  templateBuildSlug,
};
