import { combineReducers } from 'redux';
import config from './modules/config';
import user from './modules/user';
import partner from './modules/partner';
import templates from './modules/templates';
import sites from './modules/sites';
import spinner from './modules/spinner';
import i18n from './modules/i18n';
import dialogs from './modules/dialogs';
import subscriptions from './modules/subscriptions';
import error from './modules/error';

const rootReducer = combineReducers({
  spinner: spinner.reducers.spinner,
  config: combineReducers(config.reducers),
  user: combineReducers(user.reducers),
  partner: partner.reducers.partnerData,
  templates: combineReducers(templates.reducers),
  sites: combineReducers(sites.reducers),
  i18n: combineReducers(i18n.reducers),
  dialogs: combineReducers(dialogs.reducers),
  subscriptions: combineReducers(subscriptions.reducers),
  error: combineReducers({
    criticalError: error.reducers.criticalError,
  }),
});

export default rootReducer;
