import categories from '../../categories';

const { categoryIds } = categories;

export default {
  [categoryIds.PERSONAL]: 'Personal',
  [categoryIds.BUSINESS]: 'Business',
  [categoryIds.ONLINE_STORE]: 'Online store',
  [categoryIds.COMMUNITY_OR_ORGANIZATION]: 'Community/Organization',
};
