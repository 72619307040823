export const DESKTOP = 'desktop';
export const TABLET = 'tablet';
export const MOBILE = 'mobile';

export const viewportCheckpoints = {
  [DESKTOP]: { width: 1280, height: 720 },
  [TABLET]: { width: 768, height: 1024 },
  [MOBILE]: { width: 375, height: 812 },
};
export const PREVIEW_STYLES_ID = 'preview-styles';
