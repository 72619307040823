import actions from './actions';
import reducers from './reducers';
import middleware from './middleware';
import selectors from './selectors';
import verifiers from './verifiers';
import thunks from './thunks';
import actionTypes from './constants/action-types';
import platformAccess from './constants/platform-access';
import preferences from './constants/preferences';

export default {
  actions,
  reducers,
  selectors,
  middleware,
  actionTypes,
  platformAccess,
  preferences,
  verifiers,
  thunks,
};
