import track from '../helpers/track';
import eventTypes from '../constants/event-types';
import { APP_NAME } from '../../../../constants/common';

const trackBusinessCategoryInputFocused = ({ siteId, stepId, source, businessCategory = null }) => {
  track(eventTypes.BUSINESS_CATEGORY_INPUT_FOCUSED, {
    appName: APP_NAME,
    siteId,
    stepId,
    businessCategory,
    source,
  });
};

export default trackBusinessCategoryInputFocused;
