const ACTIVE_TYPES = ['active', 'canceled', 'expired', 'froze'];
const BRONZE = 'bronze';
const SILVER = 'silver';
const GOLD = 'gold';
const FREE = 'free';
const ECOMMERCE_FREE = 'wl_ecommerce_free';
const ECOMMERCE_BASIC = 'wl_ecommerce_basic';
const ECOMMERCE_PROFESSIONAL = 'wl_ecommerce_professional';
const ECOMMERCE_UNLIMITED = 'wl_ecommerce_unlimited';
const ECOMMERCE_LEGACY = 'wl_ecommerce_legacy';
const B2C_ECOMMERCE = 'ecommerce';
const B2C_ECOMMERCE_FREE = 'ecommerce_free';
const B2C_ECOMMERCE_BASIC = 'ecommerce_basic';
const B2C_ECOMMERCE_PROFESSIONAL = 'ecommerce_professional';
const B2C_ECOMMERCE_UNLIMITED = 'ecommerce_unlimited';
const WL_FREE = 'wl_free';
const WL_ULTRA_LITE = 'wl_ultra_lite';
const WL_LITE = 'wl_lite';
const WL_BASIC = 'wl_basic';
const WL_PREMIUM = 'wl_premium';
const WL_PREMIUM_ECOMM = 'wl_premium_ecomm';

export default {
  ACTIVE_TYPES,
  FREE,
  BRONZE,
  SILVER,
  GOLD,
  ECOMMERCE_FREE,
  ECOMMERCE_BASIC,
  ECOMMERCE_PROFESSIONAL,
  ECOMMERCE_UNLIMITED,
  ECOMMERCE_LEGACY,
  B2C_ECOMMERCE,
  B2C_ECOMMERCE_FREE,
  B2C_ECOMMERCE_BASIC,
  B2C_ECOMMERCE_PROFESSIONAL,
  B2C_ECOMMERCE_UNLIMITED,
  WL_FREE,
  WL_ULTRA_LITE,
  WL_LITE,
  WL_BASIC,
  WL_PREMIUM,
  WL_PREMIUM_ECOMM,
};
