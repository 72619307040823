import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'url-polyfill'; // This polyfill adds support URL API for IE
import ReactModal from 'react-modal';
import store from './store';
import App from './app';
import initApp from './init-app';

/*
  In order to prevent tracking redundant errors with "Network error"
  message we need to establish global variable on "beforeunload" event
  and use it in "errorHandler" middleware to prevent tracking such error
*/
window.addEventListener('beforeunload', () => {
  // eslint-disable-next-line no-underscore-dangle
  window._ws_resources_unloaded = true;
});

ReactModal.setAppElement('#app');

const Root = () => (
  <Provider store={store}>
    <App />
  </Provider>
);

initApp().then(() => {
  ReactDOM.render(<Root />, document.getElementById('app'));
});
