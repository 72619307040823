function waitWsBlocksLoading(targetDocument, onSuccess, onFail = () => {}) {
  const wsBlocksNodes = [...targetDocument.querySelectorAll('ws-block[src]')];
  // comparison with `false` in order to avoid the case when imported site
  // may have `ws-block` which is different from our ws-block component
  const nonReadyBlocks = wsBlocksNodes.filter((wsBlock) => wsBlock.ready === false);

  if (!nonReadyBlocks.length) {
    onSuccess();
    return;
  }

  const blockLoadingQue = nonReadyBlocks.map(
    (wsBlock) =>
      new Promise((resolve, reject) => {
        function onReady() {
          resolve();

          // eslint-disable-next-line
          wsBlock.removeEventListener('error', onError);
        }

        function onError() {
          reject();

          wsBlock.removeEventListener('ready', onReady);
        }

        wsBlock.addEventListener('ready', onReady, { once: true });
        wsBlock.addEventListener('error', onError, { once: true });
      })
  );

  Promise.all(blockLoadingQue).then(onSuccess, onFail);
}

export default waitWsBlocksLoading;
