import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { designSystem } from '@yola/ws-ui';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import getLogoSrc from 'src/js/modules/user/selectors/get-logo-src';
import isMatchMobile from 'src/js/helpers/is-match-mobile';
import { AI_SITE_WIZARD_FLAG } from 'src/js/constants/common';
import siteSetup from '../../modules/site-setup';
import useFeatureFlags from '../../modules/feature-flags/hooks/use-feature-flags';

const { ActionButton, LivePreviewHeader, Preloader } = designSystem;
const { helpers } = siteSetup;

const getLogoSize = () => {
  if (isMatchMobile()) {
    return {
      width: 38,
      height: 24,
    };
  }

  return {
    width: 49,
    height: 32,
  };
};

function HeaderDetailPage({
  mode,
  archetype,
  toggleMode,
  isPreloader,
  onUseThisTemplate,
  onBackButtonClick,
}) {
  const src = useSelector(getLogoSrc);

  const { number: currentStep } = useMemo(helpers.getCurrentStep, []);
  const [featureFlags] = useFeatureFlags([AI_SITE_WIZARD_FLAG]);
  const { [AI_SITE_WIZARD_FLAG]: isAISiteWizardEnabled } = featureFlags;
  const total = useMemo(() => helpers.getTotalSteps(archetype, isAISiteWizardEnabled), [archetype]);
  const preloader = isPreloader ? { current: currentStep, total } : null;
  const progress = preloader && (100 / preloader.total) * preloader.currentStep;

  const onToggleMode = useCallback(
    (newMode) => {
      toggleMode(newMode);
    },
    [toggleMode]
  );

  const renderPrimaryBtn = () => (
    <ActionButton
      appearance="accent"
      format="solid"
      label={isAISiteWizardEnabled ? t('Start editing') : t('Use this template')}
      onClick={onUseThisTemplate}
      rounded={false}
      size="large"
      fullWidth
    />
  );

  return (
    <div className="template-preview-header">
      <LivePreviewHeader
        captions={{
          back: t('Back'),
        }}
        logo={{
          src,
          width: getLogoSize().width,
          height: getLogoSize().height,
        }}
        onBack={onBackButtonClick}
        onToggleMode={onToggleMode}
        primaryButton={renderPrimaryBtn()}
        selectedMode={mode}
      />
      {progress && <Preloader progress={progress} />}
      <div className="header__use-template-button">{renderPrimaryBtn()}</div>
    </div>
  );
}

HeaderDetailPage.defaultProps = {
  isPreloader: false,
  onBackButtonClick: null,
};

HeaderDetailPage.propTypes = {
  mode: PropTypes.string.isRequired,
  archetype: PropTypes.string.isRequired,
  toggleMode: PropTypes.func.isRequired,
  isPreloader: PropTypes.bool,
  onUseThisTemplate: PropTypes.func.isRequired,
  onBackButtonClick: PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf([null])]),
};

export default HeaderDetailPage;
