import axios from 'axios';
import actions from '../actions';

const fetchConfig = () => async (dispatch) => {
  try {
    const response = await axios({
      method: 'get',
      url: '/config.json',
      withCredentials: true,
    });
    dispatch(actions.setServiceConfig(response.data));
  } catch (e) {
    console.error('Failed to load config', e);
  }
};

export default fetchConfig;
