import errorTypes from '../constants/error-types';

const getErrorType = (error) => {
  if (error) {
    switch (error.type) {
      // In future, an appropriate error types values to be defined,
      // representing the context of the 'Something went wrong' condition

      default:
        return errorTypes.UNKNOWN;
    }
  } else {
    return errorTypes.UNKNOWN;
  }
};

export default getErrorType;
