import { APP_NAME } from 'src/js/constants/common';
import getSiteIdRecreate from 'src/js/modules/sites/accessors/get-site-id-recreate';
import track from '../helpers/track';
import eventTypes from '../constants/event-types';

const trackSiteSetupBackButtonClicked = ({ stepId, archetypeId = null, businessCategory = null }) =>
  track(eventTypes.SITESETUP_BACK_BUTTON_CLICKED, {
    appName: APP_NAME,
    siteId: getSiteIdRecreate() || null,
    stepId,
    archetypeId,
    businessCategory,
  });

export default trackSiteSetupBackButtonClicked;
