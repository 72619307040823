import setUserData from './set-user-data.js';
import setUserPartnerData from './set-user-partner-data';
import setUserPreferences from './set-user-preferences';
import updateUserPreference from './update-user-preference';

export default {
  setUserData,
  setUserPartnerData,
  setUserPreferences,
  updateUserPreference,
};
