import getApiUrl from './api-url';
import getBaseLanguageCodes from './base-language-codes';
import getCookieDomain from './cookie-domain';
import getCurrentDomain from './current-domain';
import getLocaleCookie from './locale-cookie';
import getSegmentIOKey from './segment-io-key';
import getWLCookieDomain from './wl-cookie-domain';
import getWLDomain from './wl-domain';
import getLoginUrl from './login-url';
import getSignupUrl from './signup-url';
import getUpgradeUrl from './upgrade-url';
import getYolaEditorUrl from './yola-editor-url';
import getMyyolaUrl from './myyola-url';
import getYolacomUrl from './yolacom-url';
import getZendeskWidgetUrl from './zendesk-widget-url';
import getGoogleTagManagerDataLayerName from './google-tag-manager-data-layer-name';
import getConfig from './config';
import getIsB2C from './is-b2c';
import getSentryDSN from './sentry-dsn';
import getGTM from './gtm';

export default {
  getApiUrl,
  getBaseLanguageCodes,
  getCookieDomain,
  getCurrentDomain,
  getLocaleCookie,
  getSegmentIOKey,
  getWLCookieDomain,
  getWLDomain,
  getLoginUrl,
  getSignupUrl,
  getYolaEditorUrl,
  getMyyolaUrl,
  getYolacomUrl,
  getZendeskWidgetUrl,
  getGoogleTagManagerDataLayerName,
  getConfig,
  getIsB2C,
  getSentryDSN,
  getGTM,
  getUpgradeUrl,
};
