import * as Sentry from '@sentry/browser';

let sentryIsInitialized = false;
const releasePlaceholder = 'BUILD_NUMBER_PLACEHOLDER';

const init = (dsn) => {
  const sentryConfig = {
    dsn,
  };

  // eslint-disable-next-line no-underscore-dangle
  const release = window.__WS__BUILD_NUMBER;
  if (release !== releasePlaceholder) {
    sentryConfig.release = release;
  }

  Sentry.init(sentryConfig);
  sentryIsInitialized = true;
};
const isInit = () => sentryIsInitialized;

const setUserId = (id) => {
  if (id) {
    Sentry.configureScope((scope) => {
      scope.setUser({ id });
      // eslint-disable-next-line no-console
      console.info(`Sentry set user ID: ${id}`);
    });
  }
};

const getInstance = () => Sentry;

export default {
  init,
  getInstance,
  setUserId,
  isInit,
};
