import yosubscriptionsjs from '@yola/yosubscriptionsjs';
import config from 'src/js/modules/config';
import { snakeCaseObjectToCamelCase } from 'src/js/utils/convert-case';
import setCurrentPackage from '../actions/set-current-package';

const fetchCurrentPackage = () => async (dispatch, getState) => {
  const state = getState();
  const origin = config.selectors.getApiUrl(state);
  try {
    const { body } = await yosubscriptionsjs({ origin }).getCurrentPackage();
    const data = snakeCaseObjectToCamelCase(body);
    dispatch(setCurrentPackage(data));
  } catch (error) {
    console.error(error);
  }
};

export default fetchCurrentPackage;
