const animatedIcons = [
  {
    key: 1,
    src: '/images/site-setup/how-to-build-step/star-1.svg',
    style: { top: '13%', left: '-4.5%', animationDuration: '2.4s', animationDelay: '0.5s' },
  },
  {
    key: 2,
    src: '/images/site-setup/how-to-build-step/star-2.svg',
    style: { top: '26%', left: '-3%', animationDuration: '2.3s' },
  },
  {
    key: 3,
    src: '/images/site-setup/how-to-build-step/point.svg',
    style: { top: '38%', left: '1%', animationDuration: '2.2s', animationDelay: '0.2s' },
  },
  {
    key: 4,
    src: '/images/site-setup/how-to-build-step/star-2.svg',
    style: { top: '-12%', left: '20%', animationDuration: '2.1s' },
  },
  {
    key: 5,
    src: '/images/site-setup/how-to-build-step/star-1.svg',
    style: { top: '8%', right: '12%', animationDuration: '2.5s', animationDelay: '0.7s' },
  },
  {
    key: 6,
    src: '/images/site-setup/how-to-build-step/star-1.svg',
    style: { top: '33%', right: '-3.5%', animationDuration: '3s' },
  },
  {
    key: 7,
    src: '/images/site-setup/how-to-build-step/star-1.svg',
    style: { top: '50%', right: '3%', animationDuration: '2.1s', animationDelay: '0.3s' },
  },
  {
    key: 8,
    src: '/images/site-setup/how-to-build-step/point.svg',
    style: { top: '26%', right: '35%', animationDuration: '2.6s', animationDelay: '1s' },
  },
  {
    key: 9,
    src: '/images/site-setup/how-to-build-step/star-2.svg',
    style: { top: '-10%', right: '4%', animationDuration: '2.8s', animationDelay: '0.1s' },
  },
  {
    key: 10,
    src: '/images/site-setup/how-to-build-step/star-2.svg',
    style: { bottom: '-9%', left: '3%', animationDuration: '2.8s', animationDelay: '0.1s' },
  },
];

export default animatedIcons;
