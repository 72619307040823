import siteSetupNames from './site-setup-names';
import categories from '../../categories';

const { categoryIds } = categories;

export default {
  [siteSetupNames.ARCHETYPES]: 1,
  [siteSetupNames.CATEGORY]: 1,
  [siteSetupNames.SITE_NAME]: 3,
  [siteSetupNames.BUSINESS_NAME]: 4,
  [siteSetupNames.BUSINESS_DETAILS]: 5,
  [siteSetupNames.HOW_TO_BUILD]: 2,
  [siteSetupNames.GALLERY]: 2,
  [categoryIds.ALL]: 2,
  [categoryIds.PERSONAL]: 2,
  [categoryIds.BUSINESS]: 2,
  [categoryIds.ONLINE_STORE]: 2,
  [categoryIds.COMMUNITY_OR_ORGANIZATION]: 2,
};
