export default {
  primary600: '#34363c',
  primary700: '#252a31',
  primary800: '#141b21',
  cta600: '#ff1b69',
  cta700: '#e7004f',
  cta800: '#900031',
  info600: '#31b0d5',
  info700: '#1f9bbf',
  info800: '#0e87ab',
  success600: '#1ab744',
  success700: '#1b9b4a',
  success800: '#0b8738',
  warning600: '#ffca28',
  warning700: '#ffb302',
  warning800: '#ff8f00',
  danger600: '#d32f2f',
  danger700: '#c62828',
  danger800: '#b71b1c',
};
