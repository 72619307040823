import React from 'react';
import PropTypes from 'prop-types';
import { AlertDialog } from '@yola/ws-ui';
import { redirectToUrl } from 'src/js/utils/redirect-to-url';
import routesMap from 'src/js/router/helpers/routes-map';

const CriticalErrorDialog = (props) => <AlertDialog {...props} />;

CriticalErrorDialog.propTypes = {
  captions: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    dismiss: PropTypes.string,
  }),
  glyph: PropTypes.string,
  handleCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  onDismiss: PropTypes.func,
};

CriticalErrorDialog.defaultProps = {
  captions: {
    title: 'Something went wrong',
    description: 'Please reload the page',
    dismiss: 'Reload',
  },
  glyph: 'alert-circle',
  handleCancel: null,
  onSubmit: null,
  onDismiss: () => redirectToUrl(routesMap.index.url()),
};

export default CriticalErrorDialog;
