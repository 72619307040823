import {
  SITE_ID_TO_RECREATE,
  SITE_ID_TO_SWITCH,
  TEMPLATE_BUILD_SLUG,
} from 'src/js/constants/common';

const getSearchParamsString = () => {
  const searchParams = new URLSearchParams(window.location.search);

  [SITE_ID_TO_RECREATE, SITE_ID_TO_SWITCH, TEMPLATE_BUILD_SLUG].forEach((param) =>
    searchParams.delete(param)
  );

  const searchParamsString = searchParams.toString();

  return searchParamsString ? `?${searchParamsString}` : '';
};

export default getSearchParamsString;
