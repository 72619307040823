import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import siteSetup from 'src/js/modules/site-setup';
import HeaderGalleryPageContainer from 'src/js/containers/header-gallery-page-container';
import { ALL } from 'src/js/modules/categories/constants/category-ids';
import SiteSetupLayout from '../site-setup-layout';
import Layout from '../../layout';
import GalleryGrid from '../../gallery-grid';
import CategoriesBar from '../../categories-bar';

const { helpers } = siteSetup;

function GalleryLayoutStep({
  captions,
  activeCategory,
  categories,
  templates,
  categoriesBarRef,
  galleryGridRef,
  isBlankTemplateEnabled,
  onCategorySelect,
  onTemplatePreviewClick,
  onTemplateEditClick,
  onBlankTemplateClick,
  onFilterTemplateClick,
}) {
  const { number: currentStep } = useMemo(helpers.getCurrentStep, []);

  return (
    <Layout Header={HeaderGalleryPageContainer}>
      <SiteSetupLayout
        className="ws-site-setup-layout-gallery"
        captions={captions}
        currentStep={currentStep}
      >
        <CategoriesBar
          ref={categoriesBarRef}
          categories={categories}
          activeCategory={activeCategory}
          isBlankTemplateEnabled={isBlankTemplateEnabled}
          onCategorySelect={onCategorySelect}
          onBlankTemplateClick={onBlankTemplateClick}
          onFilterTemplateClick={onFilterTemplateClick}
        />
        <div className="ws-site-setup-layout-gallery__list">
          <GalleryGrid
            ref={galleryGridRef}
            templates={templates}
            onTemplatePreviewClick={onTemplatePreviewClick}
            onTemplateEditClick={onTemplateEditClick}
          />
        </div>
      </SiteSetupLayout>
    </Layout>
  );
}

GalleryLayoutStep.propTypes = {
  captions: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
  }).isRequired,
  activeCategory: PropTypes.string,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    })
  ).isRequired,
  templates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      slug: PropTypes.string,
      previewUrl: PropTypes.string,
    })
  ).isRequired,
  categoriesBarRef: PropTypes.shape({
    current: PropTypes.any,
  }).isRequired,
  galleryGridRef: PropTypes.shape({
    current: PropTypes.any,
  }).isRequired,
  isBlankTemplateEnabled: PropTypes.bool,
  onCategorySelect: PropTypes.func,
  onTemplatePreviewClick: PropTypes.func,
  onTemplateEditClick: PropTypes.func,
  onBlankTemplateClick: PropTypes.func,
  onFilterTemplateClick: PropTypes.func,
};

GalleryLayoutStep.defaultProps = {
  activeCategory: ALL,
  isBlankTemplateEnabled: false,
  onCategorySelect: Function.prototype,
  onTemplatePreviewClick: Function.prototype,
  onTemplateEditClick: Function.prototype,
  onBlankTemplateClick: Function.prototype,
  onFilterTemplateClick: Function.prototype,
};

export default React.memo(GalleryLayoutStep);
