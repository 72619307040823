import { APP_NAME } from 'src/js/constants/common';
import getSiteIdRecreate from 'src/js/modules/sites/accessors/get-site-id-recreate';
import track from '../helpers/track';
import eventTypes from '../constants/event-types';

const trackSiteSetupBusinessNameAndIntroductionProvided = ({
  businessName,
  businessDescription,
  archetypeId,
  businessCategory = null,
}) =>
  track(eventTypes.SITESETUP_BUSINESS_NAME_AND_INTRODUCTION_PROVIDED, {
    appName: APP_NAME,
    siteId: getSiteIdRecreate() || null,
    businessName,
    businessDescription,
    archetypeId,
    businessCategory,
  });

export default trackSiteSetupBusinessNameAndIntroductionProvided;
