import getCurrentStep from './get-current-step';
import getTotalSteps from './get-total-steps';
import isTemplateRoute from './is-template-route';
import getLastStepButtonText from './get-last-step-button-text';

export default {
  getCurrentStep,
  getTotalSteps,
  isTemplateRoute,
  getLastStepButtonText,
};
