import setSitesData from './set-sites-data';
import setSiteIdRecreate from './set-site-id-recreate';
import setTemplateBuildSlug from './set-template-build-slug';
import setSitesLimit from './set-sites-limit';
import setSiteIdToSwitchTemplate from './set-site-id-to-switch-template';

export default {
  setSitesData,
  setSiteIdRecreate,
  setTemplateBuildSlug,
  setSitesLimit,
  setSiteIdToSwitchTemplate,
};
