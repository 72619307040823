import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { t } from 'i18next';
import { SiteSetupContext } from 'src/js/modules/site-setup/helpers/site-setup-context';
import routesMap from 'src/js/router/helpers/routes-map';
import config from 'src/js/modules/config';
import useFeatureFlags from 'src/js/modules/feature-flags/hooks/use-feature-flags';
import {
  AI_SITE_WIZARD_SITE_PROP,
  aiSiteWizardSitePropValues,
} from 'src/js/constants/ai-site-wizard';
import HowToBuildStep from '../../components/site-setup/how-to-build-step';
import getGalleryCategory from '../../helpers/get-gallery-category';
import useOnTemplateSelect from '../../modules/site-setup/hooks/use-on-template-select';
import getCurrentStep from '../../modules/site-setup/helpers/get-current-step';
import trackers from '../../modules/analytics/segment/trackers';
import {
  BLANK_TEMPLATE_BUILD_SLUG,
  DEFAULT_TEMPLATE_BUILD_SLUG,
} from '../../modules/templates/constants/common';
import { BLANK_TEMPLATE_FLAG } from '../../constants/common';
import cardTypes from '../../constants/site-setup/how-to-build-step/card-types';
import constants from '../../modules/site-setup/constants';

const {
  trackSitesetupStartWithBlankTemplateSelected,
  trackSitesetupStartWithATemplateSelected,
  trackSitesetupAiGenerateWebsiteSelected,
} = trackers;
const { triggerIds } = constants;

const getCaptions = () => ({
  title: t("Let's start building your dream website"),
  subtitle: t('How do you want to proceed?'),
  blankTemplate: {
    title: t('Know what you do?'),
    link: t('Start from scratch'),
  },
  legacyTemplate: {
    title: t('Looking for Sitebuilder Classic?'),
    link: t('Continue with classic'),
  },
});
const getCards = () => [
  {
    id: cardTypes.AI_GENERATE,
    title: t('Generate your website in seconds'),
    subtitle: t('Recommended'),
    description: t('Let AI create a website for you'),
    iconGlyph: 'ai',
    withDecoration: true,
  },
  {
    id: cardTypes.START_WITH_TEMPLATE,
    title: t('Start with a template'),
    description: t('Use designer-made templates'),
    iconGlyph: 'pages',
  },
];

const HowToBuildStepContainer = ({ b2cFullAccess }) => {
  const history = useHistory();
  const context = useContext(SiteSetupContext);
  const yolacomUrl = useSelector(config.selectors.getYolacomUrl);
  const onTemplateSelect = useOnTemplateSelect();
  const [{ [BLANK_TEMPLATE_FLAG]: isBlankTemplateAvailable }] = useFeatureFlags([
    BLANK_TEMPLATE_FLAG,
  ]);

  const { getData, updateData } = context;
  const contextData = getData();
  const { number: currentStep, stepName } = getCurrentStep();
  const captions = getCaptions();
  const cards = getCards();
  const legacyPath = routesMap.legacyTemplates.url();
  const legacySitebuilderUrl = `${yolacomUrl}${legacyPath}`;
  const { businessCategorySlug, archetype = null, templates } = contextData;
  const traits = {
    stepId: stepName,
    businessCategory: businessCategorySlug,
    archetypeId: archetype,
  };

  const onBlankTemplateClick = (e) => {
    e.preventDefault();

    trackSitesetupStartWithBlankTemplateSelected(traits);

    updateData({ [AI_SITE_WIZARD_SITE_PROP]: aiSiteWizardSitePropValues.SKIPPED });
    onTemplateSelect(BLANK_TEMPLATE_BUILD_SLUG, triggerIds.BLANK_TEMPLATE);
  };

  const onCardClick = (id) => {
    if (id === cardTypes.AI_GENERATE) {
      trackSitesetupAiGenerateWebsiteSelected(traits);

      let templateSlug;

      if (templates.length) {
        const randomIndex = Math.floor(Math.random() * templates.length);
        templateSlug = templates[randomIndex];
      } else {
        templateSlug = DEFAULT_TEMPLATE_BUILD_SLUG;
      }

      updateData({ [AI_SITE_WIZARD_SITE_PROP]: aiSiteWizardSitePropValues.INITIATED });
      onTemplateSelect(templateSlug, triggerIds.AI_SITE);
      return;
    }

    if (id === cardTypes.START_WITH_TEMPLATE) {
      trackSitesetupStartWithATemplateSelected(traits);
      updateData({ [AI_SITE_WIZARD_SITE_PROP]: aiSiteWizardSitePropValues.ENABLED });
      history.push(
        routesMap.gallery.url({ category: getGalleryCategory(contextData.archetype) || 'all' })
      );
    }
  };

  return (
    <HowToBuildStep
      captions={captions}
      currentStep={currentStep}
      cards={cards}
      isLegacySitebuilderAvailable={b2cFullAccess}
      legacySitebuilderUrl={legacySitebuilderUrl}
      isBlankTemplateAvailable={Boolean(isBlankTemplateAvailable)}
      onCardClick={onCardClick}
      onBlankTemplateClick={onBlankTemplateClick}
    />
  );
};

HowToBuildStepContainer.propTypes = {
  b2cFullAccess: PropTypes.bool.isRequired,
};

export default HowToBuildStepContainer;
