function injectStyles(document, styles = '', stylesId = '') {
  let styleElement = stylesId ? document.querySelector(`#${stylesId}`) : '';

  if (styleElement) {
    styleElement.innerHTML = styles;
    return;
  }

  styleElement = document.createElement('style');

  if (stylesId) {
    styleElement.setAttribute('id', stylesId);
  }

  styleElement.innerHTML = styles;

  document.head.appendChild(styleElement);
}

export default injectStyles;
