import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash.get';
import siteSetup from 'src/js/modules/site-setup';
import trackers from 'src/js/modules/analytics/segment/trackers';
import useFeatureFlags from 'src/js/modules/feature-flags/hooks/use-feature-flags';
import { SiteSetupContext } from 'src/js/modules/site-setup/helpers/site-setup-context';
import { AI_SITE_WIZARD_FLAG } from 'src/js/constants/common';
import { redirectToUrl } from '../utils/redirect-to-url';
import isBackPlatformAvailable from '../utils/is-back-platform-available';
import getSiteIdToSwitchTemplate from '../modules/sites/selectors/site-id-to-switch-template';
import getYolaEditorUrl from '../modules/config/selectors/yola-editor-url';
import Header from '../components/header';
import getCalculatedSiteSetupStepsMap from '../modules/site-setup/helpers/get-calculated-site-setup-steps-map';

const { helpers, constants } = siteSetup;
const { siteSetupNames } = constants;
const { ARCHETYPES } = siteSetupNames;
const { trackSiteSetupBackButtonClicked } = trackers;

const HeaderGalleryPageContainer = ({ match, history, onSkip }) => {
  const siteIdToSwitchTemplate = useSelector(getSiteIdToSwitchTemplate);
  const context = useContext(SiteSetupContext);
  const { getArchetype, getData } = context;
  const archetype = getArchetype();
  const contextData = getData();
  const { number: current, stepName } = useMemo(helpers.getCurrentStep, []);
  const [featureFlags] = useFeatureFlags([AI_SITE_WIZARD_FLAG]);
  const { [AI_SITE_WIZARD_FLAG]: isAISiteWizardEnabled } = featureFlags;
  const total = useMemo(() => helpers.getTotalSteps(archetype, isAISiteWizardEnabled), [
    archetype,
    isAISiteWizardEnabled,
  ]);

  const editorUrl = useSelector(getYolaEditorUrl);
  const pathName = get(match, 'url', '');
  const isTemplateRoute = helpers.isTemplateRoute(pathName);
  const siteSetupStep = !isTemplateRoute ? { current, total } : null;
  const siteSetupStepsMap = getCalculatedSiteSetupStepsMap();
  const isBackPlatformEnabled =
    siteSetupStepsMap[ARCHETYPES] === current && isBackPlatformAvailable();

  let onBack = null;

  if (siteSetupStep && siteSetupStepsMap[ARCHETYPES] !== current) {
    onBack = () => {
      trackSiteSetupBackButtonClicked({
        stepId: stepName,
        archetypeId: archetype,
        businessCategory: contextData.businessCategorySlug,
      });

      history.goBack();
    };
  } else if (siteSetupStep && isBackPlatformEnabled) {
    onBack = () => history.goBack();
  }

  if (!siteIdToSwitchTemplate) {
    return (
      <Header
        preloader={siteSetupStep}
        isBackPlatformEnabled={isBackPlatformEnabled}
        onBackButtonClick={onBack}
        onSkip={onSkip}
      />
    );
  }

  return <Header onBackButtonClick={() => redirectToUrl(editorUrl)} />;
};

HeaderGalleryPageContainer.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  onSkip: PropTypes.func,
};

HeaderGalleryPageContainer.defaultProps = {
  onSkip: null,
};

export default withRouter(HeaderGalleryPageContainer);
