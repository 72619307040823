import trackAsync from '../helpers/track-async';
import eventTypes from '../constants/event-types';
import { APP_NAME } from '../../../../constants/common';
import track from '../helpers/track';

const trackUseTemplateButtonClicked = (
  { siteId, templateBuildSlug, selectedTemplateCategory, businessCategory = null, triggerId },
  isAsync = true
) => {
  const handler = isAsync ? trackAsync : track;

  return handler(eventTypes.USE_TEMPLATE_BUTTON_CLICKED, {
    appName: APP_NAME,
    siteId,
    templateBuildSlug,
    selectedTemplateCategory,
    businessCategory,
    triggerId,
  });
};

export default trackUseTemplateButtonClicked;
