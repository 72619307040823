import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import analytics from 'src/js/modules/analytics';
import config from 'src/js/modules/config';

const TagManagerContainer = ({ location, children }) => {
  const dataLayerName = useSelector(config.selectors.getGoogleTagManagerDataLayerName);
  const { pathname } = location;

  useEffect(() => {
    analytics.tagManager.setDataLayer(dataLayerName, pathname);
  }, [pathname, dataLayerName]);

  return children;
};

TagManagerContainer.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.node,
};

export default withRouter(TagManagerContainer);
