import getSites from './get-sites';
import getSite from './get-site';
import getSiteIdRecreate from './get-site-id-recreate';
import getSitesCount from './get-sites-count';
import getSitesLimit from './get-sites-limit';
import getSiteIdToSwitchTemplate from './site-id-to-switch-template';
import getTemplateBuildSlug from './get-template-build-slug';

export default {
  getSite,
  getSites,
  getSiteIdRecreate,
  getSitesCount,
  getSitesLimit,
  getSiteIdToSwitchTemplate,
  getTemplateBuildSlug,
};
