import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { redirectToUrl } from 'src/js/utils/redirect-to-url';
import configs from 'src/js/modules/config';
import i18n from 'src/js/modules/i18n';
import partner from 'src/js/modules/partner';
import subscriptions from 'src/js/modules/subscriptions';
import dialogs from '../index';
import SiteLimitDialog from '../../../components/modals/site-limit';
import trackAsync from '../../analytics/segment/helpers/track-async';
import eventTypes from '../../analytics/segment/constants/event-types';
import purchaseFlowTypes from '../constants/purchase-flow-types';
import { APP_NAME, PRODUCT_CATEGORY, premiumFeatures } from '../../../constants/common';
import track from '../../analytics/segment/helpers/track';

function SiteLimitDialogContainer({ templateSlug, backUrl }) {
  const currentPackageName = useSelector(subscriptions.selectors.getCurrentPackageName);
  const limit = useSelector(subscriptions.selectors.getCurrentPackageSiteLimit);
  const platformComponents = useSelector(partner.selectors.getAvailablePlatformComponents);
  const dashboardAllowed = platformComponents.includes(partner.platformComponents.DASHBOARD);
  const myyolaLink = useSelector(configs.selectors.getMyyolaUrl);
  const yolacomLink = useSelector(configs.selectors.getYolacomUrl);
  const upgradeUrl = useSelector(configs.selectors.getUpgradeUrl);
  const locale = useSelector(i18n.selectors.getLocale);
  const activeSubscriptions = useSelector(subscriptions.selectors.getActive, shallowEqual);
  const availableSubscriptionTypes = useSelector(
    partner.selectors.getAvailableSubscriptionTypes,
    shallowEqual
  );
  const isPremium = subscriptions.verifiers.hasPremiumSubscription(
    activeSubscriptions,
    availableSubscriptionTypes
  );

  const dispatch = useDispatch();
  const onClose = useCallback(() => {
    dispatch(dialogs.actions.hide());
  }, [dispatch]);

  useEffect(() => {
    track(eventTypes.UPGRADE_INTERRUPTION_DISPLAYED, {
      appName: APP_NAME,
      templateBuildSlug: templateSlug,
      premiumFeature: premiumFeatures.SITES_COUNT,
      productCategory: PRODUCT_CATEGORY,
      purchaseFlowType: purchaseFlowTypes.B2C,
      siteId: null,
    });
  }, [templateSlug]);

  const onUpgrade = useCallback(async () => {
    const upgradeURLWithParams = new URL(upgradeUrl);

    if (backUrl) {
      upgradeURLWithParams.searchParams.append('back', `${window.location.origin}${backUrl}`);
    }

    const upgradelink = upgradeURLWithParams.toString();

    await trackAsync(eventTypes.UPGRADE_INITIATED, {
      appName: APP_NAME,
      templateBuildSlug: templateSlug,
      premiumFeature: premiumFeatures.SITES_COUNT,
      productCategory: PRODUCT_CATEGORY,
      purchaseFlowType: purchaseFlowTypes.B2C,
      siteId: null,
    });

    redirectToUrl(upgradelink);
  }, [yolacomLink, locale, templateSlug]);

  return (
    <SiteLimitDialog
      onClose={onClose}
      onUpgrade={onUpgrade}
      limit={limit}
      myyolaLink={myyolaLink}
      isPremium={isPremium}
      currentPackageName={currentPackageName}
      dashboardAllowed={dashboardAllowed}
    />
  );
}

SiteLimitDialogContainer.propTypes = {
  templateSlug: PropTypes.string.isRequired,
  backUrl: PropTypes.string,
};

SiteLimitDialogContainer.defaultProps = {
  backUrl: '',
};

export default SiteLimitDialogContainer;
