import { APP_NAME } from 'src/js/constants/common';
import getSiteIdRecreate from 'src/js/modules/sites/accessors/get-site-id-recreate';
import track from '../helpers/track';
import eventTypes from '../constants/event-types';

const trackSiteSetupUserArchetypeClicked = ({ archetypeId }) =>
  track(eventTypes.SITESETUP_USER_ACHETYPE_CLICKED, {
    appName: APP_NAME,
    siteId: getSiteIdRecreate() || null,
    archetypeId,
  });

export default trackSiteSetupUserArchetypeClicked;
