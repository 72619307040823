import actionTypes from '../../partner/constants/action-types';
import injectBrandingColors from '../helpers/inject-branding-colors';

const colorsInjector = () => (next) => (action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SET_PARTNER_DATA: {
      const { wsBranding } = payload.partnerData.properties;
      const colors = wsBranding && wsBranding.colors ? wsBranding.colors : {};

      injectBrandingColors(colors);

      next(action);
      break;
    }

    default:
      next(action);
  }
};

export default colorsInjector;
