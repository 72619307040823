const HTTPS_PROTOCOL = 'https:';

const normalizeUrl = (url) => {
  if (url.length === 0) return url;

  const lowercaseUrl = url.toLowerCase();

  const httpProtocolRegExp = new RegExp('^https?://', 'i');
  if (httpProtocolRegExp.test(lowercaseUrl)) {
    const urlInstance = new URL(lowercaseUrl);
    return url.replace(httpProtocolRegExp, `${urlInstance.protocol}//`);
  }

  if (lowercaseUrl.startsWith('//')) return `${HTTPS_PROTOCOL}${url}`;

  return `${HTTPS_PROTOCOL}//${url}`;
};

export default normalizeUrl;
