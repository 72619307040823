import get from 'lodash.get';
import { t } from 'i18next';
import yowsClient from 'src/js/modules/service-client/yows-client';
import partner from 'src/js/modules/partner';
import sites from 'src/js/modules/sites';
import dialogs from 'src/js/modules/dialogs';

import { redirectToUrl } from 'src/js/utils/redirect-to-url';
import getYolaEditorUrlForSite from 'src/js/utils/get-yola-editor-url-for-site';

import config from 'src/js/modules/config';
import SiteSetupStorage from 'src/js/modules/site-setup/helpers/site-setup-storage';
import trackSiteSetupCompleted from 'src/js/modules/analytics/segment/trackers/site-setup-completed';
import actionTypes from '../constants/action-types';
import showSpinner from '../../spinner/actions/show-spinner';
import hideSpinner from '../../spinner/actions/hide-spinner';
import shouldSiteRecreate from '../helpers/should-site-recreate';

const SITE_LIMIT_REACHED = 'site_limit_reached';

const createSiteByTemplate = (store) => (next) => (action) => {
  switch (action.type) {
    case actionTypes.CREATE_SITE: {
      const state = store.getState();
      const { templateSlug } = action.payload;
      const multisiteMode = partner.selectors.getMultisiteMode(state);

      store.dispatch(showSpinner(t('Loading...')));

      const userSites = sites.selectors.getSites(state);
      const hasWebsites = userSites.count > 0;
      const siteIdRecreate = sites.selectors.getSiteIdRecreate(state);
      const isSiteShouldRecreate = shouldSiteRecreate(multisiteMode, siteIdRecreate, userSites);
      const yolaEditorUrl = config.selectors.getYolaEditorUrl(state);
      const siteIdToSwitchTemplate = sites.selectors.getSiteIdToSwitchTemplate(state);
      const siteToSwitchTemplate = sites.selectors.getSite(siteIdToSwitchTemplate, state);

      const isSiteOperation = isSiteShouldRecreate || siteIdToSwitchTemplate;
      let siteCreator;

      if (!multisiteMode && !isSiteOperation && hasWebsites) {
        redirectToUrl(yolaEditorUrl);
        break;
      }

      if (siteIdToSwitchTemplate) {
        siteCreator = yowsClient.get().switchTemplate({
          ...action.payload,
          siteId: siteIdToSwitchTemplate,
        });
      } else if (isSiteShouldRecreate) {
        siteCreator = yowsClient.get().recreateSite({
          ...action.payload,
          siteId: siteIdRecreate || userSites.results[0].id,
        });
      } else {
        siteCreator = yowsClient.get().createSite(action.payload);
      }

      siteCreator
        .then(async (response) => {
          const siteId = get(response, 'data.id', null);
          const isTemplateSwitched = siteIdToSwitchTemplate && siteToSwitchTemplate;

          if (!isTemplateSwitched) {
            const { archetype, hours, ...other } = SiteSetupStorage.getData();
            await trackSiteSetupCompleted({
              archetypeId: archetype,
              workingHours: hours,
              ...other,
            });
          }

          SiteSetupStorage.clear();

          next(action);
          redirectToUrl(
            getYolaEditorUrlForSite(yolaEditorUrl, siteId, Boolean(isTemplateSwitched))
          );
        })
        .catch((error) => {
          const code = get(error, 'response.data.code', null);
          if (code === SITE_LIMIT_REACHED) {
            if (multisiteMode) {
              store.dispatch(hideSpinner());
              store.dispatch(
                dialogs.actions.show(dialogs.dialogTypes.SITE_LIMIT, {
                  templateSlug,
                  backUrl: window.location.href,
                })
              );
            } else {
              redirectToUrl(yolaEditorUrl);
            }
          } else {
            store.dispatch(hideSpinner());
          }

          console.error(error);
          next(action);
        });
      break;
    }
    default:
      next(action);
  }
};

export default createSiteByTemplate;
