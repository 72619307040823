import config from 'src/js/modules/config';
import traqing from '@yola/traqing.js';

const segmentLoader = () => (next) => (action) => {
  switch (action.type) {
    case config.actionTypes.SET_SERVICE_CONFIG: {
      const configData = {
        config: {
          service: { ...action.payload.configData },
        },
      };
      const key = config.selectors.getSegmentIOKey(configData);
      const publicApiUrl = config.selectors.getApiUrl(configData);

      if (!key) {
        next(action);
        return;
      }

      traqing.segment.init(key, publicApiUrl);
      traqing.segment.useIntegration(traqing.segment.integrations.GOOGLE_CLIENT_ID);
      traqing.segment.useIntegration(traqing.segment.integrations.YOLA_COOKIES, {
        names: ['Yola'],
      });

      next(action);
      break;
    }

    default: {
      next(action);
    }
  }
};

export default segmentLoader;
