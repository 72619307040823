export default {
  APP_OPENED: 'APP_OPENED',
  ERROR_CAUGHT: 'ERROR_CAUGHT',
  UPGRADE_INITIATED: 'UPGRADE_INITIATED',
  UPGRADE_INTERRUPTION_DISPLAYED: 'UPGRADE_INTERRUPTION_DISPLAYED',
  PLATFORM_SELECTOR_DISPLAYED: 'PLATFORM_SELECTOR_DISPLAYED',
  PLATFORM_SELECTED: 'PLATFORM_SELECTED',
  NETWORK_STATUS_CHANGED: 'NETWORK_STATUS_CHANGED',
  CATEGORY_SELECTED: 'CATEGORY_SELECTED',
  LIVE_PREVIEW_DEVICE_CLICKED: 'LIVE_PREVIEW_DEVICE_CLICKED',
  LIVE_PREVIEW_BACK_CLICKED: 'LIVE_PREVIEW_BACK_CLICKED',
  USE_TEMPLATE_BUTTON_CLICKED: 'USE_TEMPLATE_BUTTON_CLICKED',
  HELP_DISPLAYED: 'HELP_DISPLAYED',
  SITESETUP_USER_ACHETYPE_CLICKED: 'SITESETUP_USER_ACHETYPE_CLICKED',
  SITESETUP_USER_ACHETYPE_PROVIDED: 'SITESETUP_USER_ACHETYPE_PROVIDED',
  SITESETUP_STARTED: 'SITESETUP_STARTED',
  SITESETUP_SKIP_BUTTON_CLICKED: 'SITESETUP_SKIP_BUTTON_CLICKED',
  SITESETUP_BACK_BUTTON_CLICKED: 'SITESETUP_BACK_BUTTON_CLICKED',
  SITESETUP_SITE_NAME_PROVIDED: 'SITESETUP_SITE_NAME_PROVIDED',
  SITESETUP_BUSINESS_NAME_AND_INTRODUCTION_PROVIDED:
    'SITESETUP_BUSINESS_NAME_AND_INTRODUCTION_PROVIDED',
  SITESETUP_BUSINESS_DETAILS_PROVIDED: 'SITESETUP_BUSINESS_DETAILS_PROVIDED',
  SITESETUP_COMPLETED: 'SITESETUP_COMPLETED',
  SITESETUP_BUSINESS_CATEGORY_PROVIDED: 'SITESETUP_BUSINESS_CATEGORY_PROVIDED',
  SITESETUP_START_WITH_BLANK_TEMPLATE_SELECTED: 'SITESETUP_START_WITH_BLANK_TEMPLATE_SELECTED',
  EDITING_CLICKED: 'EDITING_CLICKED',
  PREVIEW_CLICKED: 'PREVIEW_CLICKED',
  BUSINESS_CATEGORY_INPUT_FOCUSED: 'BUSINESS_CATEGORY_INPUT_FOCUSED',
  BUSINESS_CATEGORY_SELECTED: 'BUSINESS_CATEGORY_SELECTED',
  SITESETUP_START_WITH_A_TEMPLATED_SELECTED: 'SITESETUP_START_WITH_A_TEMPLATED_SELECTED',
  SITESETUP_AI_GENERATE_WEBSITE_SELECTED: 'SITESETUP_AI_GENERATE_WEBSITE_SELECTED',
};
