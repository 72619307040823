const proxyClickToParentContext = (event) => {
  const { frameElement } = event.target.ownerDocument.defaultView;

  const clickEvent = new MouseEvent('click', {
    bubbles: true,
    cancelable: true,
  });

  frameElement.dispatchEvent(clickEvent);
};

export default proxyClickToParentContext;
