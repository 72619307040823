import { APP_NAME } from 'src/js/constants/common';
import archetypesMap from 'src/js/modules/site-setup/constants/archetypes-map';
import getSiteIdRecreate from 'src/js/modules/sites/accessors/get-site-id-recreate';
import trackAsync from '../helpers/track-async';
import eventTypes from '../constants/event-types';

const { PERSONAL } = archetypesMap;

const trackSiteSetupCompleted = ({
  archetypeId,
  siteName,
  businessName,
  businessDescription,
  address,
  phone,
  email,
  workingHours,
  socialLinks,
  locale,
  businessCategorySlug = null,
}) => {
  const traits = {
    appName: APP_NAME,
    siteId: getSiteIdRecreate() || null,
    archetypeId,
    siteName,
    languageCode: locale,
    businessCategory: businessCategorySlug,
    businessName: null,
    businessDescription: null,
    addressAdded: null,
    phoneAdded: null,
    emailAdded: null,
    workingHours: null,
    socialLinks: null,
  };

  if (archetypeId !== undefined) {
    traits.businessName = businessName || null;
    traits.businessDescription = businessDescription || null;

    if (archetypeId !== PERSONAL) {
      traits.addressAdded = Boolean(address?.length);
      traits.phoneAdded = Boolean(phone?.length);
      traits.emailAdded = Boolean(email?.length);
      traits.workingHours = Boolean(workingHours?.length);
      traits.socialLinks = Boolean(socialLinks) && socialLinks.some((link) => Boolean(link.length));
    }
  }

  return trackAsync(eventTypes.SITESETUP_COMPLETED, traits);
};

export default trackSiteSetupCompleted;
