import classNames from 'classnames';
import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import LivePreview from '../components/live-preview';
import calculateViewportSize from '../helpers/calculate-viewport-size';
import { DESKTOP, TABLET, MOBILE } from '../constants/index';
import adaptSizeForViewport from '../helpers/adapt-size-for-viewport';
import getScale from '../helpers/get-scale';
import useUrl from '../helpers/use-url';
import getPreviewModeForDevice from '../helpers/get-preview-mode-for-device';
import IframeFixWrapper from '../components/iframe-fix-wrapper';

function LivePreviewContainer(props) {
  const { className, mode, url, ...restOfProps } = props;
  const [size, setSize] = useState(null);
  const [scale, setScale] = useState(1);
  const nodeElement = useRef(null);
  const htmlContent = useUrl(url);

  useEffect(() => {
    const calculateViewport = () => {
      const realViewMode = getPreviewModeForDevice();
      const calculatedSize = calculateViewportSize(nodeElement.current, realViewMode, mode);
      const adaptedSize = adaptSizeForViewport(nodeElement.current, calculatedSize);
      setScale(getScale(nodeElement.current, adaptedSize));
      setSize(adaptedSize);
    };

    calculateViewport();
    const {
      ownerDocument: { defaultView },
    } = nodeElement.current;
    defaultView.addEventListener('resize', calculateViewport);
    return () => {
      defaultView.removeEventListener('resize', calculateViewport);
    };
  }, [mode]);

  const realViewMode = getPreviewModeForDevice();

  const componentClass = classNames('live-preview-container', className, {
    'live-preview-container--mobile': mode === MOBILE,
    'live-preview-container--tablet': mode === TABLET,
    'live-preview-container--desktop': mode === DESKTOP,
    'live-preview-container--real-view-tablet': realViewMode === TABLET,
  });

  return (
    <div className={componentClass} ref={nodeElement} data-mode={mode}>
      <div
        style={{
          transform: `scale(${scale})`,
        }}
        className="live-preview-viewport"
      >
        {size && (
          <IframeFixWrapper size={size}>
            <LivePreview {...restOfProps} content={htmlContent} size={size} isolate={false} />
          </IframeFixWrapper>
        )}
      </div>
    </div>
  );
}

LivePreviewContainer.propTypes = {
  mode: PropTypes.oneOf([DESKTOP, TABLET, MOBILE]),
  scrollTopAfterSizeUpdate: PropTypes.bool,
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  onLoad: PropTypes.func,
  className: PropTypes.string,
};
LivePreviewContainer.defaultProps = {
  mode: 'desktop',
  onLoad: Function.prototype,
  className: '',
  scrollTopAfterSizeUpdate: false,
};

export default LivePreviewContainer;
