import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { t } from 'i18next';
import FilterTemplatesDialog from 'src/js/components/modals/filter-templates';
import routesMap from 'src/js/router/helpers/routes-map';
import dialogs from '..';

const getCaptions = () => ({
  title: t('Filter templates'),
  radioButtonGroupLabel: t('Sort by archetype '),
  submitButtonLabel: t('Apply'),
});

const FilterTemplatesDialogContainer = ({ activeCategory, categories, onSubmit }) => {
  const [selectedCategory, setCategory] = useState(activeCategory);
  const history = useHistory();
  const dispatch = useDispatch();

  const captions = getCaptions();

  const closeDialog = () => {
    dispatch(dialogs.actions.hide());
  };

  const handleSubmit = () => {
    history.push(routesMap.gallery.url({ category: selectedCategory }));
    onSubmit(selectedCategory);
    closeDialog();
  };

  return (
    <FilterTemplatesDialog
      captions={captions}
      categories={categories}
      selectedCategory={selectedCategory}
      onCategorySelect={setCategory}
      onSubmit={handleSubmit}
      onClose={closeDialog}
    />
  );
};

FilterTemplatesDialogContainer.propTypes = {
  activeCategory: PropTypes.string,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    })
  ).isRequired,
  onSubmit: PropTypes.func,
};

FilterTemplatesDialogContainer.defaultProps = {
  activeCategory: '',
  onSubmit: Function.prototype,
};

export default FilterTemplatesDialogContainer;
