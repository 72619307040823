import PropTypes from 'prop-types';
import React, { useRef, useState, useCallback } from 'react';
import { Spinner } from '@yola/ws-ui';
import classNames from 'classnames';
import bowser from 'yola-bowser';
import { useSpring, animated } from 'react-spring';
import LivePreviewContainer from '../../modules/live-preview/containers/live-preview-container';
import setDesktopAnimation from './helpers/set-desktop-animation';
import setMobileAnimation from './helpers/set-mobile-animation';
import easeInOut from './helpers/ease-In-out';
import { ANIMATION_DURATION } from './constants/index';

function LivePreviewSlider({ toMode, templateSlug, url }) {
  const fromMode = useRef(null);
  const firstSlide = useRef(null);
  const secondSlide = useRef(null);
  const [isLoaded, setLoadedStatus] = useState(false);
  const setPreviewIsLoaded = useCallback(() => setLoadedStatus(true), []);

  const isFirstSlideActive = useRef(false);

  const [firstSlideStyles, setFirstSlideStyles] = useSpring(() => ({
    transform: 'translateX(0%)',
    zIndex: bowser.mobile ? 1 : 'unset',
    opacity: 0,
    config: {
      duration: ANIMATION_DURATION,
      easing: easeInOut,
    },
  }));
  const [secondSlideStyles, setSecondSlideStyles] = useSpring(() => ({
    transform: bowser.mobile ? 'translateX(0%)' : 'translateX(-100%)',
    zIndex: bowser.mobile ? -1 : 'unset',
    opacity: 0,
    config: {
      duration: ANIMATION_DURATION,
      easing: easeInOut,
    },
  }));

  const onAnimationEnd = () => {
    if (isLoaded) {
      isFirstSlideActive.current = !isFirstSlideActive.current;
      fromMode.current = toMode;
    }
  };

  if (isLoaded && bowser.mobile) {
    setMobileAnimation(
      !isFirstSlideActive.current,
      setFirstSlideStyles,
      setSecondSlideStyles,
      onAnimationEnd
    );
  } else if (isLoaded && !bowser.mobile) {
    setDesktopAnimation(
      !isFirstSlideActive.current,
      setFirstSlideStyles,
      setSecondSlideStyles,
      onAnimationEnd
    );
  }

  const componentClass = classNames('live-preview-slider', null, {
    'live-preview-slider--mobile': bowser.mobile,
    'live-preview-slider--is-loaded': isLoaded,
  });

  return (
    <React.Fragment>
      {!isLoaded && (
        <div className="live-preview__loader">
          <Spinner containerPosition="center" spinnerPosition="center" />
        </div>
      )}
      <div className={componentClass}>
        <animated.div style={firstSlideStyles} className="live-preview-slider__animated">
          <div ref={firstSlide} className="live-preview-slider__slide">
            <LivePreviewContainer
              onLoad={setPreviewIsLoaded}
              name={templateSlug}
              url={url}
              mode={!isFirstSlideActive.current ? toMode : fromMode.current}
              preventLinkFollowing
              scrollTopAfterSizeUpdate
            />
          </div>
        </animated.div>

        <animated.div style={secondSlideStyles} className="live-preview-slider__animated">
          <div ref={secondSlide} className="live-preview-slider__slide">
            <LivePreviewContainer
              name={templateSlug}
              url={url}
              mode={isFirstSlideActive.current ? toMode : fromMode.current}
              preventLinkFollowing
              scrollTopAfterSizeUpdate
            />
          </div>
        </animated.div>
      </div>
    </React.Fragment>
  );
}

LivePreviewSlider.propTypes = {
  toMode: PropTypes.string.isRequired,
  templateSlug: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default React.memo(LivePreviewSlider);
