import actionTypes from '../constants/action-types';

const showSpinner = (text = '') => ({
  type: actionTypes.SHOW_SPINNER,
  payload: {
    text,
    spinnerIsShown: true,
  },
});

export default showSpinner;
