import React, { forwardRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';
import { t } from 'i18next';
import utils from '../../utils';
import CategoryLink from './category-link';
import BlankTemplateActionButton from './blank-template-action-button';

const { Container, ActionButton } = designSystem;
const { useMatchBreakpoint } = utils.hooks;

const DESKTOP_VIEW_BREAKPOINT = '1200px';

const CategoriesBar = forwardRef((props, ref) => {
  const {
    categories,
    activeCategory,
    isBlankTemplateEnabled,
    onCategorySelect,
    onBlankTemplateClick,
    onFilterTemplateClick,
  } = props;
  const isDesktopView = useMatchBreakpoint(DESKTOP_VIEW_BREAKPOINT);

  return (
    <Fragment>
      <div className="categories-bar" ref={ref}>
        <div className="categories-bar__wrapper">
          <Container>
            <div className="categories-bar__content">
              {isDesktopView && (
                <Fragment>
                  {categories.map(({ id, title }) => (
                    <CategoryLink
                      key={id}
                      id={id}
                      title={title}
                      isActive={id === activeCategory}
                      onClick={onCategorySelect}
                    />
                  ))}
                  {isBlankTemplateEnabled && (
                    <div className="categories-bar__action-btn">
                      <BlankTemplateActionButton onClick={onBlankTemplateClick} />
                    </div>
                  )}
                </Fragment>
              )}
              {!isDesktopView && (
                <Fragment>
                  <ActionButton
                    fullWidth={isBlankTemplateEnabled}
                    format="text"
                    iconGlyph="filter"
                    label={t('Filter templates')}
                    onClick={onFilterTemplateClick}
                  />
                  {isBlankTemplateEnabled && (
                    <Fragment>
                      <span className="categories-bar__vertical-divider" />
                      <BlankTemplateActionButton fullWidth onClick={onBlankTemplateClick} />
                    </Fragment>
                  )}
                </Fragment>
              )}
            </div>
          </Container>
        </div>
      </div>
    </Fragment>
  );
});

CategoriesBar.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    })
  ).isRequired,
  activeCategory: PropTypes.string,
  isBlankTemplateEnabled: PropTypes.bool,
  onCategorySelect: PropTypes.func,
  onBlankTemplateClick: PropTypes.func,
  onFilterTemplateClick: PropTypes.func,
};

CategoriesBar.defaultProps = {
  activeCategory: '',
  isBlankTemplateEnabled: false,
  onCategorySelect: Function.prototype,
  onBlankTemplateClick: Function.prototype,
  onFilterTemplateClick: Function.prototype,
};

export default CategoriesBar;
