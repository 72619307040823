import track from '../helpers/track';
import eventTypes from '../constants/event-types';
import { APP_NAME } from '../../../../constants/common';

const trackLivePreviewBackClicked = ({ siteId, templateBuildSlug }) => {
  track(eventTypes.LIVE_PREVIEW_BACK_CLICKED, {
    appName: APP_NAME,
    siteId,
    templateBuildSlug,
  });
};

export default trackLivePreviewBackClicked;
