import React, { useCallback, useState, useMemo, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { t } from 'i18next';
import trackers from 'src/js/modules/analytics/segment/trackers';
import siteSetup from 'src/js/modules/site-setup';
import siteSetupComp from 'src/js/components/site-setup';
import routesMap from 'src/js/router/helpers/routes-map';
import { SiteSetupContext } from 'src/js/modules/site-setup/helpers/site-setup-context';
import trackSiteSetupUserArchetypeProvided from 'src/js/modules/analytics/segment/trackers/site-setup-user-archetype-provided';
import categories from 'src/js/modules/categories';
import partner from 'src/js/modules/partner';
import subscriptions from 'src/js/modules/subscriptions';
import { shallowEqual, useSelector } from 'react-redux';
import useFeatureFlags from 'src/js/modules/feature-flags/hooks/use-feature-flags';
import {
  AI_SITE_WIZARD_FLAG,
  AI_PAGE_WIZARD_FLAG,
  BUSINESS_TAXONOMY_FLAG,
} from 'src/js/constants/common';
import getGalleryCategory from '../../helpers/get-gallery-category';

const { trackSiteSetupUserArchetypeClicked, trackSiteSetupSkipButtonClicked } = trackers;
const {
  constants: { archetypesMap, siteSetupNames },
} = siteSetup;
const { ArchetypesStep } = siteSetupComp;
const { PERSONAL, BUSINESS, ONLINE_STORE, NON_PROFIT } = archetypesMap;

const getCaptions = () => ({
  title: t('What kind of website do you want to create?'),
  subtitle: t('This will help us guide your path.'),
  buttons: {
    next: t('Continue'),
  },
});

const getItemsData = (hasEcommerceSubscription) => [
  {
    icon: 'avatar',
    title: t('Personal'),
    description: t('For blogs, portfolios, CVs, musicians, school projects, hobbies or artists'),
    value: PERSONAL,
  },
  {
    icon: 'briefcase',
    title: t('Business'),
    description: t('For entrepreneurs, startups, new and established businesses or enterprises'),
    value: BUSINESS,
  },
  ...(hasEcommerceSubscription
    ? [
        {
          icon: 'store',
          title: t('Online Store'),
          description: t(
            'For shops, retailers, entrepreneurs and businesses that sell products online'
          ),
          value: ONLINE_STORE,
        },
      ]
    : []),
  {
    icon: 'social',
    title: t('Community / Organization'),
    description: t('For non-profits, churches, clubs, governments or associations'),
    value: NON_PROFIT,
  },
];

function ArchetypesStepContainer() {
  const history = useHistory();
  const availableSubscriptionTypes = useSelector(
    partner.selectors.getAvailableSubscriptionTypes,
    shallowEqual
  );
  const context = useContext(SiteSetupContext);
  const { updateData, getData } = context;
  const { archetype, useTemplateFlow, businessCategorySlug } = getData();

  const initialArchetype = archetype || null;
  const [selectedArchetype, selectArchetype] = useState(initialArchetype);
  const captions = useMemo(() => getCaptions(), []);
  const hasEcommerceSubscription = useMemo(
    () => subscriptions.verifiers.hasEcommerceSubscription(availableSubscriptionTypes),
    [availableSubscriptionTypes]
  );

  // added to prefetch feature flags for the next step to avoid white screen loading
  const [featureFlags] = useFeatureFlags([
    AI_PAGE_WIZARD_FLAG,
    AI_SITE_WIZARD_FLAG,
    BUSINESS_TAXONOMY_FLAG,
  ]);
  const { [BUSINESS_TAXONOMY_FLAG]: isBusinessTaxonomyEnabled } = featureFlags;

  if (isBusinessTaxonomyEnabled) {
    history.replace(routesMap.siteCategory.url());
  }

  const itemsData = useMemo(
    () =>
      getItemsData(hasEcommerceSubscription).map((item) =>
        item.value === selectedArchetype ? { ...item, active: true } : item
      ),
    [selectedArchetype, hasEcommerceSubscription]
  );

  const onCardClick = useCallback((value) => {
    selectArchetype(value);
    trackSiteSetupUserArchetypeClicked({
      archetypeId: value,
    });
  }, []);

  const onNext = useCallback(() => {
    updateData({ archetype: selectedArchetype });
    trackSiteSetupUserArchetypeProvided({
      archetypeId: selectedArchetype,
    });
    if (useTemplateFlow) {
      history.push(routesMap.siteName.url());
    } else {
      history.push(routesMap.gallery.url({ category: getGalleryCategory(selectedArchetype) }));
    }
  }, [selectedArchetype, updateData, history, getGalleryCategory]);

  const onSkip = useCallback(() => {
    const stepId = siteSetupNames.ARCHETYPES;
    updateData({ archetype: null });
    trackSiteSetupSkipButtonClicked({
      stepId,
      archetypeId: null,
      businessCategory: businessCategorySlug,
    });
    history.push(routesMap.gallery.url({ category: categories.categoryIds.ALL }));
  }, [history, updateData]);

  return (
    <ArchetypesStep
      captions={captions}
      items={itemsData}
      onCardClick={onCardClick}
      onSkip={onSkip}
      next={{
        callback: onNext,
        disabled: !selectedArchetype,
      }}
    />
  );
}

export default ArchetypesStepContainer;
