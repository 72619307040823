const setDesktopAnimation = (
  isFirstSlideActive = true,
  setFirstSlideStyles = Function.prototype,
  setSecondSlideStyles = Function.prototype,
  onAnimationEnd = Function.prototype
) => {
  if (isFirstSlideActive) {
    setFirstSlideStyles({
      from: {
        transform: 'translateX(100%)',
        opacity: 1,
      },
      to: {
        transform: 'translateX(0%)',
        opacity: 1,
      },
      immediate: false,
    });
    setSecondSlideStyles({
      from: {
        transform: 'translateX(0%)',
        opacity: 1,
      },
      to: {
        transform: 'translateX(-100%)',
        opacity: 1,
      },
      immediate: false,
      onRest: () => {
        setSecondSlideStyles({
          from: {
            transform: 'translateX(-100%)',
          },
          to: {
            transform: 'translateX(100%)',
          },
          immediate: true,
        });
        onAnimationEnd();
      },
    });
  } else {
    setFirstSlideStyles({
      from: {
        transform: 'translateX(0%)',
        opacity: 1,
      },
      to: {
        transform: 'translateX(-100%)',
        opacity: 1,
      },
      immediate: false,
    });
    setSecondSlideStyles({
      from: {
        transform: 'translateX(100%)',
        opacity: 1,
      },
      to: {
        transform: 'translateX(0%)',
        opacity: 1,
      },
      immediate: false,
      onRest: () => {
        setFirstSlideStyles({
          from: {
            transform: 'translateX(-100%)',
          },
          to: {
            transform: 'translateX(100%)',
          },
          immediate: true,
        });
        onAnimationEnd();
      },
    });
  }
};

export default setDesktopAnimation;
