import React, { useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { withRouter } from 'react-router-dom';
import { LivePreviewContext } from '../components/template-details';
import getUserAuthorizationStatus from '../modules/user/selectors/user-authorization-status';
import getSiteIdToRecreate from '../modules/sites/selectors/get-site-id-recreate';
import getSiteIdToSwitch from '../modules/sites/selectors/site-id-to-switch-template';
import HeaderDetailPage from '../components/header-detail-page';
import trackers from '../modules/analytics/segment/trackers';
import { SiteSetupContext } from '../modules/site-setup/helpers/site-setup-context';
import useOnTemplateSelect from '../modules/site-setup/hooks/use-on-template-select';
import constants from '../modules/site-setup/constants';

const { trackLivePreviewBackClicked, trackLivePreviewDeviceClicked } = trackers;
const { triggerIds } = constants;

const HeaderDetailPageContainer = (props) => {
  const { match, history } = props;
  const isUserAuthenticated = useSelector(getUserAuthorizationStatus);
  const siteIdToRecreate = useSelector(getSiteIdToRecreate);
  const siteIdToSwitch = useSelector(getSiteIdToSwitch);
  const siteId = siteIdToRecreate || siteIdToSwitch || null;
  const templateBuildSlug = get(match, 'params.templateSlug', null);
  const context = useContext(SiteSetupContext);
  const { getArchetype } = context;

  const isPreloader = isUserAuthenticated && !siteIdToSwitch;

  const onTemplateSelect = useOnTemplateSelect({
    isTrackEventsAsync: true,
  });

  const onUseThisTemplate = useCallback(
    () => onTemplateSelect(templateBuildSlug, triggerIds.TEMPLATE),
    [onTemplateSelect, templateBuildSlug]
  );

  const onBackButtonClick = useCallback(() => {
    trackLivePreviewBackClicked({ siteId, templateBuildSlug });
    history.goBack();
  }, [siteId, templateBuildSlug, history]);

  const onToggleMode = useCallback(
    (prevMode, newMode, toggleMode) => {
      if (prevMode !== newMode) {
        trackLivePreviewDeviceClicked({
          siteId,
          templateBuildSlug,
          typeId: newMode,
        });
      }

      toggleMode(newMode);
    },
    [siteId, templateBuildSlug]
  );

  return (
    <LivePreviewContext.Consumer>
      {({ mode, toggleMode }) => (
        <HeaderDetailPage
          mode={mode}
          archetype={getArchetype()}
          toggleMode={(newMode) => {
            onToggleMode(mode, newMode, toggleMode);
          }}
          isPreloader={isPreloader}
          onUseThisTemplate={onUseThisTemplate}
          onBackButtonClick={onBackButtonClick}
        />
      )}
    </LivePreviewContext.Consumer>
  );
};

HeaderDetailPageContainer.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(HeaderDetailPageContainer);
