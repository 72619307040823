import actionTypes from '../constants/action-types';

const data = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SET_DATA:
      return action.payload.sitesData;
    default:
      return state;
  }
};

export default data;
