import common from './common';
import actionTypes from './action-types';
import siteSetupNames from './site-setup-names';
import siteSetupStepsMap from './site-setup-steps-map';
import archetypesMap from './archetypes-map';
import sitePropertiesMap from './site-properties-map';
import validationPatterns from './validation-patterns';
import triggerIds from './trigger-ids';

export default {
  common,
  actionTypes,
  siteSetupNames,
  siteSetupStepsMap,
  archetypesMap,
  sitePropertiesMap,
  validationPatterns,
  triggerIds,
};
