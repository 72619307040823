import { t } from 'i18next';

const getSuggestions = () => [
  {
    label: t('Cleaning Service'),
  },
  {
    label: t('Automotive Services'),
  },
  {
    label: t('Car Detailing Service'),
  },
  {
    label: t('Handmade Jewelry'),
  },
  {
    label: t('Beauty Salon'),
  },
  {
    label: t('Pet Grooming'),
  },
  {
    label: t('E-commerce Store'),
  },
  {
    label: t('Online Fashion Store'),
  },
];

export default getSuggestions;
