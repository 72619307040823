import React from 'react';
import PropTypes from 'prop-types';

const LoadingOverlay = ({ spinnerData }) => {
  const { spinnerIsShown, text } = spinnerData;
  const CENTER_CLASS = 'loading-overlay-img--center';
  const iconClass = `loading-overlay-img ${text || CENTER_CLASS}`;

  if (!spinnerIsShown) {
    return null;
  }

  return (
    <div className="loading-overlay">
      <div>
        <div className={iconClass} />
        {text && <p className="loading-overlay__text">{text}</p>}
      </div>
    </div>
  );
};

LoadingOverlay.defaultProps = {
  spinnerData: {
    spinnerIsShown: false,
    text: null,
  },
};

LoadingOverlay.propTypes = {
  spinnerData: PropTypes.shape({
    spinnerIsShown: PropTypes.bool,
    text: PropTypes.string,
  }),
};

export default LoadingOverlay;
