import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import noop from 'src/js/utils/noop';
import LogoContainer from '../../containers/logo-container';

function PlatformSelector({ isVisible, captions, latitudeLink, legacyLink }) {
  const platformSelectorClassNames = classNames('platform-selector', {
    'platform-selector--visible': isVisible,
  });

  const handleContextMenu = (event) => {
    event.preventDefault();
  };

  return (
    <div className={platformSelectorClassNames}>
      <header>
        <LogoContainer alt={captions.alt.logo} />
      </header>

      <main>
        <section>
          <article className="platform-selector__latitude">
            <div className="platform-selector__latitude-info">
              <h1>{captions.latitude.title}</h1>
              <p>{captions.latitude.description}</p>
              <a
                className="platform-selector__latitude-link"
                href={latitudeLink.path}
                onClick={latitudeLink.handler}
                onContextMenu={handleContextMenu}
              >
                {captions.latitude.link}
              </a>
            </div>

            <div className="platform-selector__latitude-picture-container">
              <img src="/images/latitude-ipad.png" alt={captions.alt.media} />
            </div>
          </article>
        </section>
      </main>

      <footer>
        <div className="platform-selector__legacy">
          <p>
            <strong>{captions.legacy.title}</strong>
          </p>
          <p>
            {captions.legacy.description}
            <a
              className="platform-selector__legacy-link"
              href={legacyLink.path}
              onClick={legacyLink.handler}
              onContextMenu={handleContextMenu}
            >
              {captions.legacy.link}
            </a>
          </p>
        </div>
      </footer>
    </div>
  );
}

PlatformSelector.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  captions: PropTypes.shape({
    latitude: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      link: PropTypes.string,
    }),
    legacy: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      link: PropTypes.string,
    }),
    alt: PropTypes.shape({
      logo: PropTypes.string,
      media: PropTypes.string,
    }),
  }).isRequired,
  latitudeLink: PropTypes.shape({
    path: PropTypes.string,
    handler: noop,
  }).isRequired,
  legacyLink: PropTypes.shape({
    path: PropTypes.string,
    handler: noop,
  }).isRequired,
};

export default PlatformSelector;
