import archetypesMap from '../constants/archetypes-map';

const getSettingsFromContext = (context) => {
  const {
    archetype,
    address,
    siteName,
    businessName,
    businessDescription,
    phone,
    hours,
    email,
    socialLinks,
    businessCategorySlug,
  } = context.getData();
  const settings = {
    siteName,
  };

  if (businessCategorySlug) {
    settings.businessCategory = businessCategorySlug;
  }

  if (archetype !== undefined) {
    if (businessName) {
      settings.businessName = businessName;
    }

    if (businessDescription) {
      settings.businessDescription = businessDescription;
    }

    if (archetype !== archetypesMap.PERSONAL) {
      if (address) {
        settings.addresses = [address];
      }

      if (phone) {
        settings.phones = [phone];
      }

      if (hours) {
        settings.businessHours = [hours];
      }

      if (email) {
        settings.emails = [email];
      }

      if (socialLinks && socialLinks.length) {
        settings.socialLinks = socialLinks;
      }
    }
  }

  return settings;
};

export default getSettingsFromContext;
