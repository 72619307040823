import actionTypes from '../constants/action-types';

const setCurrentPackage = (currentPackage) => ({
  type: actionTypes.SET_CURRENT_PACKAGE,
  payload: {
    currentPackage,
  },
});

export default setCurrentPackage;
