import track from '../helpers/track';
import eventTypes from '../constants/event-types';
import { APP_NAME } from '../../../../constants/common';

const trackCategorySelected = ({ siteId, selectedTemplateCategory }) => {
  track(eventTypes.CATEGORY_SELECTED, {
    appName: APP_NAME,
    siteId,
    selectedTemplateCategory,
  });
};

export default trackCategorySelected;
