import connectedStore from '../../../store/connected-store';
import getAvailablePlatformComponents from '../selectors/available-platform-components';
import platformComponents from '../constants/platform-components';

const isAuthComponentAvailable = () => {
  const store = connectedStore.get();
  const state = store.getState();
  const availablePlatformComponents = getAvailablePlatformComponents(state);

  return availablePlatformComponents.includes(platformComponents.AUTH);
};

export default isAuthComponentAvailable;
