import { BACK_SEARCH_PARAM } from 'src/js/constants/common';
import getSearchParams from './get-search-params';

export const redirectToUrl = (url) => {
  const urlWithParams = new URL(url);
  const searchParams = new URLSearchParams(getSearchParams());

  searchParams.forEach((value, key) => {
    if (key === BACK_SEARCH_PARAM) {
      return;
    }

    urlWithParams.searchParams.append(key, value);
  });

  return window.location.assign(urlWithParams.toString());
};
