import { APP_NAME } from 'src/js/constants/common';
import getSiteIdRecreate from 'src/js/modules/sites/accessors/get-site-id-recreate';
import track from '../helpers/track';
import eventTypes from '../constants/event-types';

const trackSiteSetupBusinessDetailsProvided = ({
  archetypeId,
  address,
  phone,
  email,
  workingHours,
  socialLinks,
  businessCategory = null,
}) =>
  track(eventTypes.SITESETUP_BUSINESS_DETAILS_PROVIDED, {
    appName: APP_NAME,
    siteId: getSiteIdRecreate() || null,
    addressAdded: Boolean(address.length),
    phoneAdded: Boolean(phone.length),
    emailAdded: Boolean(email.length),
    workingHours: Boolean(workingHours.length),
    socialLinks: socialLinks.some((link) => Boolean(link.length)),
    archetypeId,
    businessCategory,
  });

export default trackSiteSetupBusinessDetailsProvided;
