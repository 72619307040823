import store from 'src/js/store';
import config from 'src/js/modules/config';
import { YOLA_USER_COOKIE } from 'src/js/constants/common';
import partner from 'src/js/modules/partner';
import getCookie from 'src/js/utils/get-cookie';
import isUserAuthorized from 'src/js/modules/user/verifiers/is-user-authorized';
import { redirectToUrl } from 'src/js/utils/redirect-to-url';
import routesMap from 'src/js/router/helpers/routes-map';

const redirectUnauthorizedUser = (backUrl) => {
  if (isUserAuthorized()) return;

  const state = store.getState();
  const backURL = backUrl || routesMap.siteName.url();
  const loginUrl = config.selectors.getLoginUrl(state);
  const signupUrl = config.selectors.getSignupUrl(state);
  const multisiteMode = partner.selectors.getMultisiteMode(state);

  const yolaUserCookie = getCookie(YOLA_USER_COOKIE);
  const nextParamName = multisiteMode ? 'next' : 'sso_destination_url';

  const authUrlWithParams = new URL(yolaUserCookie ? loginUrl : signupUrl);
  authUrlWithParams.searchParams.append(nextParamName, `${window.location.origin}${backURL}`);

  redirectToUrl(authUrlWithParams.toString());
};

export default redirectUnauthorizedUser;
