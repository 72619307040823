import React, { createContext, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SiteSetupStorage from 'src/js/modules/site-setup/helpers/site-setup-storage';

const SiteSetupContext = createContext({});

const SiteSetupContextProvider = ({ children }) => {
  const [setupInfo, setSetupInfo] = useState(() => SiteSetupStorage.getData());

  useEffect(() => {
    if (Object.keys(setupInfo).length > 0) {
      SiteSetupStorage.setData(setupInfo);
    }
  }, [setupInfo]);

  const updateData = useCallback((data) => {
    setSetupInfo((info) => ({
      ...info,
      ...data,
    }));
  }, []);

  const setData = useCallback((data) => {
    setSetupInfo(data);
  }, []);

  const getData = (key) => {
    if (!key) {
      return setupInfo;
    }

    return setupInfo[key];
  };

  const getArchetype = () => getData('archetype');

  const api = {
    getArchetype,
    getData,
    setData,
    updateData,
  };

  return <SiteSetupContext.Provider value={api}>{children}</SiteSetupContext.Provider>;
};

SiteSetupContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { SiteSetupContext, SiteSetupContextProvider };
