import store from 'src/js/store';
import selectors from '../selectors';

const getSiteIdRecreate = () => {
  const state = store.getState();

  return selectors.getSiteIdRecreate(state);
};

export default getSiteIdRecreate;
