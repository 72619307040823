import actionTypes from '../constants/action-types';

const setActive = (subscriptions) => ({
  type: actionTypes.SET_ACTIVE_SUBSCRIPTIONS,
  payload: {
    subscriptions,
  },
});

export default setActive;
