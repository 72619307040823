export default (editorUrl, siteId, isTemplateSwitched) => {
  const url = new URL(editorUrl);
  url.searchParams.set('site_id', siteId);

  if (isTemplateSwitched) {
    url.searchParams.set('is_template_switched', true);
  }

  return url.href;
};
