import { ALL } from '../constants/category-ids';

const filterTemplatesByCategory = (templates, activeCategory = ALL) => {
  if (activeCategory === ALL) {
    return templates;
  }

  return templates.filter((template) => {
    const categories = template.properties.categories || [];
    return categories.some((category) => category === activeCategory);
  });
};

export default filterTemplatesByCategory;
