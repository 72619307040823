import actionTypes from '../constants/action-types';

const active = (state = [], action) => {
  switch (action.type) {
    case actionTypes.SET_CURRENT_PACKAGE:
      return action.payload.currentPackage;
    default:
      return state;
  }
};

export default active;
