import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import serviceClient from '../service-client';

const FeatureFlagsContext = createContext();
const { Provider, Consumer } = FeatureFlagsContext;

function FeatureFlagsProvider({ children }) {
  const featureFlagsManager = serviceClient.get();
  const [featureFlags, setFeatureFlags] = useState(featureFlagsManager.state);
  const [requestedFeatureFlags, setRequestedFeatureFlags] = useState([]);

  const requestFeatureFlags = async (flags) => {
    try {
      const resolvedFlags = await featureFlagsManager.request(flags);
      setFeatureFlags((currentFeatureFlags) => ({
        ...currentFeatureFlags,
        ...resolvedFlags,
      }));

      setRequestedFeatureFlags((currentFeatureFlags) => [
        ...new Set([...currentFeatureFlags, ...flags]),
      ]);

      return resolvedFlags;
    } catch (error) {
      console.error(error);
      return error;
    }
  };

  return (
    <Provider value={{ featureFlags, requestFeatureFlags, requestedFeatureFlags }}>
      {children}
    </Provider>
  );
}

FeatureFlagsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { FeatureFlagsProvider, Consumer as UserInputConsumer };
export default FeatureFlagsContext;
