import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { getSelectedTemplate } from '../template-details/utils';
import getConceptId from '../../helpers/get-concept-id';

function UseTemplate(props) {
  const { templates, templateSlug, locale, createSiteByTemplate } = props;

  useEffect(() => {
    const selectedTemplate = getSelectedTemplate(templates, templateSlug);

    if (!selectedTemplate) return;

    const conceptId = getConceptId(selectedTemplate);

    createSiteByTemplate(conceptId, templateSlug, locale);
  }, [templates, templateSlug, locale, createSiteByTemplate]);

  return null;
}

UseTemplate.defaultProps = {
  templates: null,
  createSiteByTemplate: () => {},
};

UseTemplate.propTypes = {
  templates: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
      description: PropTypes.string,
      previewUrl: PropTypes.string,
    })
  ),
  templateSlug: PropTypes.string.isRequired,
  createSiteByTemplate: PropTypes.func,
  locale: PropTypes.string.isRequired,
};

export default React.memo(
  UseTemplate,
  (prevProps, nextProps) =>
    prevProps.templateSlug === nextProps.templateSlug &&
    prevProps.templates.length === nextProps.templates.length
);
