import getConceptId from '../../../helpers/get-concept-id';

const processSiteConceptForTemplates = (templates) =>
  templates.map((template) => {
    const conceptId = getConceptId(template);
    const { siteConcepts } = template;
    const siteConcept = siteConcepts.find(({ id }) => id === conceptId);

    return {
      ...template,
      conceptId,
      previewUrl: siteConcept.previewUrl,
      properties: {
        ...template.properties,
        livePreviewUrl: siteConcept.livePreviewUrl,
      },
    };
  });

export default processSiteConceptForTemplates;
