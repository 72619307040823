import partner from 'src/js/modules/partner';
import sites from 'src/js/modules/sites';
import getSearchParams from 'src/js/utils/get-search-params';

const isGalleryAllowed = (state) => {
  const multisiteMode = partner.selectors.getMultisiteMode(state);
  const partnerSignupUrl = partner.selectors.getPartnerSignupUrl(state);
  const userSites = sites.selectors.getSites(state);
  const isSiteSwitching = sites.selectors.getSiteIdToSwitchTemplate(state);
  const hasWebsite = userSites.count > 0;
  const userSitesReset = hasWebsite && userSites.results[0].resetAt;
  const searchParams = getSearchParams();
  const templateChoiceMode = Boolean(searchParams.get('tcm'));

  if (isSiteSwitching || userSitesReset || multisiteMode || !hasWebsite) {
    return true;
  }

  if (templateChoiceMode && partnerSignupUrl) {
    return true;
  }

  return false;
};

export default isGalleryAllowed;
