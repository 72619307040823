import siteSetupNames from '../constants/site-setup-names';
import serviceClient from '../../feature-flags/service-client';
import { AI_OPTIONAL_TEMPLATE_SELECTION_STEP_FLAG } from '../../../constants/common';
import siteSetupStepsMap from '../constants/site-setup-steps-map';

const getCalculatedSiteSetupStepsMap = () => {
  const featureFlagsManager = serviceClient.get();
  const {
    [AI_OPTIONAL_TEMPLATE_SELECTION_STEP_FLAG]: isOptionalTemplateSelectionStepEnabled,
  } = featureFlagsManager.state;

  return Object.entries(siteSetupStepsMap).reduce((acc, [key, value]) => {
    const isSkipCalculationStep = [
      siteSetupNames.ARCHETYPES,
      siteSetupNames.CATEGORY,
      siteSetupNames.HOW_TO_BUILD,
    ].some((step) => step === key);

    const stepNumber =
      !isSkipCalculationStep && isOptionalTemplateSelectionStepEnabled ? value + 1 : value;

    return { ...acc, [key]: stepNumber };
  }, {});
};

export default getCalculatedSiteSetupStepsMap;
