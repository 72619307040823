import { isFunction } from 'lodash';
import track from './track';

const trackAsync = (event, props, opts, cb = () => {}) =>
  new Promise((resolve) => {
    let callback = cb;
    let options = opts;
    let properties = props;

    if (isFunction(options)) {
      callback = options;
      options = null;
    } else if (isFunction(properties)) {
      callback = properties;
      properties = null;
      options = null;
    }

    const resolvePromise = () => {
      resolve();
      callback();
    };

    const params = [properties, options, resolvePromise].filter((param) => Boolean(param));

    track(event, ...params);
    setTimeout(resolvePromise, 1000);
  });
export default trackAsync;
