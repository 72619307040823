function provideViewportHeight() {
  const update = () => {
    const viewHeight = window.innerHeight;
    document.body.style.setProperty('--viewport-height', `${viewHeight}px`);
  };

  update();
  window.addEventListener('resize', update);
}

export default provideViewportHeight;
