import track from '../helpers/track';
import eventTypes from '../constants/event-types';
import { APP_NAME } from '../../../../constants/common';

const trackBusinessCategorySelected = ({
  siteId,
  stepId,
  businessCategory = null,
  archetypeId,
  source,
}) => {
  track(eventTypes.BUSINESS_CATEGORY_SELECTED, {
    appName: APP_NAME,
    siteId,
    stepId,
    businessCategory,
    archetypeId,
    source,
  });
};

export default trackBusinessCategorySelected;
