import { t } from 'i18next';

const getCaptions = () => ({
  selectCaptions: {
    title: t('Select a website template'),
    subtitle: t(
      "Unsure which template to pick? Don't worry, you can easily switch templates at any time without losing any of your content."
    ),
  },
  resetCaptions: {
    title: t('Reset your website'),
    subtitle: t(
      'Complete the website reset by selecting a new template. Please proceed with this step carefully. You cannot recover your site after the reset.'
    ),
  },
  switchCaptions: {
    title: t('Switch website template'),
    subtitle: t(
      "Choose the new template from our rich collection. Don't worry, your content will not be changed."
    ),
  },
});

const getGalleryCaptions = (siteIdToRecreate, siteIdToSwitch) => {
  const { selectCaptions, resetCaptions, switchCaptions } = getCaptions();

  if (siteIdToRecreate) {
    return resetCaptions;
  }

  if (siteIdToSwitch) {
    return switchCaptions;
  }

  return selectCaptions;
};

export default getGalleryCaptions;
