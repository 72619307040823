import getCountries from './get-countries';
import getLanguageNameData from './get-language-name';
import getLanguages from './get-languages';

const getLanguageCodesOptions = () => {
  const languagesMap = getLanguages();
  const countriesMap = getCountries();
  const languageKeys = Object.keys(languagesMap);
  const languageCodeOptions = [];

  languageKeys.forEach((code) => {
    const { countries } = languagesMap[code];
    countries.forEach((country) => {
      const { countryCode, languageCode } = country;
      const { iconComponent, name: countryName } = countriesMap[countryCode];
      const name = getLanguageNameData({
        languageCode,
        language: languagesMap[code],
        country,
        countryName,
      });

      languageCodeOptions.push({
        value: languageCode,
        label: name,
        iconComponent,
      });
    });
  });

  return languageCodeOptions;
};

export default getLanguageCodesOptions;
