/**
 * Window.innerHeight property workaround for iFrame on iOS 10+ (May 17 2018)
 *
 * The Width/Height of IFrame on iOS, whatever the values you set, will always have width/height
 * equal to width/height of the document, which gets rendered inside that iFrame. Furthermore,
 * the width/height of iFrame is calculated regarding the entire render tree, not its visible parts.
 *
 * This behavior brings tons of bugs and issues. One of those issues is solved by this workaround.
 *
 * This, workaround monkey-patches the window.innerHeight and returns the correct value for
 * window.innerHeight as it would be, if iframe would be rendered on desktop
 */
import bowser from 'yola-bowser';

const fixIOSWindowInnerHeight = (frameWindow) => {
  if (!bowser.ios) return;

  const frame = frameWindow.frameElement;
  const syncyFrame = frame.parentNode;
  const view = syncyFrame.parentNode;
  Object.defineProperty(frameWindow, 'innerHeight', { get: () => view.offsetHeight });
};

export default fixIOSWindowInnerHeight;
