import track from '../helpers/track';
import eventTypes from '../constants/event-types';
import { APP_NAME } from '../../../../constants/common';

const trackEditingClicked = ({
  siteId,
  templateBuildSlug,
  selectedTemplateCategory,
  businessCategory = null,
  archetypeId,
}) => {
  track(eventTypes.EDITING_CLICKED, {
    appName: APP_NAME,
    siteId,
    templateBuildSlug,
    selectedTemplateCategory,
    businessCategory,
    archetypeId,
  });
};

export default trackEditingClicked;
