import { useContext } from 'react';
import useFeatureFlags from 'src/js/modules/feature-flags/hooks/use-feature-flags';
import { AI_SITE_WIZARD_FLAG } from 'src/js/constants/common';
import {
  AI_SITE_WIZARD_SITE_PROP,
  aiSiteWizardSitePropValues,
} from 'src/js/constants/ai-site-wizard';
import { SiteSetupContext } from '../../site-setup/helpers/site-setup-context';

const useSiteProperties = () => {
  const context = useContext(SiteSetupContext);
  const { getData } = context;
  const { [AI_SITE_WIZARD_SITE_PROP]: aiSiteWizard } = getData();
  const [featureFlags, , requestedFeatureFlags] = useFeatureFlags([AI_SITE_WIZARD_FLAG]);

  if (!requestedFeatureFlags.includes(AI_SITE_WIZARD_FLAG)) {
    return null;
  }

  const { [AI_SITE_WIZARD_FLAG]: isAISiteWizardEnabled } = featureFlags;

  if (isAISiteWizardEnabled) {
    return {
      [AI_SITE_WIZARD_SITE_PROP]: aiSiteWizard || aiSiteWizardSitePropValues.ENABLED,
    };
  }

  return {};
};

export default useSiteProperties;
