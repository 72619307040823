import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import TemplateDetails from '../components/template-details';
import getLocale from '../modules/i18n/selectors/locale';
import useTemplates from '../modules/templates/hooks/use-templates';
import useFeatureFlags from '../modules/feature-flags/hooks/use-feature-flags';
import routesMap from '../router/helpers/routes-map';
import { SiteSetupContext } from '../modules/site-setup/helpers/site-setup-context';
import getSiteIdToSwitch from '../modules/sites/selectors/site-id-to-switch-template';
import { BUSINESS_TAXONOMY_FLAG } from '../constants/common';

const TemplateDetailsContainer = ({ templateSlug }) => {
  const history = useHistory();
  const locale = useSelector(getLocale);
  const context = useContext(SiteSetupContext);
  const archetype = context.getArchetype();
  const isArchetypeSet = archetype !== undefined;
  const [featureFlags] = useFeatureFlags([BUSINESS_TAXONOMY_FLAG]);
  const { [BUSINESS_TAXONOMY_FLAG]: isBusinessTaxonomyEnabled } = featureFlags;
  const siteIdToSwitch = useSelector(getSiteIdToSwitch);
  const { templates } = useTemplates();

  if (!isArchetypeSet && !siteIdToSwitch) {
    history.replace(
      isBusinessTaxonomyEnabled ? routesMap.siteCategory.url() : routesMap.userArchetypes.url()
    );
    return null;
  }

  return <TemplateDetails templates={templates} templateSlug={templateSlug} locale={locale} />;
};

TemplateDetailsContainer.propTypes = {
  templateSlug: PropTypes.string.isRequired,
};

export default TemplateDetailsContainer;
